import React, { useState, useEffect } from "react";

// App i18n
import { useTranslation } from "react-i18next";

// Redux
import { useDispatch } from "react-redux";

// hooks
import { useAuth } from "../../../hooks/use-auth";

// Internal components
import Footer from "../Footer";
import TopBar from "../navigation/TopBar";

const Layout = ({ children, goBack, isLoading, menu, nav, search, title }) => {
  const [isMobile, setMobile] = useState(null);
  const [isOpen, setOpen] = useState(false);
  // i18n
  const [t] = useTranslation();
  const dir = t("app.lang.dir");
  // Redux
  const dispatch = useDispatch();
  // Auth user
  const auth = useAuth();

  useEffect(() => {
    // Create the viewport listener
    viewportWidth();
    window.addEventListener("resize", () => {
      viewportWidth();
    });
    return () => {
      // Remove listener
      window.removeEventListener("resize", () => {});
    };
  });

  const viewportWidth = () => {
    let display;
    if (typeof window !== "undefined")
      display =
        (window.innerWidth || document.documentElement.clientWidth) < 960
          ? true
          : false;
    setMobile(display);
  };

  const handleOpenModal = () => {
    dispatch.openModal();
  };

  return (
    <>
      <main id="layout">{children}</main>
      <Footer>{menu}</Footer>
      <div className="uk-position-top">
        <TopBar
          auth={auth}
          dir={dir}
          isLoading={isLoading}
          isMobile={isMobile}
          isOpen={isOpen}
          openModal={handleOpenModal}
          search
          title={title}
        >
          {menu}
        </TopBar>
      </div>
    </>
  );
};

export default Layout;
