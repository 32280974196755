import React, { useEffect, useState } from "react";
// i18n
import { useTranslation } from "react-i18next";
// Other functions
import slug from "slug";
// Hooks
import { useRouter } from "../../hooks/use-router";
import { useIwin } from "../../hooks/use-iwin";

// Internal components
import Head from "../../components/layout/Head";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/layout/Loading";

const News = () => {
  // i18n
  const [t] = useTranslation();
  const dir = t("app.lang.dir");
  // router
  const router = useRouter();

  // state
  const [news, setNews] = useState({});

  return (
    <>
      <Head
        title={t("app.name") + " | " + t("iwin.masterfile")}
        type={t("app.type")}
        image="/assets/inwine_home.jpg"
        description={t("app.description")}
      />
      <Layout></Layout>
    </>
  );
};

export default News;
