import React from "react";
import { useTranslation } from "react-i18next";

import SettingsEmail from "./SettingsEmail";
import SettingsLanguage from "./SettingsLanguage";
import SettingsNameLocation from "./SettingsNameLocation";
import SettingsNav from "./SettingsNav";

const SettingsPersonalDetails = ({ user }) => {
  const [t] = useTranslation();

  const dir = t("app.lang.dir");

  return (
    <div className="uk-panel">
      <div
        className={
          "uk-card uk-card-default uk-card-body uk-margin uk-text-center" +
          (dir === "ltr" ? " uk-text-left@s" : " uk-text-right@s")
        }
      >
        <SettingsNav />
        <ul id="uk-switcher-settings" className="uk-switcher">
          <li>
            <div
              className="uk-h5 uk-text-300 uk-text-uppercase uk-heading-divider"
              style={{ padding: "10px 5px" }}
            >
              {t("settings.personal_details.description")}
            </div>
            <SettingsNameLocation user={user} />
            <SettingsEmail user={user} />
            <SettingsLanguage user={user} />
          </li>
          {/* <li>
              <SettingsOrganisation
                onOrganisation={this.handleOrganisation}
                organisation={organisation}
              />
            </li> */}
        </ul>
      </div>
    </div>
  );
};

export default SettingsPersonalDetails;
