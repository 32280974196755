import React, { useEffect, useState } from "react";
// i18n
import { useTranslation } from "react-i18next";
// Redux
import { useSelector, useDispatch } from "react-redux";
// Hooks
import { useRouter } from "../../hooks/use-router";
import { getNews } from "../../redux/actions/news_actions";
// mdi Icon
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
// Other imports
import format from "date-fns/format";
// Internal components
import Head from "../../components/layout/Head";
import Footer from "../../components/layout/Footer";

const NewsItem = () => {
  // i18n
  const [t] = useTranslation();
  var locale = t("app.lang.code");
  // to convert date to locale format
  let localeResource;
  if (locale === "ar") localeResource = require("date-fns/locale/ar-SA");
  else if (locale === "en") localeResource = require("date-fns/locale/en-GB");
  else if (locale === "zh") localeResource = require("date-fns/locale/zh-CN");
  else localeResource = require(`date-fns/locale/${locale}`);
  const dir = t("app.lang.dir");
  // router
  const router = useRouter();
  // Redux
  const dispatch = useDispatch();
  const news = useSelector((state) => state.news.items);
  const [item, setItem] = useState(null);
  const readingTime = (ev) => {
    const wordsPerMinute = 200; // Average case.
    let result = t("lbl.min_to_read", { count: 0 });
    let textLength = ev && ev.split(" ").length; // Split by words
    if (textLength > 0) {
      let value = Math.ceil(textLength / wordsPerMinute);
      result = t("lbl.min_to_read", { count: value });
    }
    return result;
  };
  useEffect(() => {
    dispatch(getNews(router.match.params.item));
  }, [dispatch]);
  useEffect(() => {
    setItem(news.find((item) => item.newsId === router.match.params.item));
  }, [news]);

  return (
    <>
      <Head
        title={t("app.name") + " | " + (item && item.title)}
        type={t("app.type")}
        image={item && item.imageUrl}
        description={item && item.content.replaceAll("/n", "\n")}
      />
      <div className="uk-container">
        <div className="uk-section uk-section-branded">
          <div
            className="uk-position-absolute"
            style={
              dir === "ltr"
                ? {
                    left: 50,
                    top: 50,
                    transform: "translate(0, -100%)",
                  }
                : {
                    right: 50,
                    top: 50,
                    transform: "translate(0, -100%)",
                  }
            }
          >
            <a href={"#"} className="uk-icon-button">
              <Icon
                path={dir === "ltr" ? mdiChevronLeft : mdiChevronRight}
                size={2}
                color="#999999"
                onClick={() => router.history.goBack()}
              />
            </a>
          </div>
          {item && (
            <div className="uk-card uk-card-body uk-card-news">
              <div className="uk-news-header">
                <div className="uk-news-category">
                  {t(`lbl.${item.category}`)}
                </div>
                <div className="uk-news-date">
                  {format(new Date(item.date), "PPP", {
                    locale: localeResource.default,
                  })}
                </div>
                <div className="uk-news-title">{item.title}</div>
                <div className="uk-news-author">
                  {t("lbl.by_author", { author: item.author })}
                  <div className="uk-news-time-read">
                    {readingTime(item.content)}
                  </div>
                </div>
              </div>
              <div className="uk-news-image">
                <div className="uk-news-image-cropped">
                  <img
                    src={item.imageUrl}
                    alt=""
                    style={{
                      background: `url(${item.imageUrl}) center center no-repeat`,
                      width: "100%",
                    }}
                  />
                </div>
                <div className="uk-news-image-legend">{item.imageLegend}</div>
              </div>
              <div className="uk-news">
                {item.content
                  .replaceAll("/n", "\n")
                  .split("\n")
                  .map((str, i) => (
                    <p key={i}>{str}</p>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewsItem;
