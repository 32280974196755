import React from "react";
import { GoogleApiWrapper, Map } from "google-maps-react";

import { firebase as config } from "../../config";

// const Marker = () => (
//   <div className="uk-map-marker">
//     <Icon path={mdiMapMarker} size={1} color="rgb(183, 31, 57)" />
//   </div>
// );

const DisplayMap = ({ google, lat, lng }) => {
  return (
    <>
      <div style={{ height: "300px", width: "100%", margin: "10px 0" }}>
        <Map
          google={google}
          containerStyle={{ position: "relative" }}
          initialCenter={{ lat, lng }}
          zoom={14}
        />
      </div>
    </>
  );
};

export default GoogleApiWrapper({ apiKey: `${config.apiKey}` })(DisplayMap);
