import React from "react";
// Other functions
import slug from "slug";
// Redux
import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux/actions/modal_actions";

const NewsModal = ({ className, style, newsItem }) => {
  // Redux dispatch
  const dispatch = useDispatch();

  return (
    <>
      <div id={`${newsItem.newsId}`} className={className} data-uk-modal>
        <div className="uk-modal-dialog">
          <button
            onClick={() => dispatch(closeModal())}
            className="uk-modal-close-full uk-close-large"
            type="button"
            data-uk-close
          />
          <div
            className="uk-grid-collapse uk-child-width-1-2@s uk-flex-middle"
            data-uk-grid
          >
            <div
              className="uk-background-cover"
              style={{
                backgroundImage: `${newsItem.imageUrl}`,
              }}
              data-uk-height-viewport
            ></div>
            <div className="uk-padding-large">
              <h1>{newsItem.title}</h1>
              {newsItem.content
                .replaceAll("/n", "\n")
                .split("\n")
                .map((str, i) => (
                  <p key={i}>{str}</p>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsModal;
