// Modal types
export const MODAL_OPEN = "open_modal";
export const MODAL_CLOSE = "close_modal";

// UI types
export const ERRORS_CLEAR = "clear_errors";
export const ERRORS_SET = "set_errors";
export const REFERER_SET = "set_referer";
export const UI_LOADING = "ui_loading";

// Auth types
export const USER_SET = "set_user";
export const USER_SIGNINS_SET = "set_user_signins";
export const USER_EMAIL_SENT = "user_email_sent";
export const USER_EMAIL_VERIFIED = "user_email_verified";
export const USER_EMAIL_RESET = "user_email_reset";

// News types
export const NEWS_SET = "set_news";
