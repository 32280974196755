import {
  USER_EMAIL_SENT,
  USER_EMAIL_VERIFIED,
  USER_EMAIL_RESET,
  USER_SET,
  USER_SIGNINS_SET,
} from "../types";

const INITIAL_STATE = {
  email_sent: false,
  email_verified: false,
  user: {},
  user_signins: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_EMAIL_SENT:
      return { ...state, email_sent: true, email_verified: false };
    case USER_EMAIL_VERIFIED:
      return { ...state, email_sent: false, email_verified: true };
    case USER_EMAIL_VERIFIED:
      return { ...state, email_sent: false, email_verified: false };
    case USER_SET:
      return { ...state, user: { ...action.payload } };
    case USER_SIGNINS_SET:
      let user_signins = state.map((user) => {
        if (user.userId === action.payload.userId) {
          user.lastConnections = action.payload.lastConnections;
        }
        return user;
      });
      return { ...state, user_signins };

    default:
      return state;
  }
};
