import React, { useEffect } from "react";
// i18n
import { useTranslation } from "react-i18next";
// redux
import { useSelector } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
// Internal helpers
import { toTitleCase } from "../../../../util/helpers";
// Internal components
import AddressInput from "../../elements/AddressInput";
import ButtonInput from "../../elements/ButtonInput";
import CheckboxInput from "../../elements/CheckboxInput";
import DateInput from "../../elements/DateInput";
import HiddenInput from "../../elements/HiddenInput";
import TextInput from "../../elements/TextInput";
import SpanFloat from "../../../molecules/SpanFloat";

const validate = combineValidators({
  userName: isRequired({ message: "err.user_name.required" }),
  firstName: isRequired({ message: "err.first_name.required" }),
  lastName: isRequired({ message: "err.last_name.required" }),
  dateOfBirth: isRequired({ message: "err.user_name.required" }),
});

const lowercase = (value) => value && value.toLowerCase();
const titlecase = (value) => value && toTitleCase(value);

const PersonalDetails = ({
  auth,
  handleSubmit,
  isLoading,
  initialize,
  invalid,
  pristine,
  submitting,
}) => {
  // Get the i18next object
  const { t } = useTranslation();
  // page dir
  const dir = t("app.lang.dir");
  // Get redux form object values
  const selector = formValueSelector("personal_details_form");
  const state = useSelector((state) => state);
  const dateOfBirth = selector(state, "dateOfBirth");
  const isOrganisation = selector(state, "isOrganisation");
  const address = selector(state, "address.formattedAddress");

  let user = auth.user;

  useEffect(() => {
    if (user !== {}) initialize(user);
  }, [user]);

  return (
    <form
      onSubmit={handleSubmit}
      className="uk-form-stacked uk-width-2-3 uk-align-center"
    >
      <div className="uk-grid uk-grid-small" uk-grid="">
        <Field
          name="firstName"
          mandatory
          autofocus
          label={t("lbl.first_name")}
          component={TextInput}
          className="uk-input"
          width="uk-width-1-2"
          type="text"
          placeholder={t("plh.first_name")}
          normalize={titlecase}
        />
        <Field
          name="lastName"
          mandatory
          label={t("lbl.last_name")}
          component={TextInput}
          className="uk-input"
          width="uk-width-1-2"
          placeholder={t("plh.last_name")}
          normalize={titlecase}
        />
        <Field
          name="userName"
          mandatory
          label={t("lbl.username")}
          component={TextInput}
          className="uk-input"
          width="uk-width-2-3"
          type="text"
          placeholder={t("plh.username")}
          help={t("hlp.display_name")}
          normalize={lowercase}
        />
        <Field
          name="dateOfBirth"
          dateInit={dateOfBirth}
          mandatory
          component={DateInput}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          placement={dir === "ltr" ? "bottom-end" : "bottom-start"}
          className="uk-input"
          width="uk-width-1-3"
          type="text"
          label={t("lbl.date_of_birth")}
          placeholder={t("plh.date_of_birth")}
        />
        <Field
          name="isOrganisation"
          component={CheckboxInput}
          className="uk-checkbox"
          width="uk-width-auto"
          label={t("profile.organisation_bool")}
        />
        {!isOrganisation && (
          <Field
            name="address"
            optional
            component={AddressInput}
            className="uk-input"
            width="uk-width-1-1"
            type="text"
            label={t("lbl.full_address")}
            placeholder={t("plh.address")}
            help={t("hlp.address")}
            // normalize={titlecase}
          />
        )}
        {/* <hr
          className="uk-width-1-1 uk-margin-small"
          style={{ marginLeft: "15px" }}
        /> */}
        <Field name="locale" component={HiddenInput} />
        <div className="uk-width-1-1">
          <SpanFloat float="right">
            <ButtonInput
              primary
              disabled={invalid || submitting || isLoading || pristine}
              action="submit"
              isLoading={submitting || isLoading}
            >
              {isOrganisation ? t("lbl.next") : t("lbl.save")}
            </ButtonInput>
          </SpanFloat>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "personal_details_form",
  validate,
})(PersonalDetails);
