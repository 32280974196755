import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const WineTraceMenu = () => {
  // i18n
  const [t] = useTranslation();

  return (
    <>
      <li>
        <Link to="#blockchain" data-uk-scroll="duration: 500">
          {t("app.nav.trace")}
        </Link>
      </li>
      <li>
        <Link to="#domain" data-uk-scroll="duration: 500">
          {t("app.nav.domain")}
        </Link>
      </li>
      <li>
        <Link to="#merchant" data-uk-scroll="duration: 500">
          {t("app.nav.merchant")}
        </Link>
      </li>
      <li>
        <Link to="#courtier" data-uk-scroll="duration: 500">
          {t("app.nav.courtier")}
        </Link>
      </li>
      <li>
        <Link to="#importer" data-uk-scroll="duration: 500">
          {t("app.nav.importer")}
        </Link>
      </li>
      <li>
        <Link to="#consumer" data-uk-scroll="duration: 500">
          {t("app.nav.consumer")}
        </Link>
      </li>
    </>
  );
};

export default WineTraceMenu;
