import React, { useEffect } from "react";

// i18n
import { useTranslation } from "react-i18next";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../redux/actions/modal_actions";
import { storeMessage } from "../../../redux/actions/user_actions";

import { Field, reduxForm } from "redux-form";
import {
  composeValidators,
  combineValidators,
  createValidator,
  isRequired,
  hasLengthGreaterThan,
} from "revalidate";

// Internal helpers
import { toTitleCase } from "../../../util/helpers";

import HiddenInput from "../../forms/elements/HiddenInput";
import TextInput from "../../forms/elements/TextInput";
import TextAreaInput from "../../forms/elements/TextAreaInput";
import SelectInput from "../../forms/elements/SelectInput";
import SpanFloat from "../../molecules/SpanFloat";

const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  "err.email.not_valid"
);

const lowercase = (value) => value && value.toLowerCase();
const titlecase = (value) => value && toTitleCase(value);

const validate = combineValidators({
  fullName: isRequired({ message: "err.full_name.required" }),
  message: composeValidators(
    isRequired({ message: "err.message.required" }),
    hasLengthGreaterThan(5)({ message: "err.message.length" })
  )(),
  referring: isRequired({ message: "err.referring.required" }),
  email: composeValidators(
    isValidEmail,
    isRequired({ message: "err.email.required" })
  )(),
});

const ContactForm = ({
  change,
  handleSubmit,
  invalid,
  pristine,
  submitting,
}) => {
  // i18n
  const [t] = useTranslation();
  const locale = t("app.lang.code");

  // Redux
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.ui.isLoading);

  useEffect(() => {
    change("locale", locale);
  }, [change, locale]);

  const handleSubmitForm = (values) => {
    dispatch(storeMessage(values));
  };

  const referring = [
    { key: "invite", value: t("form.contact.referring.invite") },
    { key: "recommend", value: t("form.contact.referring.recommend") },
    { key: "search", value: t("form.contact.referring.search") },
    { key: "word_mouth", value: t("form.contact.referring.word_mouth") },
    { key: "other", value: t("form.contact.referring.other") },
  ];

  return (
    <>
      <form
        onSubmit={handleSubmit(handleSubmitForm)}
        className="uk-grid-small uk-margin-medium-top uk-width-1-1 uk-clearfix"
        data-uk-grid
      >
        <Field
          name="fullName"
          mandatory
          component={TextInput}
          className="uk-input"
          width="uk-width-1-1"
          type="text"
          label={t("lbl.full_name")}
          placeholder={t("plh.full_name")}
          normalize={titlecase}
        />
        <Field
          name="company"
          optional
          component={TextInput}
          className="uk-input"
          width="uk-width-1-1"
          type="text"
          label={t("lbl.company")}
          placeholder={t("plh.company")}
          normalize={titlecase}
        />
        <Field
          name="email"
          mandatory
          component={TextInput}
          className="uk-input"
          width="uk-width-1-1"
          type="text"
          label={t("lbl.email")}
          placeholder={t("plh.email")}
          normalize={lowercase}
        />
        <Field
          name="referring"
          mandatory
          component={SelectInput}
          className="uk-select"
          width="uk-width-1-1"
          label={t("lbl.referring")}
          placeholder={t("plh.select")}
          options={referring}
        />
        <Field
          name="message"
          mandatory
          component={TextAreaInput}
          className="uk-textarea"
          width="uk-width-1-1"
          rows={3}
          label={t("lbl.message")}
          placeholder={t("plh.message")}
        />
        <Field name="locale" component={HiddenInput} />
        <hr className="uk-width-1-1 uk-margin-small" />
        <div className="uk-width-1-1">
          <SpanFloat float="left">
            <button
              primary="true"
              disabled={isLoading || invalid || pristine || submitting}
              action="submit"
              className="uk-margin uk-button uk-button-primary"
            >
              {t("lbl.send")}
            </button>
          </SpanFloat>
          <SpanFloat float="right">
            <div
              onClick={() => dispatch(closeModal())}
              className="uk-button uk-button-default"
            >
              {t("lbl.cancel")}
            </div>
          </SpanFloat>
        </div>
      </form>
    </>
  );
};

export default reduxForm({
  form: "contactForm",
  validate,
  enableReinitialize: true,
})(ContactForm);
