import React, { Fragment } from "react";

// i18n
import { useTranslation } from "react-i18next";

// Internal components
import LabelDisplayInput from "./LabelDisplayInput";

const TextAreaInput = ({
  className,
  disabled,
  help,
  input,
  label,
  mandatory,
  meta: { touched, error },
  optional,
  placeholder,
  rows,
  validation,
  width
}) => {
  // i18n
  const [t] = useTranslation();

  return (
    <div className={width ? `uk-width-1-1 ${width}@s` : "uk-width-1-1"}>
      <LabelDisplayInput
        dir={t("app.lang.dir")}
        label={label}
        mandatory={mandatory}
        optional={optional}
      />
      <div className="uk-form-controls">
        <div className="uk-inline uk-width-1-1">
          <textarea
            {...input}
            rows={rows}
            placeholder={
              optional ? `${placeholder} ${t("lbl.optional")}` : placeholder
            }
            disabled={disabled}
            className={
              touched && !!error
                ? `${className} uk-form-danger`
                : `${className}`
            }
          />
        </div>
        <div className="uk-text-small uk-margin-small-bottom">
          {help && (
            <Fragment>
              <span className="uk-text-meta uk-text-left">{help}</span>
              <br />
            </Fragment>
          )}
          {validation === false
            ? ""
            : touched &&
              error && (
                <span className="uk-text-danger">
                  {error.substring(0, 4) === "err." ? t(error) : error}
                </span>
              )}
        </div>
      </div>
    </div>
  );
};

export default TextAreaInput;
