import React from "react";
import ReactDOM from "react-dom";

import axios from "axios";

// Router
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

// Toastr
import ReduxToastr from "react-redux-toastr";

// Redux & Firebase
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { configureStore } from "./redux/store";
import { firebase } from "./util/firebase";

// Cookie
import { CookiesProvider } from "react-cookie";

// i18n
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

//Authentication
import { ProvideAuth } from "./hooks/use-auth";

import App from "./components/App";
import ScrollToTop from "./components/layout/ScrollToTop";
import * as serviceWorker from "./serviceWorker";

// CSS
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./styles.css";

const store = configureStore();

const rrfConfig = {
  firebase,
  config: {
    userProfile: "users",
    attachAuthIsReady: true,
    useFirestoreForProfile: true,
    updateProfileOnLogin: false,
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const rootEl = document.getElementById("root");

// Browser history
const browserHistory = createBrowserHistory();

axios.defaults.baseURL =
  "https://europe-west2-inwine-3f733.cloudfunctions.net/api";

let render = () => {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfConfig}>
          <Router history={browserHistory}>
            <ScrollToTop>
              <CookiesProvider>
                <ReduxToastr
                  position="bottom-right"
                  transitionIn="fadeIn"
                  transitionOut="fadeOut"
                />
                <ProvideAuth>
                  <App />
                </ProvideAuth>
              </CookiesProvider>
            </ScrollToTop>
          </Router>
        </ReactReduxFirebaseProvider>
      </Provider>
    </I18nextProvider>,
    rootEl
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// if (module.hot) {
//   module.hot.accept("./components/App", () => {
//     setTimeout(render);
//   });
// }

i18n.on("loaded", () => {
  render();
});
