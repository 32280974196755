import React from "react";

// Redux
import { useSelector } from "react-redux";

// Internal components
import ContactModal from "./ContactModal";
import NameLocationModal from "./NameLocationModal";
import NewsModal from "./NewsModal";
import OrganisationModal from "./OrganisationModal";
import ProfilePhotoModal from "./ProfilePhotoModal";

const modalLookup = {
  ContactModal,
  NameLocationModal,
  NewsModal,
  OrganisationModal,
  ProfilePhotoModal,
};

const ModalManager = () => {
  // Redux store state selector function
  const currentModal = useSelector((state) => state.modal);
  let retModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    retModal = <ModalComponent {...modalProps} />;
  }
  return <>{retModal}</>;
};

export default ModalManager;
