import React from "react";

// Spinner
import Spinner from "react-spinkit";

const Loading = () => {
  return (
    <>
      <div className="uk-overlay-default uk-position-cover"></div>
      <div className="uk-cover-container">
        <canvas data-width data-height />
        <div className="uk-overlay uk-position-center uk-position-z-index">
          <Spinner name="ball-grid-pulse" color="#A1001D" />
        </div>
      </div>
    </>
  );
};

export default Loading;
