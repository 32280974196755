import React from "react";
import { Link } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// external components
import format from "date-fns/format";
// redux
import { useDispatch } from "react-redux";
import { openModal } from "../../../../redux/actions/modal_actions";

// import { localisedByType } from "../../constants/company_types";

import { withI18nParam } from "../../../../util/helpers";

const PersonalSummary = ({ user }) => {
  // i18N
  const [t] = useTranslation();
  const dir = t("app.lang.dir");
  const locale = t("app.lang.code");
  // redux
  const dispatch = useDispatch();
  // console.log("locale:", locale);
  let localeResource;
  if (locale === "ar") localeResource = require("date-fns/locale/ar-SA");
  else if (locale === "en") localeResource = require("date-fns/locale/en-GB");
  else if (locale === "zh") localeResource = require("date-fns/locale/zh-CN");
  else localeResource = require(`date-fns/locale/${locale}`);

  const {
    createdAt,
    photoUrl,
    displayName,
    email,
    language,
    lastSignIn,
    organisations,
    position,
    city,
    country,
    userName,
  } = user;

  let memberSince = "";
  let lastConnection = {};
  if (createdAt)
    memberSince = format(new Date(createdAt), "PPP", {
      locale: localeResource.default,
    });
  if (lastSignIn) {
    lastConnection.date = format(new Date(lastSignIn), "PPP", {
      locale: localeResource.default,
    });
    lastConnection.time = format(new Date(lastSignIn), "pp", {
      locale: localeResource.default,
    });
  }
  return (
    <div className="uk-panel">
      <div
        className={
          "uk-card uk-card-default uk-card-body uk-margin uk-text-center" +
          (dir === "ltr" ? " uk-text-left@s" : " uk-text-right@s")
        }
      >
        <div className="uk-grid-small uk-flex uk-flex-bottom" data-uk-grid>
          <div
            className="uk-grid-small uk-width-1-1 uk-width-expand@s uk-flex uk-flex-bottom"
            data-uk-grid
          >
            <div className="uk-width-1-1 uk-width-auto@s uk-text-center">
              <div
                className="uk-inline uk-visible-toggle"
                uk-toggle={
                  "target: #profile_photo; mode: hover; cls: uk-box-shadow-large"
                }
                style={{ borderRadius: "50%", overflow: "hidden" }}
              >
                <Link
                  to={"#"}
                  onClick={() =>
                    dispatch(
                      openModal("ProfilePhotoModal", {
                        className: "uk-modal uk-flex-top uk-open",
                        style: { display: "block" },
                        header: t("settings.organisation.company.header"),
                        description: t(
                          "settings.organisation.company.description"
                        ),
                        user,
                      })
                    )
                  }
                >
                  <img
                    id="profile_photo"
                    width="150"
                    height="150"
                    alt={displayName || userName}
                    src={photoUrl || "/assets/user.png"}
                  />
                  <div className="uk-overlay uk-overlay-default uk-position-bottom uk-padding-remove uk-text-center">
                    <div style={{ fontSize: "10px" }}>{t("lbl.change")}</div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="uk-width-1-1 uk-width-expand@s">
              {displayName && (
                <div className="uk-h3 uk-margin-remove-bottom">
                  {displayName}
                </div>
              )}
              {userName && (
                <div className="uk-h5 uk-margin-remove">{"@" + userName}</div>
              )}
              {position && (
                <div className="uk-text-300 uk-margin-small-top">
                  {position}
                </div>
              )}
              {(city || country) && (
                <div className="uk-text-meta">
                  {city}
                  {country && (city ? ", " + country.name : country.name)}
                </div>
              )}
            </div>
          </div>
          <div className="uk-width-1-1 uk-width-auto@s">
            {organisations && organisations.length > 0 && (
              <div className="uk-display-block uk-margin-small">
                <div
                  className={
                    "uk-text-center uk-text-top uk-display-inline-block" +
                    (dir === "ltr"
                      ? " uk-margin-small-right"
                      : " uk-margin-small-left")
                  }
                >
                  <img
                    src="/assets/svg/company.svg"
                    alt={t("lbl.company")}
                    width="20"
                    height="20"
                    style={{ color: "#333" }}
                    data-uk-svg
                  />
                </div>
                <div
                  className={
                    "uk-display-inline-block uk-text-300" +
                    (dir === "ltr" ? " uk-text-left" : " uk-text-right")
                  }
                >
                  <div className="uk-text-nowrap uk-text-bold">
                    {organisations[0].name}
                  </div>
                  <div className="uk-text-nowrap uk-text-small">
                    {/* {localisedByType(t("app.lang.code"), organisations[0].type)} */}
                  </div>
                  <div className="uk-text-nowrap uk-text-meta">
                    {/* {organisations[0].city}, {organisations[0].country} */}
                  </div>
                </div>
              </div>
            )}
            {organisations &&
              organisations.length > 0 &&
              organisations[0].status && (
                <div className="uk-display-block uk-margin-small">
                  <div
                    className={
                      "uk-text-center uk-text-top uk-display-inline-block" +
                      (dir === "ltr"
                        ? " uk-margin-small-right"
                        : " uk-margin-small-left")
                    }
                  >
                    <span uk-icon="icon: user" />
                  </div>
                  <div className="uk-display-inline-block uk-text-300">
                    <div className="uk-text-nowrap uk-text-small">
                      <span className="uk-text-warning">
                        {t(`lbl.${organisations[0].status}`)}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            {organisations &&
              organisations.length > 0 &&
              organisations[0].collaborators && (
                <div className="uk-display-block uk-margin-small">
                  <div
                    className={
                      "uk-text-center uk-text-top uk-display-inline-block" +
                      (dir === "ltr"
                        ? " uk-margin-small-right"
                        : " uk-margin-small-left")
                    }
                  >
                    <span uk-icon="icon: users" />
                  </div>
                  <div className="uk-display-inline-block uk-text-300">
                    <div className="uk-text-nowrap uk-text-small">
                      {organisations[0].collaborators.length === 0
                        ? t("lbl.collaborator_count_0")
                        : t("lbl.collaborator_count", {
                            count: organisations[0].collaborators.length,
                          })}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <div
        className={
          "uk-card uk-card-default uk-card-body uk-margin uk-text-center" +
          (dir === "ltr" ? " uk-text-left@s" : " uk-text-right@s")
        }
      >
        <div className="uk-h3 uk-margin-small">
          {withI18nParam(
            "profile.session",
            [
              <span key={0} className="uk-text-300">
                inWine
              </span>,
            ],
            t
          )}
        </div>
        <div className="uk-grid-small uk-child-width-1-1" data-uk-grid>
          {memberSince && (
            <div className="">
              <div className="uk-text-small uk-text-nowrap">
                {t("lbl.member_since_", { date: memberSince })}
              </div>
            </div>
          )}
          {lastConnection && (
            <div className="">
              <div className="uk-text-small uk-text-nowrap uk-margin-small">
                {t("lbl.last_connection_", {
                  date: lastConnection.date + ", " + lastConnection.time,
                })}
              </div>
            </div>
          )}
          {language && (
            <div className="">
              <div className="uk-text-small uk-text-nowrap uk-margin-small">
                {t("lbl.language_", {
                  language: t(`app.lang.${language}.long`),
                })}
              </div>
            </div>
          )}
          {email && (
            <div className="">
              <div
                className={
                  "uk-display-block uk-text-center uk-margin-small" +
                  (dir === "ltr" ? " uk-text-left@s" : " uk-text-right@s")
                }
              >
                <div
                  className={
                    "uk-text-center uk-text-top uk-display-inline-block" +
                    (dir === "ltr"
                      ? " uk-margin-small-right"
                      : " uk-margin-small-left")
                  }
                >
                  <span uk-icon="icon: mail" />
                </div>
                <div className="uk-display-inline-block uk-text-300">
                  <div className="uk-text-nowrap uk-text-small">
                    {email[0].email}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={
          "uk-card uk-card-default uk-card-body uk-margin uk-text-center" +
          (dir === "ltr" ? " uk-text-left@s" : " uk-text-right@s")
        }
      >
        <div className="uk-h3 uk-margin-small">
          <span className="uk-text-300">{t("inventory.title")}</span>
        </div>
      </div>
    </div>
  );
};

export default PersonalSummary;
