// get lat and lng from google address details
export const getAddressComponents = (result, useShortName = false) =>
  new Promise((resolve, reject) => {
    try {
      let addressComponents = {};
      result.address_components.forEach(({ long_name, short_name, types }) => {
        if (types.includes("street_number"))
          addressComponents.streetNumber = useShortName
            ? short_name
            : long_name;
        if (types.includes("route"))
          addressComponents.route = useShortName ? short_name : long_name;
        if (types.includes("locality"))
          addressComponents.city = useShortName ? short_name : long_name;
        if (types.includes("postal_code"))
          addressComponents.zipCode = useShortName ? short_name : long_name;
        if (types.includes("administrative_area_level_2"))
          addressComponents.department = { short_name, long_name };
        if (types.includes("administrative_area_level_1"))
          addressComponents.region = { short_name, long_name };
        if (types.includes("country"))
          addressComponents.country = { short_name, long_name };
      });
      resolve(addressComponents);
    } catch (err) {
      reject(err);
    }
  });
// get formatted address from google address details
export const getFormattedAddress = (result) =>
  new Promise((resolve, reject) => {
    try {
      const formattedAddress = result.formatted_address;
      resolve(formattedAddress);
    } catch (error) {
      reject(error);
    }
  });
// get lat and lng from google address details
export const getLatLng = (result) =>
  new Promise((resolve, reject) => {
    try {
      const { lat, lng } = result.geometry.location;
      resolve({ lat: lat(), lng: lng() });
    } catch (error) {
      reject(error);
    }
  });
// get lat and lng from google address details
export const getZipCode = (result, useShortName = false) =>
  new Promise((resolve, reject) => {
    try {
      let zipCode = null;
      result.address_components.forEach(({ long_name, short_name, types }) => {
        if (types.includes("postal_code"))
          zipCode = useShortName ? short_name : long_name;
      });
      resolve(zipCode);
    } catch (err) {
      reject(err);
    }
  });
// get url from google address details
export const getUrl = (result) =>
  new Promise((resolve, reject) => {
    try {
      const url = result.url;
      resolve(url);
    } catch (error) {
      reject(error);
    }
  });
// get UTC offset from google address details
export const getUtcOffsetMinutes = (result) =>
  new Promise((resolve, reject) => {
    try {
      const utcOffsetMinutes = result.utc_offset_minutes;
      resolve(utcOffsetMinutes);
    } catch (error) {
      reject(error);
    }
  });
