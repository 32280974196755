import React, { useEffect } from "react";

// App i18n
import { useTranslation } from "react-i18next";

// Redux
import { Field, reduxForm } from "redux-form";
import {
  combineValidators,
  composeValidators,
  createValidator,
  isRequired,
} from "revalidate";

// Internal components
import HiddenInput from "../../elements/HiddenInput";
import TextInput from "../../elements/TextInput";
import ButtonInput from "../../elements/ButtonInput";

// Email validator
const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  "err.email.not_valid"
);

// Validators
const validate = combineValidators({
  email: composeValidators(
    isValidEmail,
    isRequired({ message: "err.email.required" })
  )(),
});
// Normalizing
const lowercase = (value) => value && value.toLowerCase();

const Email = ({
  change,
  handleSubmit,
  isLoading,
  invalid,
  pristine,
  submitting,
}) => {
  // i18n
  const [t] = useTranslation();

  useEffect(() => {
    change("locale", t("app.lang.code"));
  });

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="uk-form-stacked uk-width-medium uk-align-center"
      >
        <Field
          name="email"
          mandatory
          autofocus
          label={t("lbl.email")}
          component={TextInput}
          type="email"
          autoComplete="off"
          className="uk-input"
          placeholder={t("plh.email")}
          normalize={lowercase}
        />
        <Field name="locale" component={HiddenInput} />
        <div className="uk-margin">
          <ButtonInput
            disabled={invalid || submitting || isLoading || pristine}
            action="submit"
            isLoading={submitting || isLoading}
            label={t("lbl.get_started")}
          />
        </div>
      </form>
    </>
  );
};

export default reduxForm({
  form: "email_form",
  validate,
})(Email);
