export const locales = [
  "ar",
  "bn",
  "de",
  "el",
  "en",
  "es",
  "fr",
  "he",
  "hi",
  "it",
  "ja",
  "ko",
  "pt",
  "ru",
  "tr",
  "zh"
];

export const dateLocale = locale => {
  var localeModule = {};
  if (locale === "ar") localeModule = require("date-fns/locale/ar-SA");
  if (locale === "bn") localeModule = require("date-fns/locale/bn");
  if (locale === "de") localeModule = require("date-fns/locale/de");
  if (locale === "el") localeModule = require("date-fns/locale/el");
  if (locale === "en") localeModule = require("date-fns/locale/en-GB");
  if (locale === "es") localeModule = require("date-fns/locale/es");
  if (locale === "fr") localeModule = require("date-fns/locale/fr");
  if (locale === "he") localeModule = require("date-fns/locale/he");
  if (locale === "hi") localeModule = require("date-fns/locale/hi");
  if (locale === "it") localeModule = require("date-fns/locale/it");
  if (locale === "ja") localeModule = require("date-fns/locale/ja");
  if (locale === "ko") localeModule = require("date-fns/locale/ko");
  if (locale === "pt") localeModule = require("date-fns/locale/pt");
  if (locale === "ru") localeModule = require("date-fns/locale/ru");
  if (locale === "tr") localeModule = require("date-fns/locale/tr");
  if (locale === "zh") localeModule = require("date-fns/locale/zh-CN");
  return localeModule;
};
