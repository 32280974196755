import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const SettingsNav = () => {
  const [t] = useTranslation();

  return (
    <>
      <ul className="uk-tab" uk-tab="target: #uk-switcher-settings">
        <li>
          <Link to={"#"}>{t("settings.personal_details.header")}</Link>
        </li>
        <li>
          <Link to={"#"}>{t("settings.organisation.header")}</Link>
        </li>
      </ul>
    </>
  );
};

export default SettingsNav;
