import React from "react";

import format from "date-fns/format";

const LabelScore = ({ dir, score }) => {
  const date = score.date
    ? format(new Date(score.date.toDate()), "MMM yyyy")
    : "";

  const source = score.source.name
    ? score.source.name !== score.taster
      ? score.source.name
      : ""
    : "";
  const title = score.taster
    ? score.taster +
      (source ? "<br />" + source : "") +
      (date ? "<br />" + date : "")
    : source
    ? source + (date ? "<br />" + date : "")
    : date
    ? date
    : "";

  return (
    <div className="uk-card uk-card-small">
      <div
        uk-tooltip={"title:" + title + "; delay: 300"}
        className="uk-grid-collapse uk-text-center"
        data-uk-grid
      >
        <div
          className={
            dir === "ltr"
              ? "uk-text-uppercase uk-width-auto uk-float-left"
              : "uk-text-uppercase uk-width-auto uk-float-right"
          }
          style={
            dir === "ltr"
              ? {
                  fontFamily: "Roboto Mono",
                  fontSize: "14px",
                  fontWeight: 500,
                  minWidth: "30px",
                  backgroundColor: "white",
                  border: "1px solid rgb(206, 206, 213)",
                  borderRight: "none",
                  borderTopLeftRadius: "2px",
                  borderBottomLeftRadius: "2px",
                  color: "rgb(183, 31, 57)"
                }
              : {
                  fontFamily: "Roboto Mono",
                  fontSize: "14px",
                  fontWeight: 500,
                  minWidth: "30px",
                  backgroundColor: "white",
                  border: "1px solid rgb(206, 206, 213)",
                  borderLeft: "none",
                  borderTopRightRadius: "2px",
                  borderBottomRightRadius: "2px",
                  color: "rgb(183, 31, 57)"
                }
          }
        >
          {score.source.code}
        </div>
        <div
          className={
            dir === "ltr"
              ? "uk-width-auto uk-float-left"
              : "uk-width-auto uk-float-right"
          }
          style={
            dir === "ltr"
              ? {
                  fontSize: "14px",
                  fontWeight: 500,
                  minWidth: "30px",
                  padding: "0 4px",
                  whiteSpace: "nowrap",
                  backgroundColor: "rgb(228, 233, 236)",
                  border: "1px solid rgb(206, 206, 213)",
                  borderTopRightRadius: "2px",
                  borderBottomRightRadius: "2px",
                  color: "rgb(41, 43, 45)"
                }
              : {
                  fontSize: "14px",
                  fontWeight: 500,
                  minWidth: "30px",
                  padding: "0 4px",
                  whiteSpace: "nowrap",
                  backgroundColor: "rgb(228, 233, 236)",
                  border: "1px solid rgb(206, 206, 213)",
                  borderTopLeftRadius: "2px",
                  borderBottomLeftRadius: "2px",
                  color: "rgb(41, 43, 45)"
                }
          }
        >
          {score.value}
        </div>
      </div>
    </div>
  );
};

export default LabelScore;
