// axios
import axios from "axios";
// i18n
import i18n from "../../i18n";
// firebase
import { db } from "../../util/firebase";
// Actions and action types
import { closeModal } from "./modal_actions";

import { ERRORS_CLEAR, ERRORS_SET, NEWS_SET, UI_LOADING } from "../types";

// Sends signin link to user's email
export const getNews = (item) => (dispatch) => {
  // set async loading
  dispatch({ type: UI_LOADING });
  // Call the API getNews method [GET] with limit set @ 4
  axios
    .get(item && item !== "" ? `/news/${item}` : "/news?limit=4")
    .then((response) => {
      // Successful call let's process the response
      // console.log("getNews > Response:", response.data);
      dispatch({
        type: NEWS_SET,
        payload: response.data,
      });
      dispatch({ type: ERRORS_CLEAR });
    })
    .catch((e) => {
      console.error("getNews > Error:", e);
      // Failed call, update Redux reducer with error message
      dispatch({
        type: ERRORS_SET,
        payload: { message: e.message },
      });
    });
};
