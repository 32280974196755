import React from "react";

import { useTranslation } from "react-i18next";

// mdi Icon
import Icon from "@mdi/react";
import { mdiStar } from "@mdi/js";

import LabelScore from "./LabelScore";

const LabelScores = ({ scores }) => {
  const [t] = useTranslation();
  const dir = t("app.lang.dir");

  return (
    <div
      className="uk-grid-small uk-grid-match uk-flex uk-flex-middle"
      data-uk-grid
    >
      <div
        className="uk-width-auto"
        uk-tooltip={"title:" + t("label.scores") + "; delay: 300"}
      >
        <span className="uk-icon">
          <Icon
            path={mdiStar}
            size={1}
            className={
              dir === "ltr" ? "uk-margin-small-right" : "uk-margin-small-left"
            }
            style={{ color: "rgb(131, 131, 137)" }}
          />
        </span>
      </div>
      <div className="uk-width-expand">
        <div className="uk-grid uk-grid-small uk-text-center" data-uk-grid>
          {scores
            .sort((a, b) => a.date < b.date)
            .map((score, i) => (
              <div key={i} className="uk-width-auto">
                <LabelScore dir={dir} score={score} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LabelScores;
