import React from "react";

// mdi Icon
import Icon from "@mdi/react";
import { mdiEmailOutline, mdiFacebook, mdiLinkedin, mdiTwitter } from "@mdi/js";

import social from "../../util/helpers/social";

const SocialLinks = ({ link, title }) => {
  return (
    <ul className="uk-iconnav">
      <li>
        <a href={social.twitter(link, title)} target="_top">
          <span className="uk-icon icon-link">
            <Icon path={mdiTwitter} size={1} className="icon-twitter" />
          </span>
        </a>
      </li>
      <li>
        <a
          href={social.facebook(link, title)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="uk-icon icon-link">
            <Icon path={mdiFacebook} size={1} className="icon-facebook" />
          </span>
        </a>
      </li>
      <li>
        <a
          href={social.linkedin(link, title)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="uk-icon icon-link">
            <Icon path={mdiLinkedin} size={1} className="icon-linkedin" />
          </span>
        </a>
      </li>
      <li>
        <a href={social.mail(link, title)}>
          <span className="uk-icon icon-link">
            <Icon path={mdiEmailOutline} size={1} className="icon-mail" />
          </span>
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;
