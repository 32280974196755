import React from "react";
import { Redirect } from "react-router-dom";
// Hooks
import { useRouter } from "../../../hooks/use-router";

// Internal components
import ResetPassword from "../ResetPassword";
import Authenticate from "../Authenticate";

const Action = () => {
  const router = useRouter();
  // Get the action to complete.
  const mode = router.query.mode;
  // // console.log("mode:", mode);
  // // Get the one-time code from the query parameter.
  // const oobCode = router.query.oobCode;
  // // console.log("oobCode:", oobCode);
  // // (Optional) Get the API key from the query parameter.
  // const apiKey = router.query.apiKey;
  // // console.log("apiKey:", apiKey);
  // // Get the continueUrl from the query parameter.
  // const continueUrl = router.query.continueUrl;
  // // console.log("continueUrl:", continueUrl);
  // // Get the lang from the query parameter.
  // const lang = router.query.lang;
  // // console.log("lang:", lang);
  // // Get the link
  // const link = window.location.href;
  // // console.log("link", link);

  // Handle the user management action.
  switch (mode) {
    case "recoverEmail":
      return;
    case "resetPassword":
      // display resetPassword handler and UI
      return <ResetPassword />;
    case "verifyEmail":
      // display resetPassword handler and UI
      router.push("/");
    case "signIn":
      // display Authenticate handler and UI
      return <Authenticate />;
    default:
      alert("Error encountered");
  }

  return;
};

export default Action;
