import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const LandingMenu = () => {
  // i18n
  const [t] = useTranslation();

  return (
    <>
      <li>
        <Link to="#vision" data-uk-scroll="duration: 500">
          {t("app.nav.vision")}
        </Link>
      </li>
      <li>
        <Link to="#industry" data-uk-scroll="duration: 500">
          {t("app.nav.industry")}
        </Link>
      </li>
      <li>
        <Link to="#news" data-uk-scroll="duration: 500">
          {t("app.nav.news")}
        </Link>
      </li>
      <li>
        <Link to="#team" data-uk-scroll="duration: 500">
          {t("app.nav.team")}
        </Link>
      </li>
    </>
  );
};

export default LandingMenu;
