import React from "react";

const ButtonInput = ({
  children,
  disabled,
  isLoading,
  label,
  onClick,
  primary,
  style,
  submitting,
  width,
}) => {
  return (
    <div
      className={
        "uk-margin-remove uk-inline" +
        ((width === "auto" && " uk-width-auto") || " uk-width-1-1")
      }
    >
      <button
        className={
          "uk-button uk-button-default uk-margin-small-bottom uk-width-1-1" +
          (primary && "  uk-button-primary")
        }
        disabled={disabled}
        onClick={onClick}
        style={style}
      >
        {label}
        {(submitting || isLoading) && (
          <div
            className="uk-position-cover uk-flex uk-flex-center uk-flex-middle"
            data-uk-spinner
          ></div>
        )}
        {children && children}
      </button>
    </div>
  );
};

export default ButtonInput;
