import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import LabelOtherBrands from "./LabelOtherBrands";
import LabelOtherProperties from "./LabelOtherProperties";

const LabelOwnership = ({ ownership }) => {
  const [t] = useTranslation();

  const [isVisible, setVisibility] = useState(false);

  const toggleVisible = () => {
    setVisibility(prevState => ({
      isVisible: !prevState.isVisible
    }));
  };

  const other_index = ownership.length - 1;
  return (
    <>
      {ownership &&
        ownership.map((owner, index) => (
          <div
            key={index}
            className="uk-card uk-card-small uk-margin-top"
            style={{ width: "300px" }}
          >
            <h5 className="uk-text-300 uk-margin-remove-vertical">
              <strong>
                {t("label.ownership")}
                <a onClick={() => toggleVisible()} className="uk-float-right">
                  <span uk-icon={isVisible ? "chevron-up" : "chevron-down"} />
                </a>
              </strong>
            </h5>
            <hr className="uk-margin-small" />
            {isVisible && (
              <div className="uk-card-body uk-padding-remove">
                <h5 className="uk-text-bold">{owner.name}</h5>
                <div className="uk-grid-small uk-grid-match" data-uk-grid>
                  <div className="uk-width-auto">
                    <span uk-icon="location" />
                  </div>
                  <div className="uk-width-expand">
                    <address>
                      {owner.address.line1 && (
                        <div className="uk-text-small">
                          {owner.address.line1}
                        </div>
                      )}
                      {owner.address.line2 && (
                        <div className="uk-text-small">
                          {owner.address.line2}
                        </div>
                      )}
                      {owner.address.zip && owner.address.city && (
                        <div>
                          <span className="uk-text-small">
                            {owner.address.zip}&nbsp;
                          </span>
                          <span className="uk-text-small">
                            {owner.address.city}
                          </span>
                        </div>
                      )}
                      {owner.address.country && (
                        <div className="uk-text-small">
                          {owner.address.country}
                        </div>
                      )}
                    </address>
                  </div>
                </div>
                {owner.contact.tel && (
                  <div className="uk-grid-small uk-grid-match" data-uk-grid>
                    <div className="uk-width-auto">
                      <span uk-icon="receiver" />
                    </div>
                    <div className="uk-width-expand">
                      <div className="uk-text-small">{owner.contact.tel}</div>
                    </div>
                  </div>
                )}
                {owner.contact.fax && (
                  <div className="uk-grid-small uk-grid-match" data-uk-grid>
                    <div className="uk-width-auto">
                      <img src="/assets/svg/fax.svg" alt="fax" data-uk-svg />
                    </div>
                    <div className="uk-width-expand">
                      <div className="uk-text-small">{owner.contact.fax}</div>
                    </div>
                  </div>
                )}
                {owner.contact.mail && (
                  <div className="uk-grid-small uk-grid-match" data-uk-grid>
                    <div className="uk-width-auto">
                      <span uk-icon="mail" />
                    </div>
                    <div className="uk-width-expand">
                      <div>
                        <a
                          href={"mailto:" + owner.contact.mail}
                          className="uk-text-small"
                        >
                          {owner.contact.mail}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {owner.contact.website && (
                  <div className="uk-grid-small uk-grid-match" data-uk-grid>
                    <div className="uk-width-auto">
                      <span uk-icon="world" />
                    </div>
                    <div className="uk-width-expand">
                      <div>
                        <a
                          href={owner.contact.website}
                          target="_blank"
                          className="uk-text-small"
                        >
                          {owner.contact.website}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      {ownership[other_index].properties &&
        ownership[other_index].properties.length > 0 && (
          <LabelOtherProperties
            properties={ownership[other_index].properties}
          />
        )}
      {ownership[other_index].brands &&
        ownership[other_index].brands.length > 0 && (
          <LabelOtherBrands brands={ownership[other_index].brands} />
        )}
    </>
  );
};

export default LabelOwnership;
