import { ERRORS_CLEAR, ERRORS_SET, REFERER_SET, UI_LOADING } from "../types";

const INITIAL_STATE = {
  error: {},
  isLoading: false,
  referer: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ERRORS_CLEAR:
      return { ...state, isLoading: false, error: {} };
    case REFERER_SET:
      return { ...state, referer: action.payload };
    case UI_LOADING:
      return { ...state, isLoading: true };
    case ERRORS_SET:
      return { ...state, isLoading: false, error: { ...action.payload } };
    default:
      return state;
  }
};
