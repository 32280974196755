import * as firebase from "firebase/app";
import "firebase/analytics"
import "firebase/auth";
import "firebase/firestore";

import { firebase as config } from "../config";

if (!firebase.apps.length) {
  firebase.initializeApp(config);
  firebase.analytics()
}

const db = firebase.firestore();
const auth = firebase.auth();

export { auth, db, firebase };
