import React from "react";

const ThumbLabelContainer = ({ labels }) => {
  return (
    <div className="image-wrapper">
      {labels && labels.length > 0 && (
        <div
          className={labels.length > 1 ? "uk-grid-collapse uk-child-width-1-2": "uk-child-width-1-1"}
          uk-grid=""
          data-image-count={labels && labels.length}
        >
          {labels.map((label, i) => (
            <div key={i} className="label-container">
              <img data-index={i} src={label.url} alt="" draggable="false" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ThumbLabelContainer;

