import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const DashboardMenu = () => {
  // i18n
  const [t] = useTranslation();

  return (
    <>
      <li>
        <Link to="/dashboard">
          <span>{t("app.nav.inventories")}</span>
        </Link>
        <div
          uk-dropdown="pos: bottom-justify; boundary: .uk-navbar-container; boundary-align: true; offset: 0"
          style={{ height: 59, padding: 20 }}
        >
          <ul
            className="uk-nav uk-dropdown-nav"
            uk-tab="swiping: false; connect: #inventories-container; animation: uk-animation-fade"
          >
            <li className="uk-active">
              <a href="#">Left</a>
            </li>
            <li>
              <a href="#">Item</a>
            </li>
            <li>
              <a href="#">Item</a>
            </li>
            <li>
              <a>Disabled</a>
            </li>
          </ul>
        </div>
      </li>
    </>
  );
};

export default DashboardMenu;
