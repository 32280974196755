import React from "react";
import format from "date-fns/format";

import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { openModal } from "../../../../redux/actions/modal_actions";

import AccordionListItem from "../components/AccordionListItem";
import SpanFloat from "../../../../components/molecules/SpanFloat";

const SettingsNameLocation = ({ user }) => {
  const [t] = useTranslation();

  var locale = t("app.lang.code");
  // console.log("locale:", locale);
  let localeResource;
  if (locale === "ar") localeResource = require("date-fns/locale/ar-SA");
  else if (locale === "en") localeResource = require("date-fns/locale/en-GB");
  else if (locale === "zh") localeResource = require("date-fns/locale/zh-CN");
  else localeResource = require(`date-fns/locale/${locale}`);

  const dispatch = useDispatch();

  let missing = [];
  if (user) {
    if (!user.firstName) missing.push("first_name");
    if (!user.lastName) missing.push("last_name");
    if (!user.displayName) missing.push("display_name");
    if (!user.position) missing.push("position");
    if (!user.address.addressComponents.city) missing.push("city");
    if (!user.address.addressComponents.country.long_name)
      missing.push("country");
    if (!user.dateOfBirth) missing.push("date_of_birth");
  }

  return (
    <AccordionListItem
      header={t("settings.personal_details.name_location.header")}
      description={t("settings.personal_details.name_location.description")}
      info={
        missing && missing.length > 0 ? (
          <span
            className="uk-text-warning"
            uk-icon="icon: warning"
            style={{ padding: "0 15px" }}
          />
        ) : (
          <span
            className="uk-text-success"
            uk-icon="icon: check"
            style={{ padding: "0 15px" }}
          />
        )
      }
      dir={t("app.lang.dir")}
    >
      <div
        className="uk-section uk-section-default uk-section-xsmall uk-width-1-1 uk-width-4-5@s"
        style={{ padding: "0 5px" }}
      >
        <div className="uk-grid uk-grid-small">
          {user && user.firstName && user.lastName && (
            <div className="uk-text-small uk-width-1-2@s uk-margin-small-bottom">
              {t("lbl.full_name_", {
                firstName: user.firstName,
                lastName: user.lastName,
              })}
            </div>
          )}
          {user && user.displayName && (
            <div className="uk-text-small uk-width-1-2@s uk-margin-small-bottom">
              {t("lbl.display_name_", {
                displayName: user.displayName,
              })}
            </div>
          )}
          {user && user.dateOfBirth && (
            <div className="uk-text-small uk-width-1-1@s uk-margin-small-bottom">
              {t("lbl.date_of_birth_", {
                date: format(new Date(user.dateOfBirth), "PPP", {
                  locale: localeResource.default,
                }),
              })}
            </div>
          )}
          {user && user.position && (
            <div className="uk-text-small uk-width-1-2 uk-margin-small-bottom">
              {t("lbl.position_", {
                position: user.position,
              })}
            </div>
          )}
          {user &&
            (user.address.addressComponents.city ||
              user.address.addressComponents.country.long_name) && (
              <>
                <div className="uk-text-small uk-width-1-2 uk-margin-small-bottom">
                  {t("lbl.location")}: {user.address.addressComponents.city}
                  {user.address.addressComponents.country.long_name &&
                  user.address.addressComponents.city
                    ? ", " + user.address.addressComponents.country.long_name
                    : user.address.addressComponents.country.long_name}
                </div>
              </>
            )}
          {user && missing && missing.length > 0 ? (
            <>
              <div className="uk-text-small uk-text-warning uk-width-1-1 uk-margin-small-bottom">
                {t("lbl.missing")}{" "}
                {missing.map((miss, i) =>
                  i === missing.length - 1 ? (
                    <span key={i}>{t(`lbl.${miss}`)}</span>
                  ) : (
                    <span key={i}>{t(`lbl.${miss}`)}, </span>
                  )
                )}
              </div>
              <SpanFloat float="left">
                <button
                  className="uk-button uk-button-primary uk-button-small"
                  onClick={() =>
                    dispatch(
                      openModal("NameLocationModal", {
                        className: "uk-modal uk-flex-top uk-open",
                        style: { display: "block" },
                        header: t(
                          "settings.personal_details.name_location.header"
                        ),
                        description: t(
                          "settings.personal_details.name_location.description"
                        ),
                        user,
                      })
                    )
                  }
                >
                  {t("lbl.update")}
                </button>
              </SpanFloat>
            </>
          ) : (
            <SpanFloat float="left">
              <button
                className="uk-button uk-button-default uk-button-small"
                onClick={() =>
                  dispatch(
                    openModal("NameLocationModal", {
                      className: "uk-modal uk-flex-top uk-open",
                      style: { display: "block" },
                      header: t(
                        "settings.personal_details.name_location.header"
                      ),
                      description: t(
                        "settings.personal_details.name_location.description"
                      ),
                      user,
                    })
                  )
                }
              >
                {t("lbl.change")}
              </button>
            </SpanFloat>
          )}
        </div>
      </div>
    </AccordionListItem>
  );
};

export default SettingsNameLocation;
