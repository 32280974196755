import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// hooks
import { useRequireAuth } from "../../hooks/use-require-auth";
import { useRouter } from "../../hooks/use-router";
// mdi Icon
import Icon from "@mdi/react";
import { mdiCached, mdiDotsVertical } from "@mdi/js";
// Internal imports
import Head from "../../components/layout/Head";
import Loading from "../../components/layout/Loading";
import Layout from "../../components/layout/Layout";
import DashboardMenu from "./DashboardMenu";
import ThumbLabelContainer from "../../components/molecules/ThumbLabelContainer";

const Dashboard = () => {
  const auth = useRequireAuth();
  const router = useRouter();
  const [t] = useTranslation();
  const [user, setUser] = useState({});

  const thumbLabels = [
    {
      url:
        "https://firebasestorage.googleapis.com/v0/b/inwine-3f733.appspot.com/o/assets%2Flabel_images%2FFR2130Q00.jpg?alt=media&token=9b7d6693-81b5-4bf3-9487-abc8bf39108d",
    },
    {
      url:
        "https://firebasestorage.googleapis.com/v0/b/inwine-3f733.appspot.com/o/assets%2Flabel_images%2FFR21B0400.jpg?alt=media&token=062cda4f-3e21-4c1b-bc17-84dc95c26384",
    },
    {
      url:
        "https://firebasestorage.googleapis.com/v0/b/inwine-3f733.appspot.com/o/assets%2Flabel_images%2FAU2030100.jpg?alt=media&token=07d818de-5948-465c-afff-25e5ce6cb9c0",
    },
    {
      url:
        "https://firebasestorage.googleapis.com/v0/b/inwine-3f733.appspot.com/o/assets%2Flabel_images%2FFR2130U00.jpg?alt=media&token=4e424a17-7825-4355-a42d-322f9c0b62fc",
    },
  ];

  // re-route upon auth update
  useEffect(() => {
    alert(auth.user);
    if (auth.user.isNewUser) router.push("/signup");
    else setUser(auth.user);
  }, [auth.user]);

  if (!auth.user) {
    return <Loading />;
  }
  return (
    <>
      <Head
        title={t("inventory.title", {
          app: t("app.name"),
          user: user.displayname,
        })}
        type={t("app.type")}
        image="/assets/images/brand-74x29.png"
        description={t("app.description")}
      />
      <Layout menu={<DashboardMenu />}>
        <ul id="inventories-container" className="uk-switcher">
          <li>
            <div className="uk-container uk-container-expand">
              <div className="uk-section uk-section-large">
                <div uk-slider="">
                  <div className="uk-position-relative">
                    <div
                      className="uk-slider-container"
                      style={{ padding: "15px 0" }}
                    >
                      <ul className="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l uk-child-width-1-6@xl uk-grid">
                        <li>
                          <div
                            className="uk-card uk-card-inventory uk-visible-toggle"
                            tabIndex="-1"
                          >
                            <div className="uk-card-media-top">
                              <ThumbLabelContainer labels={thumbLabels} />
                            </div>
                            <div className="uk-card-body uk-position-relative">
                              <div className="uk-h4 uk-margin-remove-top uk-text-truncate">
                                <div
                                  className="uk-position-top-right uk-invisible-hover"
                                  uk-tooltip={`More options for Principal Bordeaux`}
                                  style={{
                                    top: 0,
                                    right: "0.2rem",
                                  }}
                                >
                                  <Link to={"#"}>
                                    <Icon
                                      path={mdiDotsVertical}
                                      size={0.9}
                                      color="#666666"
                                    />
                                  </Link>
                                </div>
                                Principal Bordeaux
                                <div className="uk-text-meta">Privé</div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="uk-card uk-card-inventory">
                            <div className="uk-card-media-top">
                              <img src="images/dark.jpg" alt="" />
                            </div>
                            <div className="uk-card-body">
                              <h4 className="uk-card-title">Headline</h4>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt.
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="uk-card uk-card-inventory">
                            <div className="uk-card-media-top">
                              <img src="images/light.jpg" alt="" />
                            </div>
                            <div className="uk-card-body">
                              <h4 className="uk-card-title">Headline</h4>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt.
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="uk-card uk-card-inventory">
                            <div className="uk-card-media-top">
                              <img src="images/photo2.jpg" alt="" />
                            </div>
                            <div className="uk-card-body">
                              <h4 className="uk-card-title">Headline</h4>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt.
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="uk-card uk-card-inventory">
                            <div className="uk-card-media-top">
                              <img src="images/photo3.jpg" alt="" />
                            </div>
                            <div className="uk-card-body">
                              <h4 className="uk-card-title">Headline</h4>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt.
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <a
                      className="uk-position-center-left-out uk-hidden-hover"
                      href="#"
                      data-uk-slidenav-previous
                      uk-slider-item="previous"
                    ></a>
                    <a
                      className="uk-position-center-right-out uk-hidden-hover"
                      href="#"
                      data-uk-slidenav-next
                      uk-slider-item="next"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat.
          </li>
          <li>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur, sed do eiusmod.
          </li>
        </ul>
      </Layout>
    </>
  );
};
export default Dashboard;
