import React, { useEffect, useState } from "react";

import slug from "slug";

import { useTranslation } from "react-i18next";

import algoliasearch from "algoliasearch";
import { algolia } from "../../../config";

import { useRouter } from "../../../hooks/use-router";

const client = algoliasearch(algolia.applicationId, algolia.adminApiKey);

const index = client.initIndex("iwin");

const SearchInput = ({ hitsPerPage }) => {
  // i18n
  const [t] = useTranslation();
  // Algolia
  const [hits, setHits] = useState([]);
  // router
  const router = useRouter();

  useEffect(() => {
    index.setSettings({
      searchableAttributes: ["displayName", "originDisplayName, iwin"],
      attributesToHighlight: ["displayName", "originDisplayName"],
      attributesToRetrieve: ["iwin", "displayName", "originDisplayName"],
      hitsPerPage
    });
  });

  const handleInputChange = e => {
    e.preventDefault();
    // console.log("value:", e.target.value);
    if (e.target.value !== "") {
      index.search({ query: e.target.value }, (err, content) => {
        // console.log("response:", content);
        setHits(content.hits);
      });
    } else {
      setHits([]);
    }
  };

  return (
    <>
      <form className="uk-search uk-search-default uk-width-1-1">
        <a
          href="#"
          className={t("app.lang.dir") === "ltr" ? "" : "uk-search-icon-flip"}
          data-uk-search-icon
        ></a>
        <input
          className="uk-search-input"
          type="search"
          placeholder={t("iwin.search.placeholder")}
          onChange={handleInputChange}
          style={{ width: "100%" }}
        />
        {hits && hits.length > 0 && (
          <div
            className="uk-width-1-1 uk-card uk-card-small uk-padding-remove uk-card-default uk-card-body uk-position-absolute"
            style={{
              zIndex: 10,
              height: "auto",
              background: "rgba(255,255,255,0.94)"
            }}
          >
            {hits.map((hit, i) => {
              const highlightDisplayName =
                hit._highlightResult.displayName.value;
              const highlightOriginDisplayName =
                hit._highlightResult.originDisplayName.value;
              return (
                <div
                  key={i}
                  className="uk-hover-link"
                  onClick={e =>
                    router.push(
                      `/iwin-masterfile/${hit.iwin}/${slug(
                        hit.displayName + " " + hit.originDisplayName
                      )}`
                    )
                  }
                  style={{ padding: "5px 0 5px 15px" }}
                >
                  <div
                    className="uk-grid-small uk-width-1-1 uk-text-left"
                    data-uk-grid
                  >
                    <div className="uk-highlight uk-text-bold uk-width-expand">
                      {highlightDisplayName ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: highlightDisplayName
                          }}
                        />
                      ) : (
                        hit.displayName
                      )}
                    </div>
                    <div className="uk-width-auto uk-text-iwin uk-text-bold">
                      {hit.iwin}
                    </div>
                    <div className="uk-width-1-1 uk-highlight uk-text-small uk-text-300 uk-margin-remove">
                      {highlightOriginDisplayName ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: highlightOriginDisplayName
                          }}
                        />
                      ) : (
                        hit.originDisplayName
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </form>
    </>
  );
};

export default SearchInput;
