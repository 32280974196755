import React from "react";

import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux/actions/modal_actions";

import ContactForm from "./ContactForm";

const ContactModal = ({ className, description, header, style }) => {
  // Redux dispatch
  const dispatch = useDispatch();

  return (
    <div className={className} style={style} data-uk-modal>
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <button
          onClick={() => dispatch(closeModal())}
          className="uk-modal-close-full uk-close-large"
          type="button"
          data-uk-close
        />
        <div className="uk-modal-header uk-padding-remove-horizontal">
          <div className="uk-h3 uk-text-300 uk-text-uppercase">
            {header}
            <div className="uk-text-meta">{description}</div>
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

export default ContactModal;
