import i18n from "i18next";
import path from "path";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // fallback language is en
    fallbackLng: "en",

    // language codes to lookup, i.e. en, not en-US
    load: "languageOnly",

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    // set the console debug messages on, or off
    debug: process.env.NODE_ENV !== "production",

    // set the handling of missing keys
    saveMissing: true,
    saveMissingTo: "all",

    // set the backend options
    backend: {
      loadPath: path.join(__dirname, "/locales/{{lng}}/{{ns}}.json"),
      addPath: path.join(__dirname, "/locales/{{lng}}/{{ns}}.missing.json"),
      allowMultiLoading: true
    },

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
      format: (value, format, lng) => {
        if (format === "uppercase") return value.toUpperCase();
        return value;
      }
    },
    // necessary for react i.e. async
    react: {
      useSuspense: false
    }
  });
export default i18n;
