import React, { useEffect, useState } from "react";

// i18n
import { useTranslation } from "react-i18next";

// Date formatting
import format from "date-fns/format";
import { dateLocale } from "../util/helpers/locale";

import Head from "../components/layout/Head";
import Footer from "../components/layout/Footer";

const PrivacyPolicy = () => {
  // i18n
  const [t] = useTranslation();
  // Format date
  const lang = t("app.lang.code");
  // State
  const [date, setDate] = useState(lang);

  useEffect(() => {
    setDate(format(new Date("2018-05-06"), "PPP", dateLocale(lang)));
  }, [lang]);

  return (
    <>
      <Head
        title={t("app.name") + " | " + t("app.legal.privacy_policy")}
        type={t("app.type")}
        image="/assets/inwine_home.jpg"
        description={t("app.description")}
      />
      <div className="uk-section uk-section-default">
        <div className="uk-container uk-container-small">
          <div className="uk-h2 uk-text-300 uk-heading-divider">
            {t("app.legal.privacy_policy")}
            <div className="uk-text-meta">
              {t("lbl.last_updated_", { date })}
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">Our Policy</div>
            <div>
              Welcome to the web site (the "Site") of inWine S.A.S ("inWine",
              "we", "us" and/or "our"). This Site is operated by inWine and has
              been created to provide information about our company and our
              platform and sharing services (together with the Site, the
              "Services") to our Service visitors ("you", "your"). This Privacy
              Policy sets forth an outline of the information that inWine
              gathers, in what ways that information is used, and the options
              that you have to access, correct, or delete such information.
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">Our Promise</div>
            <div>
              You own your data. We exist to help you get the most out of it. We
              want to be transparent in this regard, and we strongly believe in
              keeping your personal information personal, private and secure. We
              will not sell, rent, or otherwise share your personal information
              to anyone except as required to provide our service or as
              otherwise outlined in this Policy without first notifying you and
              providing an opportunity to consent.
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">
              Who Does This Policy Apply to
            </div>
            <div>
              <ul className="uk-list">
                <li>
                  <span className="uk-text-bold">Customers:</span> Any person
                  that registers individually or on behalf of an entity or
                  organization in order to use inWine Services.
                </li>
                <li>
                  <span className="uk-text-bold">Website Visitors:</span>{" "}
                  visitors to our Websites, as well as others that may opt in to
                  receive commercial communications from inWine. Under this
                  Policy, “Websites” shall refer to www.inwine.com and all
                  affiliated websites owned or operated by inWine. “Websites”
                  does not include any websites owned or operated by our
                  Customers, nor any where we acted as Registrar for said
                  Customer.
                </li>
                <li>
                  <span className="uk-text-bold">Event Attendees:</span> inWine
                  hosts a series of annual conferences and is an active
                  participant at many other conferences, summits, and additional
                  events. Any individuals that provide their information to
                  inWine or inWine representatives and affiliates when they
                  attend or register to attend inWine-sponsored events or others
                  where inWine participates.
                </li>
                <li>
                  <span className="uk-text-bold">End Users:</span> inWine may
                  process End User data on behalf of its Customers.
                  Subsequently, this Policy also applies to any End User that
                  visits a Customer website and whose information we process in
                  order to provide Services to our Customers.
                </li>
              </ul>
              <span className="uk-text-bold">Exclusions.</span> This Privacy
              Policy shall not apply to any unsolicited information that you
              provide to inWine through the Services or through any other means.
              This includes, but is not limited to, information posted to any
              public areas of the Services, such as forums, any ideas for new
              products or modifications to existing products, and other
              unsolicited submissions (collectively, "Unsolicited Information").
              All Unsolicited Information shall be deemed to be non-confidential
              and inWine shall be free to reproduce, use, disclose, and
              distribute such Unsolicited Information to others without
              limitation or attribution. This Policy does not apply to Customers
              websites. Customers are responsible for posting their own terms,
              conditions, and privacy policies. Each Customer is responsible for
              ensuring their compliance with all applicable laws and
              regulations, inclusive of their use of our Services by End Users.
              inWine does not knowingly collect information from individuals
              under the age of 18. If you are under the age of 18, please do not
              submit any Personal Data through the Services. We encourage
              parents and legal guardians to monitor their children's Internet
              usage and to help enforce our Privacy Policy by instructing their
              children never to provide Personal Data on the Services without
              their permission. If you have reason to believe that a child under
              the age of 18 has provided Personal Data to inWine through the
              Services, please contact privacy@inwine.com and we will endeavor
              to delete that information from our databases.
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">
              What Information Do We Collect
            </div>
            <div>
              <ul className="uk-list">
                <li>
                  <span className="uk-text-bold">
                    Customer Account Information.
                  </span>
                  When you register for a inWine account, we request your email
                  address. This information is your "Account Information" for
                  the purposes of this Privacy Policy. Account Information is
                  required to identify you as a Customer and permit you to
                  access your account(s). By voluntarily providing us with such
                  Account Information, you represent that you are the owner of
                  such personal data or otherwise have the requisite consent to
                  provide it to us.
                </li>
                <li>
                  <span className="uk-text-bold">
                    Customer Payment Information.
                  </span>
                  You are not required to enter your credit card information
                  unless and until you decide to continue with a paid
                  subscription to our Services. When you sign up for one of our
                  paid Services, you must provide billing information. The
                  information you will need to submit depends on which billing
                  method you choose. For example, if you pay with a credit card,
                  we will collect your card information and billing address,
                  although we do not store full credit card numbers or personal
                  account numbers; rather, we work with our preferred payments
                  partner, Stripe, to handle all transactions.
                </li>
                <li>
                  <span className="uk-text-bold">Website Visitors:</span> We
                  will collect your name, email address, and other contact
                  information. We ask for and—at your option—collect personal
                  information from you when you submit opt-in forms on our
                  Websites, including places where you can sign up for and agree
                  to receive commercial communications from us. We also may ask
                  you to submit personal information if you choose to use
                  interactive features of the Websites, including participation
                  in surveys, promotions, requesting customer support, or
                  otherwise communicating with us. We will send such
                  communications in accordance with applicable law.
                </li>
                <li>
                  <span className="uk-text-bold">Website Log Files.</span> When
                  you visit and interact with most websites, as well as ours and
                  including our Community board on inWine chat, we gather
                  certain information and store it in log files. This
                  information may include but is not limited to Internet
                  Protocol (IP) addresses, system configuration information,
                  URLs of referring pages, and location and language
                  preferences. We rotate logs frequently and only store log
                  files where absolutely necessary; e.g. to perform an abuse
                  investigation, to analyze a distributed denial of service
                  attack, etc. Website Cookies and Tracking. In operating the
                  Services, we may use a technology called "cookies." A cookie
                  is a piece of information that the computer hosting our
                  Services gives to your browser when you access the Services.
                  Our cookies help provide additional functionality to the
                  Services and help us analyze Services usage more accurately.
                  For instance, our Site may set a cookie on your browser that
                  allows you to access the Services without needing to remember
                  and then enter a password more than once during a visit to the
                  Site. In all cases in which we use cookies, we will not
                  collect Personal Data except with your permission. On most web
                  browsers, you will find a "help" section on the toolbar.
                  Please refer to this section for information on how to receive
                  notification when you are receiving a new cookie and how to
                  turn cookies off. We recommend that you leave cookies turned
                  on because they allow you to take advantage of some of the
                  Service features. Note that if you disable cookies entirely,
                  inWine’s Websites may not function properly.
                </li>
                <li>
                  <span className="uk-text-bold">
                    Interactive Website Elements.
                  </span>
                  The Websites may offer publicly accessible blogs, community
                  forums (e.g. inWine chat), comments sections, discussion
                  forums, or other interactive features ("Interactive
                  Elements"). If you choose to participate in any of these
                  Interactive Elements, please be aware that any information
                  that you post may be read, collected, and used by others who
                  access it. If you wish to remove your personal information
                  from any of our Interactive Elements, please see the section
                  below entitled, "What are your data rights".
                </li>
                <li>
                  <span className="uk-text-bold">Event Attendee.</span> We may
                  collect personal information such as name, address, phone
                  number and email address when participants register for or
                  attend a sponsored event or other events at which inWine
                  (and/or its affiliates or representatives) are present.
                </li>
                <li>
                  <span className="uk-text-bold">End Users.</span> It is our
                  Customers and their users who are responsible for the content
                  transmitted across our network (e.g., images, written content,
                  graphics, etc.). We collect End Users information when they
                  use our Customers’ websites, web applications, and APIs. This
                  information may include but is not limited to IP addresses,
                  system configuration information, and other information about
                  traffic to and from Customers websites (collectively, the "Log
                  Data"). We collect and use Log Data to operate, maintain, and
                  improve our Services in performance of our obligations under
                  our Customer agreements. For example, Log Data can help us to
                  detect new threats, identify malicious third parties, and
                  provide more robust security protection for our Customers. If
                  you are an individual from the European Economic Area (EEA),
                  please note that our legal basis for collecting and using your
                  personal information will depend on the personal information
                  collected and the specific context in which we collect it. We
                  normally will collect personal information from you only
                  where: (a) we have your consent to do so, (b) where we need
                  your personal information to perform a contract with you (e.g.
                  to deliver inWine Services you have requested), or (c) where
                  the processing is in our legitimate interests. Please note
                  that in most cases, if you do not provide the requested
                  information, inWine will not be able to provide the requested
                  service to you. In some cases, we may also have a legal
                  obligation to collect personal information from you, or may
                  otherwise need the personal information to protect your vital
                  interests or those of another person. Where we rely on your
                  consent to process your personal data, you have the right to
                  withdraw or decline consent at any time. Where we rely on our
                  legitimate interests to process your personal data, you have
                  the right to object. If you have any questions about or need
                  further information concerning the legal basis on which we
                  collect and use your personal information, please contact us
                  at privacy@inwine.com.
                </li>
              </ul>
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">
              How Do We Use Information We Collect
            </div>
            <div>
              inWine only processes personal information in a way that is
              compatible with and relevant for the purpose for which it was
              collected or authorised. As a general rule, all data we collect
              (including personal information, where applicable) may be used to:
              Provide, operate, maintain, improve, and promote the Website and
              Services; Enable you to access and use the Website and Services;
              Process and complete transactions, and send you related
              information, including purchase confirmations and invoices; Send
              transactional messages, including responses to your comments,
              questions, and requests; provide customer service and support; and
              send you technical notices, updates, security alerts, and support
              and administrative messages; Send commercial communications, in
              line with your communication preferences, such as providing you
              with information about products and services, features,
              newsletters, offers, promotions, and events about us and our
              partners; and send other news or information about us and our
              partners. Monitor and analyze trends, usage, and activities in
              connection with the Websites and Services and for marketing or
              advertising purposes; Comply with legal obligations as well as to
              investigate and prevent fraudulent transactions, unauthorized
              access to the Services, and other illegal activities; Personalize
              the Websites and Services, including providing features or content
              that match your interests and preferences. Information from Third
              Party Services. From time to time, we may combine information we
              collect as described above with personal information we obtain
              from third parties. For example, we may combine information
              entered through a inWine sales submission with information we
              receive from a third-party sales intelligence platform to enhance
              our ability to market our Services to Customers or potential
              Customers.
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">When We Share Information</div>
            <div>
              We retain the services of other companies that help us to run our
              business (“Service Providers”). These companies provide services
              to help us deliver customer support, process credit card payments,
              manage and contact existing Customers as well as sales leads,
              provide marketing support, and otherwise operate and improve our
              Services. These Service Providers may only process personal
              information pursuant to our instructions and in compliance both
              with this Privacy Policy and other applicable confidentiality and
              security measures and regulations, including our obligations under
              the EU-US and Swiss-US Privacy Shield frameworks described in
              Section 6, below. We do not permit our Service Providers to use
              any personal information we share with them for their own
              marketing purposes or for any other purpose than in connection
              with the services they provide to us. In addition to sharing with
              Service Providers as described above, we may also share your
              information with others in the following circumstances: With our
              resellers and other sales partners who may help us distribute the
              Services to Customers; In the event of a merger, sale, change in
              control, or reorganization of all our part of our business; When
              we are required to disclose personal information to respond to
              subpoenas, court orders, or legal process, or to establish or
              exercise our legal rights or defend against legal claims; Where we
              have a good-faith belief sharing is necessary to investigate,
              prevent, or take action regarding illegal activities, suspected
              fraud, situations involving potential threats to the physical
              safety of any person, violations of our Service Agreement, or as
              otherwise required to comply with our legal obligations; or as you
              may otherwise consent from to time to time. When We Link to Other
              Websites This Privacy Policy applies only to the Services. The
              Services may contain links to other web sites not operated or
              controlled by inWine (the "Third Party Sites"). The policies and
              procedures we described here do not apply to the Third Party
              Sites. The links from the Services do not imply that inWine
              endorses or has reviewed the Third Party Sites. We suggest
              contacting those sites directly for information on their privacy
              policies.
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">When We Aggregate Data</div>
            <div>
              When you interact with inWine through our Services, we receive and
              store certain non-identifiable information from Customers and
              their End Users. This information, such as Logs, which are
              collected passively cannot be used to specifically identify you.
              inWine may store such information itself or such information may
              be included in databases owned and maintained by inWine
              affiliates, agents or service providers. The Services may use such
              information and pool it with other information to track, for
              example, the total number of visitors to our Site, the number of
              visitors to each page of our Site, and the domain names of our
              visitors Internet service providers. It is important to note that
              no Personal Data is available or used in this process.
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">
              When We Transfer Data Internationally
            </div>
            <div>
              inWine is a French corporation with primarily storage of your
              information in the European Economic Area (the “EEA”). To
              facilitate our global operations, we may transfer and access such
              information from around the world, including from other countries
              in which inWine has operations to provide the Services. Whenever
              inWine shares personal information originating in the EEA or
              Switzerland with a inWine entity outside the EEA or Switzerland,
              it will do so under the EU standard contractual clauses or the
              Privacy Shield Frameworks detailed in this section. If you are
              accessing or using our Websites or Services or otherwise providing
              information to us, you are agreeing to the transfer of your
              personal information to the EEA and other jurisdictions in which
              we operate. inWine is certified under both the EEA Privacy Shield
              Frameworks as set forth by the EEA regarding the collection, use,
              and retention of personal information transferred from the US and
              Switzerland to the EEA, respectively. If there is any conflict
              between the terms in this Policy and the Privacy Shield
              Principles, the Privacy Shield Principles shall govern. For more
              information on the EEA Privacy Shield or Swiss-U.S. Privacy
              Shield, please visit the U.S. Department of Commerce’s Privacy
              Shield website at: https://www.privacyshield.gov/welcome.
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">What Are Your Data Rights</div>
            <div>
              <ul className="uk-list">
                <li>
                  <span className="uk-text-bold">
                    Customer, Website Visitors, and Attendees.
                  </span>{" "}
                  You have the right to access, correct, make updates to,
                  export, or delete your personal information. You may also
                  email us at privacy@inwine.com with any subsequent subject
                  access requests (use the term “SAR” as your email subject
                  line), and we will respond within thirty (30) days. Customers
                  also can access, correct, export, or update their Account
                  Information by editing their profile or organization record at
                  https://inwine.com.
                </li>
                <li>
                  <span className="uk-text-bold">End Users:</span> inWine has no
                  direct relationship with End Users. We rely on our Customers
                  to comply with the underlying legal requirements for subject
                  access requests. If an End User requests that we access,
                  correct, update, or delete their information, or no longer
                  wishes to be contacted by one of our Customers that use our
                  Services, we will direct that End User to contact the Customer
                  website(s) with which they interacted directly. Our Customers
                  are solely responsible for ensuring compliance with all
                  applicable laws and regulations with respect to their website
                  users.
                </li>
                <li>
                  <span className="uk-text-bold">Subject Access Requests.</span>{" "}
                  We consider it important to verify that a requestor is
                  inquiring about their own information before we can assist
                  with any request. Where a SAR implicates the personal data of
                  another individual, we balance the request against the risk of
                  violating another person’s rights. We will comply with subject
                  access requests to the extent required by applicable law or
                  the US-EU or US-Swiss Privacy Shield.
                </li>
              </ul>
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">
              How to Change Your Communication Preferences
            </div>
            <div>
              To keep your information accurate, current, and complete, please
              contact us as specified below. We will take reasonable steps to
              update any information in our possession that you have previously
              submitted via the Services. Note that you may also manage your
              communications preferences and the receipt of any commercial
              communication by clicking the “unsubscribe” link included at the
              bottom of all emails from inWine. You may also adjust your
              preferences through your account settings if you have a inWine
              account, or else send an email to privacy@inwine.com.
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">
              How We Handle Data Security, Integrity, and Access
            </div>
            <div>
              inWine takes all reasonable steps to protect the data that we
              receive from loss, misuse, and unauthorised access, disclosure,
              alteration, or destruction. We utilise appropriate physical,
              digital, and administrative protocols to keep your information
              secure, including the use of encryption services. Notwithstanding,
              no Internet or email transmission is ever fully secure or error
              free. In particular, email sent to or from the Services may not be
              secure. Therefore, take special care in deciding what information
              you send to us via email. For any questions about the security of
              your information, please contact privacy@inwine.com.
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">
              How We Will Notify You about Changes
            </div>
            <div>
              The Services and our business may change from time to time. As a
              result, at times it may be necessary for inWine to make changes to
              this Privacy Policy. inWine reserves the right to update or modify
              this Privacy Policy at any time and from time to time without
              prior notice. However, if we make changes that we believe will
              materially impact this Privacy Policy, we will promptly notify you
              of those changes and obtain consent (where applicable or
              required). Please review this Privacy Policy from time to time.
              This Privacy Policy was last updated on the date indicated above.
              Your continued use of the Services after any changes or revisions
              to this Privacy Policy shall indicate your agreement with the
              terms of such revised Privacy Policy.
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">
              How We Will Handle a Business Transfer
            </div>
            <div>
              As we develop our business, we may sell or buy businesses or
              assets. In the event of a corporate sale, merger, reorganisation,
              dissolution or similar event, we may assign or transfer this
              Policy, as well as all information covered thereunder.
            </div>
          </div>
          <div className="uk-section uk-section-default uk-section-xsmall">
            <div className="uk-h4 uk-text-300">How You Can Contact Us</div>
            <div>
              <address>
                inWine S.A.S
                <br />
                1 place Frédéric Ozanam
                <br />
                33200 Bordeaux
                <br />
                France
              </address>
              privacy@inwine.com
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
