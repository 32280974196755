import React, { useState } from "react";

import { useTranslation } from "react-i18next";

const LabelOtherProperties = ({ properties }) => {
  const [t] = useTranslation();

  const [isVisible, setVisibility] = useState(false);

  const toggleVisible = () => {
    setVisibility(prevState => ({
      isVisible: !prevState.isVisible
    }));
  };

  return (
    <>
      <div
        className="uk-card uk-card-small uk-margin-top"
        style={{ width: "300px" }}
      >
        <h5 className="uk-text-300 uk-margin-remove-vertical">
          <strong>
            {t("label.other_properties")}&nbsp;
            {!isVisible && (
              <span className="uk-badge uk-text-bold">{properties.length}</span>
            )}
            <a onClick={() => toggleVisible()} className="uk-float-right">
              <span uk-icon={isVisible ? "chevron-up" : "chevron-down"} />
            </a>
          </strong>
        </h5>
        <hr className="uk-margin-small" />
        {isVisible && (
          <div className="uk-card-body uk-padding-remove">
            <table className="uk-table uk-margin-small-top">
              <tbody>
                {properties
                  .sort((a, b) => a.name > b.name)
                  .map((property, i) => (
                    <tr key={i}>
                      <td className="uk-padding-remove-horizontal uk-padding-remove-top">
                        <strong>{property.name}</strong>
                        <br />
                        <span className="uk-text-meta">
                          {property.origin.country}
                          {property.origin.region &&
                            ", " + property.origin.region}
                          {property.origin.sub_region &&
                            ", " + property.origin.sub_region}
                          {property.origin.appelation &&
                            ", " + property.origin.appelation}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default LabelOtherProperties;
