import React, { useEffect, useState } from "react";

// i18n
import { useTranslation } from "react-i18next";

// hooks
import { useRequireAuth } from "../../../hooks/use-require-auth";

// Internal imports
import Head from "../../../components/layout/Head";
import Loading from "../../../components/layout/Loading";
import Layout from "../../../components/layout/Layout";
import SettingsMenu from "./SettingsMenu";
import PersonalDetails from "./PersonalDetails";
import PersonalSummary from "./PersonalSummary";

const Settings = () => {
  const auth = useRequireAuth();
  const [t] = useTranslation();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (auth.user) setUser(auth.user);
  }, [auth]);

  if (!user) {
    return <Loading />;
  } else {
    return (
      <>
        <Head
          title={
            user &&
            t("profile.title", {
              app: t("app.name"),
              user: user.displayName,
            })
          }
          type={t("app.type")}
          image="/assets/images/brand-74x29.png"
          description={t("app.description")}
        />
        <Layout menu={<SettingsMenu />}>
          <div className="uk-container">
            <div className="uk-section">
              <div className="uk-grid-small" uk-grid="">
                <div className="uk-width-1-3@m">
                  <PersonalSummary user={user} />
                </div>
                <div className="uk-width-2-3@m">
                  <PersonalDetails user={user} />
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
};

export default Settings;
