import React from "react";

const LabelVarietal = ({ dir, varietal }) => {
  return (
    <div className="uk-card uk-card-small">
      <div
        className="uk-grid-collapse uk-child-width-auto uk-text-center"
        data-uk-grid
      >
        <div
          className={
            dir === "ltr"
              ? "uk-float-left uk-text-nowrap"
              : "uk-float-right uk-text-nowrap"
          }
          style={
            dir === "ltr"
              ? {
                  fontSize: "12px",
                  fontWeight: 400,
                  padding: "1px 4px 0",
                  minWidth: "30px",
                  backgroundColor: "#b71f39",
                  border: "1px solid #b71f39",
                  borderRight: "none",
                  borderTopLeftRadius: "2px",
                  borderBottomLeftRadius: "2px",
                  color: "#ffffff"
                }
              : {
                  fontSize: "12px",
                  fontWeight: 400,
                  padding: "1px 4px 0",
                  minWidth: "30px",
                  backgroundColor: "#b71f39",
                  border: "1px solid #b71f39",
                  borderLeft: "none",
                  borderTopRightRadius: "2px",
                  borderBottomRightRadius: "2px",
                  color: "#ffffff"
                }
          }
        >
          {varietal.grape}
        </div>
        <div
          className={dir === "ltr" ? "uk-float-left" : "uk-float-right"}
          style={
            dir === "ltr"
              ? {
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "0 4px",
                  minWidth: "30px",
                  backgroundColor: "rgb(228, 233, 236)",
                  border: "1px solid rgb(206, 206, 213)",
                  borderTopRightRadius: "2px",
                  borderBottomRightRadius: "2px",
                  color: "rgb(41, 43, 45)"
                }
              : {
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "0 4px",
                  minWidth: "30px",
                  backgroundColor: "rgb(228, 233, 236)",
                  border: "1px solid rgb(206, 206, 213)",
                  borderTopLeftRadius: "2px",
                  borderBottomLeftRadius: "2px",
                  color: "rgb(41, 43, 45)"
                }
          }
        >
          {varietal.ratio}
          {"%"}
        </div>
      </div>
    </div>
  );
};

export default LabelVarietal;
