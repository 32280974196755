import React from "react";

import { useTranslation } from "react-i18next";

// mdi Icon
import Icon from "@mdi/react";
import { mdiFruitGrapes } from "@mdi/js";

import LabelVarietal from "./LabelVarietal";

const LabelVarietals = ({ varietals }) => {
  const [t] = useTranslation();
  const dir = t("app.lang.dir");

  return (
    <div
      className="uk-grid-small uk-grid-match uk-flex uk-flex-middle"
      data-uk-grid
    >
      <div
        className="uk-width-auto"
        uk-tooltip={"title:" + t("label.varietals") + "; delay: 300"}
      >
        <span className="uk-icon">
          <Icon
            path={mdiFruitGrapes}
            size={1}
            className={
              dir === "ltr" ? "uk-margin-small-right" : "uk-margin-small-left"
            }
            style={{ color: "rgb(131, 131, 137)" }}
          />
        </span>
      </div>
      <div className="uk-width-expand">
        <div className="uk-grid uk-grid-small uk-text-center" data-uk-grid>
          {varietals.map((varietal, i) => (
            <div key={i} className="uk-width-auto">
              <LabelVarietal dir={dir} varietal={varietal} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LabelVarietals;
