import React from "react";

// i18n
import { useTranslation } from "react-i18next";

const IconPosition = ({ icon, position, ratio, tooltip, vertical, width }) => {
  // i18n
  const [t] = useTranslation();

  return (
    <span
      className={
        vertical &&
        (t("app.lang.dir") === "ltr"
          ? `uk-position-${vertical}-right`
          : `uk-position-${vertical}-left`) +
          (position && position
            ? ` uk-position-${position}`
            : " uk-position-small")
      }
      uk-icon={
        icon && ratio ? `icon: ${icon}; ratio: ${ratio}` : `icon: ${icon}`
      }
      uk-tooltip={tooltip && `title: ${tooltip}; delay: 300`}
      width={width && width}
    />
  );
};

export default IconPosition;
