import React from "react";
import { Link } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// mdi Icon
import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";

// Internal components
import SignedInMenu from "../auth/SignedInMenu";
import SignedOutMenu from "../auth/SignedOutMenu";

const RightNav = ({ auth, children, dir, isMobile, t }) => {
  return (
    <div
      className={
        dir === "ltr"
          ? "uk-navbar-right uk-margin-medium-right"
          : "uk-navbar-left uk-margin-medium-left"
      }
    >
      <ul className="uk-navbar-nav">
        {isMobile ? (
          <li>
            <div className="mobile-menu-align uk-panel">
              <Link to={"#"}>
                <Icon path={mdiMenu} size={1.3} />
              </Link>
              <div uk-dropdown="pos: bottom-justify; boundary: .uk-navbar-container; boundary-align: true; offset:0">
                <div className="uk-flex uk-flex-1 uk-flex-center">
                  <ul className="uk-nav uk-dropdown-nav uk-width-2-3 uk-width-1-2@s">
                    {children}
                  </ul>
                </div>
              </div>
            </div>
          </li>
        ) : (
          <>
            {children}
            {/* {auth && auth.user && auth.user.isNewUser === false ? (
              <SignedInMenu dir={dir} isMobile={isMobile} user={auth.user} />
            ) : (
              <SignedOutMenu />
            )} */}
          </>
        )}
      </ul>
    </div>
  );
};

const TopBar = ({ auth, children, dir, isMobile }) => {
  // i18n
  const [t] = useTranslation();

  return (
    <div
      uk-sticky="animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky uk-dark; cls-inactive: uk-navbar-transparent uk-light; top: 80"
      style={{
        boxShadow: "0 0 10px rgba(0,0,0,.08)",
      }}
    >
      <nav
        className="uk-navbar-container"
        role="navigation"
        aria-label="Main Menu"
        data-uk-navbar
      >
        <div className={dir === "ltr" ? "uk-navbar-left" : "uk-navbar-right"}>
          <ul className="uk-navbar-nav">
            <li>
              <Link to="/">
                <img
                  src="/assets/favicon-48x48.png"
                  alt={t("app.name")}
                  style={{
                    margin:
                      dir === "ltr"
                        ? "auto auto auto 20px"
                        : "auto 20px auto auto",
                    height: 32,
                    width: 32,
                  }}
                />
              </Link>
            </li>
          </ul>
        </div>
        {/* {isMobile && (
          <div className="uk-navbar-center">
            <ul className="uk-navbar-nav">
              {auth && auth.user && auth.user.isNewUser === false ? (
                <SignedInMenu dir={dir} isMobile={isMobile} user={auth.user} />
              ) : (
                <SignedOutMenu />
              )}
            </ul>
          </div>
        )} */}
        <RightNav auth={auth} dir={dir} isMobile={isMobile} t={t}>
          {children}
        </RightNav>
      </nav>
    </div>
  );
};

export default TopBar;
