import { toastr } from "react-redux-toastr";

// i18n
import i18n from "../../i18n";

// firebase
import { db } from "../../util/firebase";

// Actions and action types
import { closeModal } from "./modal_actions";

import { ERRORS_CLEAR, ERRORS_SET, UI_LOADING } from "../types";

// Contact form message storage
export const storeMessage = message => async dispatch => {
  // isLoading...
  dispatch({ type: UI_LOADING });
  try {
    // store the data
    let data = {
      ...message,
      createdAt: new Date().toISOString()
    };
    // store the data in Firestore
    await db.collection("contact_requests").add(data);
    dispatch({ type: ERRORS_CLEAR });
  } catch (error) {
    console.log("error: ", error);
    dispatch({
      type: ERRORS_SET,
      payload: error.message
    });
  }
  dispatch(closeModal());
  // trigger a toastr
  toastr.success(i18n.t("lbl.sent"), i18n.t("form.contact.success"));
};
