import React from "react";

// Redux
import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux/actions/modal_actions";
import { useLockBodyScroll } from "../../../hooks/use-lock-body-scroll";

// Internal components
import ProfilePhoto from "./ProfilePhoto";

const ProfilePhotoModal = ({
  className,
  description,
  dir,
  header,
  isMobile,
  style,
  user,
}) => {
  // Redux store dispatch function
  const dispatch = useDispatch();

  // Call hook to lock body scroll
  useLockBodyScroll();

  return (
    <div className={className} style={style} data-uk-modal>
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <button
          onClick={() => dispatch(closeModal())}
          className="uk-modal-close-full uk-close-large"
          type="button"
          data-uk-close
        />
        <div className="uk-modal-header uk-padding-remove-horizontal">
          <div className="uk-h3 uk-text-300 uk-text-uppercase">
            {header}
            <div className="uk-text-meta">{description}</div>
          </div>
        </div>
        <ProfilePhoto
          dir={dir}
          header={header}
          isMobile={isMobile}
          onClose={closeModal}
          user={user}
        />
      </div>
    </div>
  );
};

export default ProfilePhotoModal;
