import React, { useEffect, useRef, useState } from "react";
// i18n
import { useTranslation } from "react-i18next";
// google apiKey
import usePlacesAutocomplete, {
  getGeocode,
  getDetails,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
// firebase
import { firebase } from "../../../config";
// helpers
import {
  getAddressComponents,
  getFormattedAddress,
  getLatLng,
  getUrl,
  getUtcOffsetMinutes,
} from "../../../util/helpers/googleAddress";
// hooks
import { useScript } from "../../../hooks/use-script";
// Internal components
import LabelDisplayInput from "./LabelDisplayInput";

const AddressInput = ({
  autofocus,
  className,
  disabled,
  help,
  icon,
  input: { onChange, ...restInput },
  type,
  label,
  mandatory,
  meta: { touched, error },
  optional,
  placeholder,
  validation,
  width,
}) => {
  // Load Google Maps API
  const script = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${firebase.apiKey}&libraries=places`
  );

  const {
    ready,
    suggestions: { status, data },
    setValue,
    value,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 500,
    callbackName: script,
  });

  // placeId
  const dataPlace = useRef({});
  const handleInput = ({ target: { value } }) => {
    // Update the keyword of the input element
    setValue(value);
    onChange(value);
  };

  const handleSelect = ({ place_id, description }) => {
    setValue(description, false);
    // structure address
    let address = {};
    let googleAddress = {};
    // Get address details
    getDetails({
      placeId: place_id,
      fields: [
        "address_components",
        "formatted_address",
        "formatted_address",
        "geometry",
        "url",
        "utc_offset_minutes",
      ],
    })
      .then((details) => {
        googleAddress = details;
        console.log(googleAddress);
        return getLatLng(googleAddress);
      })
      .then(({ lat, lng }) => {
        address.latLng = { lat, lng };
        return getFormattedAddress(googleAddress);
      })
      .then((formattedAddress) => {
        address.formattedAddress = formattedAddress;
        return getAddressComponents(googleAddress);
      })
      .then((addressComponents) => {
        address.addressComponents = addressComponents;
        return getUrl(googleAddress);
      })
      .then((url) => {
        address.url = url;
        return getUtcOffsetMinutes(googleAddress);
      })
      .then((utcOffsetMinutes) => {
        address.utcOffsetMinutes = utcOffsetMinutes;
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
    onChange(address);
    clearSuggestions();
  };

  // i18n
  const [t] = useTranslation();

  return (
    <div className={width ? `uk-width-1-1 ${width}@s` : "uk-width-1-1"}>
      <LabelDisplayInput
        dir={t("app.lang.dir")}
        label={label}
        mandatory={mandatory}
        optional={optional}
      />
      <div className="uk-form-controls">
        <Combobox
          onSelect={(value) => {
            const data = dataPlace.current[value];
            handleSelect(data);
          }}
          aria-label={label}
        >
          <ComboboxInput
            value={value}
            onChange={handleInput}
            disabled={disabled || !ready}
            autoFocus={autofocus}
            width={width}
            type={type}
            placeholder={
              optional ? `${placeholder} ${t("lbl.optional")}` : placeholder
            }
            autoComplete="none"
            className={
              touched && !!error
                ? `${className} uk-form-danger`
                : `${className}`
            }
          />
          <ComboboxPopover>
            <ComboboxList>
              {status === "OK" &&
                data.map(({ place_id, description }) => {
                  const value = `${description}`;
                  dataPlace.current[value] = {
                    place_id,
                    description,
                  };
                  return <ComboboxOption key={place_id} value={value} />;
                })}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
        <div className="uk-text-meta uk-margin-small-bottom uk-text-left">
          {help && (
            <>
              <span>{help}</span>
              <br />
            </>
          )}
          {validation === false
            ? ""
            : touched &&
              error && (
                <span className="uk-text-danger uk-align-left">
                  {error.substring(0, 4) === "err." ? t(error) : error}
                </span>
              )}
        </div>
      </div>
    </div>
  );
};

export default AddressInput;
