import React from "react";
import { useTranslation } from "react-i18next";

const CheckboxInput = ({
  className,
  help,
  input,
  label,
  mandatory,
  meta: { touched, error },
  placeholder,
  small,
  width,
}) => {
  const [t] = useTranslation();

  const inputField = (
    <input
      {...input}
      type="checkbox"
      placeholder={placeholder}
      checked={input.value}
      className={
        touched && !!error ? `${className} uk-form-danger` : `${className}`
      }
    />
  );

  return (
    <div
      className={
        width
          ? `uk-width-1-1 ${width}@s uk-flex uk-flex-middle uk-flex-center`
          : "uk-width-1-1 uk-flex uk-flex-middle uk-flex-center"
      }
    >
      <div className="uk-form-controls uk-margin">
        <div
          className={
            "uk-width-1-1 " +
            (t("app.lang.dir") === "ltr" ? "uk-text-left" : "uk-text-right")
          }
        >
          {label && (
            <label>
              <>
                {inputField}
                <span
                  className={
                    (mandatory && "uk-text-bold") +
                    (small && " uk-text-small") +
                    (t("app.lang.dir") === "ltr"
                      ? " uk-padding-small uk-padding-remove-right"
                      : " uk-padding-small uk-padding-remove-left")
                  }
                >
                  {label}
                  {mandatory && (
                    <span
                      style={{
                        color: "red",
                        marginTop: "-2px",
                        marginLeft: "3px",
                      }}
                    >
                      *
                    </span>
                  )}
                </span>
              </>
            </label>
          )}
        </div>
        <div className="uk-text-small uk-margin-small-bottom">
          {help && (
            <>
              <span className="uk-text-meta uk-text-left">{help}</span>
              <br />
            </>
          )}
          {touched && error && (
            <span className="uk-text-danger">
              {error.substring(0, 4) === "err." ? t(error) : error}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckboxInput;
