import React, { useState, useEffect } from "react";
// i18n
import { useTranslation } from "react-i18next";
// datepicker
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import subYears from "date-fns/subYears";
// internal components
import LabelDisplayInput from "./LabelDisplayInput";

const DateInput = ({
  dateInit,
  disabled,
  help,
  icon,
  input,
  label,
  mandatory,
  meta: { touched, error },
  optional,
  placeholder,
  placement,
  selected,
  validation,
  width,
  ...rest
}) => {
  // i18n
  const [t] = useTranslation();
  const [startDate, setStartDate] = useState(subYears(new Date(), 18));
  // update dateInit provide
  useEffect(() => {
    if (typeof dateInit !== "undefined") setStartDate(new Date(dateInit));
  }, [dateInit]);
  // i18n
  var locale = t("app.lang.code");
  // console.log("locale:", locale);
  let localeResource;
  if (locale === "ar") localeResource = require("date-fns/locale/ar-SA");
  else if (locale === "en") localeResource = require("date-fns/locale/en-GB");
  else if (locale === "zh") localeResource = require("date-fns/locale/zh-CN");
  else localeResource = require(`date-fns/locale/${locale}`);
  // Init locale for DatePicker
  registerLocale(locale, localeResource.default);

  const handleInput = (data) => {
    setStartDate(data);
    input.onChange(data);
  };

  return (
    <div className={width ? `uk-width-1-1 ${width}@s` : "uk-width-1-1"}>
      <LabelDisplayInput
        dir={t("app.lang.dir")}
        label={label}
        mandatory={mandatory}
        optional={optional}
      />
      <div className="uk-form-controls">
        <div className="uk-inline uk-width-1-1">
          {icon && (
            <span
              className={
                t("app.lang.dir") === "ltr"
                  ? "uk-form-icon"
                  : "uk-form-icon uk-form-icon-flip"
              }
              uk-icon={"icon: " + icon}
            />
          )}
          <DatePicker
            {...rest}
            locale={localeResource.default}
            dateFormat="P"
            selected={startDate}
            maxDate={startDate}
            onChange={handleInput}
            width={width}
            placeholder={placeholder}
            disabled={disabled}
            className={
              touched && !!error ? "uk-input uk-form-danger" : "uk-input"
            }
            popperPlacement={placement ? placement : "auto"}
            popperModifiers={{
              flip: { enabled: false },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
              },
            }}
          />
        </div>
        <div className="uk-text-small uk-margin-small-bottom">
          {help && (
            <>
              <span className="uk-text-meta uk-text-left">{help}</span>
              <br />
            </>
          )}
          {validation === false
            ? ""
            : touched &&
              error && (
                <span className="uk-text-danger">
                  {error.substring(0, 4) === "err." ? t(error) : error}
                </span>
              )}
        </div>
      </div>
    </div>
  );
};

export default DateInput;
