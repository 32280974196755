import React, { useEffect, useState } from "react";

import axios from "axios";

// i18n
import { useTranslation } from "react-i18next";

// Redux
import { useSelector } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { combineValidators, isRequired } from "revalidate";

// Internal helpers
import { toTitleCase } from "../../../../util/helpers";

import CompanyInput from "../../elements/CompanyInput";
import ButtonInput from "../../elements/ButtonInput";
import HiddenInput from "../../elements/HiddenInput";
import SpanFloat from "../../../molecules/SpanFloat";

const validate = combineValidators({
  company: isRequired({ message: "err.company.name.required" }),
});

const lowercase = (value) => value && value.toLowerCase();
const titlecase = (value) => value && toTitleCase(value);
const uppercase = (value) => value && value.toUpperCase();

const CompanyDetails = ({
  auth,
  handleSubmit,
  isLoading,
  invalid,
  pristine,
  submitting,
}) => {
  // Get the i18next object
  const { t } = useTranslation();
  // page dir
  const dir = t("app.lang.dir");
  // Get redux form object values
  const selector = formValueSelector("company_details_form");
  const state = useSelector((state) => state);
  const company = selector(state, "company");
  // API search results
  const [results, setResults] = useState([]);

  let user = auth.user;

  useEffect(() => {
    if (company && company !== "") {
      auth.getCompanies({ search: company });
    }
  }, [company]);

  // API search function
  const searchCharacters = (search) => {
    // return axios(
    //   `${process.env.REACT_APP_SIRENE_FULLTEXT}${search}?page=1&per_page=10`,
    //   {
    //     method: "GET",
    //   }
    // )
    // .then((r) => r.json())
    // .then((r) => r.data.results)
    // .catch((error) => {
    //   console.error(error);
    //   return [];
    // });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="uk-form-stacked uk-width-2-3 uk-align-center"
    >
      <div className="uk-grid uk-grid-small" uk-grid="">
        <Field
          name="company"
          mandatory
          autofocus
          label={t("lbl.company")}
          component={CompanyInput}
          className="uk-input"
          width="uk-width-1-1"
          type="text"
          placeholder={t("plh.company")}
          normalize={uppercase}
        />
        {/* <hr
          className="uk-width-1-1 uk-margin-small"
          style={{ marginLeft: "15px" }}
        /> */}
        <Field name="locale" component={HiddenInput} />
        <div className="uk-width-1-1">
          <SpanFloat float="right">
            <ButtonInput
              primary
              disabled={invalid || submitting || isLoading || pristine}
              action="submit"
              isLoading={submitting || isLoading}
            >
              {t("lbl.save")}
            </ButtonInput>
          </SpanFloat>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "company_details_form",
  validate,
})(CompanyDetails);
