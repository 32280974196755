import React, { useEffect } from "react";

// i18n
import { useTranslation } from "react-i18next";
// Cookies
import { useCookies } from "react-cookie";
// Router
import { useRouter } from "../../hooks/use-router";
// mdi Icon
import Icon from "@mdi/react";
import {
  mdiCurrencyEurOff,
  mdiEarth,
  mdiFormatListCheckbox,
  mdiLifebuoy,
  mdiLockOutline,
  mdiUpdate,
} from "@mdi/js";

import Head from "../../components/layout/Head";
import LandingLayout from "../../components/layout/LandingLayout";
import IwinMasterfileMenu from "./IwinMasterfileMenu";
import CookieConsent from "../../components/layout/CookieConsent";
import SearchInput from "../../components/forms/elements/SearchInput";

const IwinMasterfile = () => {
  // i18n
  const [t] = useTranslation();
  // page dir
  const dir = t("app.lang.dir");
  // cookies
  const [cookies, setCookie] = useCookies(["accepts_policy"]);
  // Router
  const router = useRouter();

  useEffect(() => {
    if (typeof cookies !== "undefined")
      window.addEventListener("scroll", handleAccept);
    return () => {
      if (typeof cookies !== "undefined")
        window.removeEventListener("scroll", handleAccept);
    };
  });

  const handleAccept = (e) => {
    e.preventDefault();
    setCookie("accepts_policy", true, { path: "/" });
  };

  const handleDecline = (e) => {
    e.preventDefault();
    setCookie("accepts_policy", false, { path: "/" });
  };

  return (
    <>
      <Head
        title={t("app.name") + " | " + t("iwin.masterfile")}
        type={t("app.type")}
        image="/assets/inwine_home.jpg"
        description={t("app.description")}
      />
      <LandingLayout landingMenu={<IwinMasterfileMenu />}>
        <div className="uk-inline uk-overflow-hidden">
          <img
            className="uk-animation uk-transform-origin-top-right"
            src="/assets/images/_identify-410x700.jpg"
            srcSet="/assets/images/_identify-410x700.jpg 410w, /assets/images/_identify-640x700.jpg 640w, /assets/images/_identify-960x700.jpg 960w, /assets/images/_identify-1200x900.jpg 1200w, /assets/images/_identify-1600x900.jpg 1600w, /assets/images/_identify-1920x1080.jpg 1920w"
            sizes="(max-width: 640px), (min-width: 640px), (max-width: 960px), (max-width: 1200px), (max-width: 1600px), (max-width: 1920px), 100vw"
            alt="IWIN Masterfile"
            uk-scrollspy="cls: uk-animation-kenburns; repeat: true"
            style={{
              height: "100vh",
              width: "100%",
              objectFit: "cover",
              backgroundPosition: "center",
            }}
          />
          <div
            className="uk-overlay-default uk-position-cover"
            style={{
              background:
                "url(/assets/images/background-image-overlay-full.jpg)",
              backgroundRepeat: "repeat",
              opacity: "0.3",
            }}
            data-uk-height-viewport
          />
          <div className="uk-width-1-2 uk-position-center uk-position-z-index uk-text-center uk-light">
            <div className="uk-grid uk-grid-small" data-uk-grid>
              <div className="uk-heading-large uk-text-300 uk-width-5-6 uk-width-2-3@s uk-align-center">
                {t("iwin.masterfile")}
              </div>
              <div className="uk-main-subheading uk-width-5-6 uk-width-2-3@s uk-align-center">
                {t("iwin.description")}
              </div>
              <div className="uk-width-5-6 uk-width-2-3@s uk-align-center">
                <SearchInput hitsPerPage={8} />
              </div>
            </div>
          </div>
        </div>
        <div
          id="data"
          className="uk-container uk-section"
          style={{ paddingTop: 110 }}
        >
          <div className="uk-section-header">{t("iwin.data.title")}</div>
          <div className="uk-section-subheader">{t("iwin.data.subtitle")}</div>
          <div
            data-uk-scrollspy={
              dir === "ltr"
                ? "target: > div; cls: uk-animation-slide-left-medium"
                : "target: > div; cls: uk-animation-slide-right-medium"
            }
          >
            <p
              className="uk-h4 uk-text-300 uk-text-justify uk-margin-large-bottom"
              style={{ marginTop: 50 }}
            >
              {t("iwin.data.p1")}
            </p>
            <div
              className="uk-grid uk-grid-large uk-child-width-1-2@s"
              data-uk-grid
            >
              <div>
                <div className="uk-card uk-card-iw">
                  <div className="uk-card-madia-top">
                    <img
                      src="assets/images/_data_fragmentation-1200x800.jpg"
                      alt="data-fragmentation"
                    />
                  </div>
                  <div className="uk-card-body">
                    <div className="uk-card-header">
                      {t("iwin.data.title1")}
                    </div>
                    <p className="uk-h4 uk-text-300 uk-text-justify">
                      {t("iwin.data.subtitle1")}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="uk-card uk-card-iw">
                  <div className="uk-card-madia-top">
                    <img
                      src="assets/images/_information_asymmetry-1200x800.jpg"
                      alt="data-fragmentation"
                    />
                  </div>
                  <div className="uk-card-body">
                    <div className="uk-card-header">
                      {t("iwin.data.title2")}
                    </div>
                    <p className="uk-h4 uk-text-300 uk-text-justify">
                      {t("iwin.data.subtitle2")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="masterfile"
          className="uk-section uk-section-primary"
          style={{ paddingTop: 110 }}
        >
          <div className="uk-container">
            <div className="uk-section-header">{t("iwin.what.title")}</div>
            <div className="uk-section-subheader">
              {t("iwin.what.subtitle")}
            </div>
            <div
              data-uk-scrollspy={
                dir === "ltr"
                  ? "target: > div; cls: uk-animation-slide-left-medium"
                  : "target: > div; cls: uk-animation-slide-right-medium"
              }
            >
              <div className="uk-card uk-margin" style={{ marginTop: 50 }}>
                <div className="uk-card-body uk-align-center">
                  <p className="uk-h4 uk-text-300 uk-text-justify">
                    {t("iwin.what.acronym")}
                  </p>
                  <p className="uk-h4 uk-text-300 uk-text-justify">
                    {t("iwin.what.p1")}
                  </p>
                  <p className="uk-h4 uk-text-300 uk-text-justify">
                    {t("iwin.what.p2")}
                  </p>
                  <p className="uk-h4 uk-text-300 uk-text-justify">
                    {t("iwin.what.p3")}
                  </p>
                  <p className="uk-h4 uk-text-300 uk-text-justify">
                    {t("iwin.what.p4")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="benefits"
          className="uk-container uk-section"
          style={{ paddingTop: 110 }}
        >
          <div className="uk-section-header">{t("iwin.whyiwin.title")}</div>
          <div className="uk-section-subheader">
            {t("iwin.whyiwin.subtitle")}
          </div>
          <div
            data-uk-scrollspy={
              dir === "ltr"
                ? "target: > div; cls: uk-animation-slide-left-medium"
                : "target: > div; cls: uk-animation-slide-right-medium"
            }
          >
            <div
              className="uk-card uk-card-small uk-margin uk-text-center"
              style={{ marginTop: 50 }}
            >
              <div className="uk-card-body uk-text-middle">
                <div className="uk-child-width-1-3@s uk-margin" data-uk-grid>
                  <div className="uk-margin-large-bottom">
                    <Icon path={mdiEarth} color="#999" size={3} />
                    <div className="uk-card-header">
                      {t("iwin.whyiwin.item1.title")}
                      <br />
                    </div>
                    <p className="uk-text-300">
                      {t("iwin.whyiwin.item1.description")}
                    </p>
                  </div>
                  <div className="uk-margin-large-bottom">
                    <Icon path={mdiFormatListCheckbox} color="#999" size={3} />
                    <div className="uk-card-header">
                      {t("iwin.whyiwin.item2.title")}
                      <br />
                    </div>
                    <p className="uk-text-300">
                      {t("iwin.whyiwin.item2.description")}
                    </p>
                  </div>
                  <div className="uk-margin-large-bottom">
                    <Icon path={mdiLockOutline} color="#999" size={3} />
                    <div className="uk-card-header">
                      {t("iwin.whyiwin.item3.title")}
                      <br />
                    </div>
                    <p className="uk-text-300">
                      {t("iwin.whyiwin.item3.description")}
                    </p>
                  </div>
                  <div className="uk-margin-large-bottom">
                    <Icon path={mdiLifebuoy} color="#999" size={3} />
                    <div className="uk-card-header">
                      {t("iwin.whyiwin.item4.title")}
                      <br />
                    </div>
                    <p className="uk-text-300">
                      {t("iwin.whyiwin.item4.description")}
                    </p>
                  </div>
                  <div className="uk-margin-large-bottom">
                    <Icon path={mdiCurrencyEurOff} color="#999" size={3} />
                    <div className="uk-card-header">
                      {t("iwin.whyiwin.item5.title")}
                      <br />
                    </div>
                    <p className="uk-text-300">
                      {t("iwin.whyiwin.item5.description")}
                    </p>
                  </div>
                  <div className="uk-margin-large-bottom">
                    <Icon path={mdiUpdate} color="#999" size={3} />
                    <div className="uk-card-header">
                      {t("iwin.whyiwin.item6.title")}
                      <br />
                    </div>
                    <p className="uk-text-300">
                      {t("iwin.whyiwin.item6.description")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LandingLayout>
      {!cookies.accepts_policy && (
        <CookieConsent onAccept={handleAccept} onDecline={handleDecline} />
      )}
    </>
  );
};

export default IwinMasterfile;
