import React, { useEffect, useState } from "react";

// i18n
import { Trans, useTranslation } from "react-i18next";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Hooks
import { useAuth } from "../../../hooks/use-auth";
import { useRouter } from "../../../hooks/use-router";

// Internal components
import Head from "../../../components/layout/Head";
import Loading from "../../../components/layout/Loading";
import EmailForm from "../../../components/forms/auth/Email";

const ForgotPassword = () => {
  // Get auth state and re-render anytime it changes
  const auth = useAuth();
  // Get the router object
  const router = useRouter();
  // Get the i18next object
  const { t } = useTranslation();
  // Get redux ui object
  const isLoading = useSelector(state => state.ui.isLoading);
  const [email, setEmail] = useState(null);

  // Corporate signIn initiated
  const handleEmail = email => {
    const response = auth.sendPasswordResetEmail(email.email);
    if (response) setEmail(email.email);
  };

  return (
    <>
      <Head
        title={t("forgot_password.header", { app: t("app.name") })}
        type={t("app.type")}
        image="/assets/images/brand-74x29.png"
        description={t("app.description")}
      />
      <div className="uk-container uk-container-small">
        <div className="uk-section uk-section-small">
          {isLoading && <Loading />}
          {!isLoading && email === null && (
            <>
              <div className="uk-h3 uk-text-uppercase uk-text-left">
                <Trans
                  i18nKey="forgot_password.title"
                  values={{ app: t("app.name") }}
                />
              </div>
              <div className="uk-text-normal uk-margin-small-top uk-margin-remove-bottom">
                {t("forgot_password.enter_email.subtitle")}
              </div>
              <div className="uk-margin">
                <EmailForm onSubmit={handleEmail} isLoading={isLoading} />
              </div>
            </>
          )}
          {!isLoading && email !== null && (
            <>
              <div className="uk-h3 uk-text-uppercase uk-text-left">
                {t("forgot_password.check_email.title")}
              </div>
              <div className="uk-text-normal uk-margin-small-top uk-margin-remove-bottom">
                <Trans
                  i18nKey="forgot_password.check_email.p1"
                  values={{ email }}
                />
              </div>
              <div className="uk-text-normal uk-margin-small-top uk-margin-remove-bottom">
                {t("forgot_password.check_email.p2")}
              </div>
              <div className="uk-margin">
                {t("forgot_password.check_email.did_not_receive")}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
