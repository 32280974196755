import React, { useEffect, useState } from "react";
// i18n
import { Trans, useTranslation } from "react-i18next";
// Redux
import { useSelector } from "react-redux";
// Hooks
import { useRequireAuth } from "../../../hooks/use-require-auth";
import { useRouter } from "../../../hooks/use-router";
// Internal components
import Head from "../../../components/layout/Head";
import PersonalDetailsForm from "../../../components/forms/auth/PersonalDetails";
import CompanyDetailsForm from "../../../components/forms/auth/CompanyDetails";

const Signup = () => {
  // i18n
  const { t } = useTranslation();
  const locale = t("app.lang.code");
  const dir = t("app.lang.dir");
  // auth
  const auth = useRequireAuth();
  // router
  const router = useRouter();
  // redux
  const ui = useSelector((state) => state.ui);
  // state
  const [isNewUser, setIsNewUser] = useState(null);
  const [isOrganisation, setIsOrganisation] = useState(null);
  const [userName, setUserName] = useState(null);
  // componentWillUpdate
  useEffect(() => {
    // console.log("Signup > useEffect > auth:", auth);
    if (auth && auth.user) {
      // console.log("Signup > useEffect > auth.user:", auth.user);
      if (auth.user.userName) setUserName(auth.user.userName);
      if (auth.user.isOrganisation)
        setIsOrganisation(Boolean(auth.user.isOrganisation));
      if (auth.user.isNewUser) setIsNewUser(Boolean(auth.user.isNewUser));
    }
  }, [
    auth,
    auth.user,
    auth.user.userName,
    auth.user.isNewUser,
    auth.user.isOrganisation,
  ]);

  useEffect(() => {
    // console.log("Signup > useEffect > isOrganisation:", isOrganisation);
    // console.log("Signup > useEffect > isNewUser:", isNewUser);
    if (
      isOrganisation &&
      isOrganisation === false &&
      isNewUser &&
      isNewUser === false &&
      userName !== null
    )
      router.push(`/@${userName}`);
  }, [isNewUser, isOrganisation, userName]);
  // Signup initiated
  const handlePersonalDetails = (user) => {
    auth.signup({ ...user, locale: t("app.lang.code") });
  };
  const handleCompanyDetails = (user) => {
    auth.signup({ ...user, locale: t("app.lang.code") });
  };

  return (
    <>
      <Head
        title={t("signup.header", { app: t("app.name") })}
        type={t("app.type")}
        image="/assets/images/brand-74x29.png"
        description={t("app.description")}
      />
      {auth.user !== null && (
        <div className="uk-container">
          <div className="uk-section uk-section-branded">
            {auth.user && !isOrganisation && isNewUser && (
              <div className="uk-flex uk-flex-center uk-flex-1">
                <div className="uk-card uk-card-transparent">
                  <div className="uk-card-header uk-text-center">
                    <img
                      src={`/assets/logo_inwine_${locale}.png`}
                      alt="inWine"
                      style={{ width: "50%", maxWidth: 200 }}
                    />
                  </div>
                  <div className="uk-card-body">
                    <div className="uk-h2 uk-text-300 uk-text-uppercase uk-text-center">
                      <Trans
                        i18nKey="signup.title"
                        values={{ app: t("app.name") }}
                      />
                      <p className="uk-h4 uk-text-uppercase">
                        {t("signup.personal_details.description")}
                      </p>
                    </div>
                    <PersonalDetailsForm
                      onSubmit={handlePersonalDetails}
                      auth={auth}
                      isLoading={ui.isLoading}
                    />
                    <div className="uk-text-small uk-text-danger uk-width-5-6">
                      {ui.error && ui.error.message}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {auth.user && isNewUser && isOrganisation && (
              <div className="uk-flex uk-flex-center uk-flex-1">
                <div className="uk-card uk-card-transparent">
                  <div className="uk-card-header uk-text-center">
                    <img
                      src={`/assets/logo_inwine_${locale}.png`}
                      alt="inWine"
                      style={{ width: "50%", maxWidth: 200 }}
                    />
                  </div>
                  <div className="uk-card-body">
                    <div className="uk-h2 uk-text-300 uk-text-uppercase uk-text-center">
                      <Trans
                        i18nKey="signup.title"
                        values={{ app: t("app.name") }}
                      />
                      <p className="uk-h4 uk-text-uppercase">
                        {t("signup.organisation.description")}
                      </p>
                    </div>
                    <CompanyDetailsForm
                      onSubmit={handleCompanyDetails}
                      auth={auth}
                      isLoading={ui.isLoading}
                    />
                    <div className="uk-text-small uk-text-danger uk-width-5-6">
                      {ui.error && ui.error}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
