import React from "react";

// i18n
import { useTranslation } from "react-i18next";

const SpanFloat = ({ children, float }) => {
  // i18n
  const [t] = useTranslation();

  const dir = t("app.lang.dir");

  return (
    <span
      className={
        float === "right" || float === true
          ? dir === "ltr"
            ? "uk-float-right"
            : "uk-float-left"
          : dir === "ltr"
          ? "uk-float-left"
          : "uk-float-right"
      }
    >
      {children}
    </span>
  );
};

export default SpanFloat;
