import React from "react";
import { useTranslation } from "react-i18next";

import AccordionListItem from "../components/AccordionListItem";

const SettingsEmail = ({ user }) => {
  const [t] = useTranslation();

  return (
    <AccordionListItem
      header={t("settings.personal_details.email_addresses.header")}
      description={t("settings.personal_details.email_addresses.description")}
      info={
        user &&
        user.email &&
        t("settings.personal_details.email_addresses.total_count", {
          count: user.email && user.email.length
        })
      }
      dir={t("app.lang.dir")}
    >
      <table className="uk-width-1-1 uk-width-5-6@s uk-table uk-table-striped">
        <tbody>
          {user &&
            user.email &&
            user.email.map((el, i) => (
              <tr key={i}>
                <td style={{ padding: "5px" }}>{el.email}</td>
                <td style={{ padding: "5px" }}>{t(`lbl.${el.type}`)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </AccordionListItem>
  );
};

export default SettingsEmail;
