import React from "react";

import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux/actions/modal_actions";

import { useAuth } from "../../../hooks/use-auth";
import { useLockBodyScroll } from "../../../hooks/use-lock-body-scroll";

import NameLocationForm from "../../forms/auth/NameLocation";

const NameLocationModal = ({ className, description, header, style }) => {
  const auth = useAuth();
  const dispatch = useDispatch();

  // Call hook to lock body scroll
  useLockBodyScroll();

  const handleSubmitData = (values) => {
    values.dateOfBirth = new Date(values.dateOfBirth).toISOString();
    auth.updateUserProfile(values);
    dispatch(closeModal());
  };

  return (
    <div className={className} style={style} data-uk-modal>
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <button
          onClick={() => dispatch(closeModal())}
          className="uk-modal-close-full uk-close-large"
          type="button"
          data-uk-close
        />
        <div className="uk-modal-header uk-padding-remove-horizontal">
          <div className="uk-h3 uk-text-300 uk-text-uppercase">
            {header}
            <div className="uk-text-meta">{description}</div>
          </div>
        </div>
        <NameLocationForm
          onClose={closeModal}
          user={auth.user}
          onSubmit={handleSubmitData}
        />
      </div>
    </div>
  );
};

export default NameLocationModal;
