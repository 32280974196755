import React, { useEffect } from "react";

// App i18n
import { useTranslation } from "react-i18next";

// Cookies
import { useCookies } from "react-cookie";

// Redux
import { Field, reduxForm } from "redux-form";
import {
  combineValidators,
  composeValidators,
  createValidator,
  isRequired,
  matchesField
} from "revalidate";

// Internal components
import HiddenInput from "../../elements/HiddenInput";
import TextInput from "../../elements/TextInput";
import ButtonInput from "../../elements/ButtonInput";

// Email validator
const isValidPassword = createValidator(
  message => value => {
    if (
      value &&
      !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i.test(value)
    ) {
      return message;
    }
  },
  "err.password.format"
);
// Validators
const validate = combineValidators({
  password: composeValidators(
    isValidPassword,
    isRequired({ message: "err.password.required" })
  )(),
  passwordConfirm: matchesField("password")({
    message: "err.password.matches"
  })
});

const Password = ({ change, handleSubmit, isLoading, invalid, submitting }) => {
  // i18n
  const [t] = useTranslation();
  // Get cookies object
  const [cookies] = useCookies(["signer_role"]);
  const signer =
    cookies.signer_role === "person"
      ? { role: "corporate", color: "#f26446" }
      : { role: "person", color: "#5dabf8" };

  useEffect(() => {
    change("locale", t("app.lang.code"));
  });

  return (
    <>
      <form onSubmit={handleSubmit} className="uk-form-stacked uk-width-medium">
        <Field
          name="password"
          component={TextInput}
          type="password"
          icon="lock"
          autoComplete="off"
          className="uk-input"
          placeholder={t("plh.password")}
        />
        <Field
          name="passwordConfirm"
          component={TextInput}
          type="password"
          icon="lock"
          autoComplete="off"
          className="uk-input"
          placeholder={t("plh.password_confirm")}
        />
        <Field name="locale" component={HiddenInput} />
        <div className="uk-margin">
          <ButtonInput
            disabled={invalid || submitting || isLoading}
            action="submit"
            isLoading={submitting || isLoading}
            label={t("lbl.submit")}
            style={
              invalid || submitting || isLoading
                ? {}
                : { backgroundColor: `${signer.color}`, color: "#fff" }
            }
          />
        </div>
      </form>
    </>
  );
};

export default reduxForm({
  form: "password_form",
  validate
})(Password);
