import React, { useEffect } from "react";

import slug from "slug";

import { useRouter } from "../../../hooks/use-router";
import LabelImageThumb from "../image/LabelImageThumb";

const LabelOtherWine = ({ dir, wines }) => {
  const router = useRouter();

  const onLabelClick = wine => {
    router.history.push(`/iwin-masterfile/${wine.iwin}/${slug(wine.name)}`);
  };

  return (
    <>
      {wines && (
        <table className="uk-table uk-table-hover uk-margin-remove-top">
          <tbody>
            {wines.map((wine, i) => (
              <tr key={i} onClick={e => onLabelClick(wine, i)}>
                <td className="uk-padding-remove-horizontal uk-text-center">
                  <LabelImageThumb wine={wine} />
                </td>
                <td className="uk-padding-remove-horizontal">
                  <h4 className="uk-margin-remove-bottom">
                    {wine.displayName}
                  </h4>
                  <p className="uk-margin-remove-vertical">
                    {wine && wine.colourImageURL && (
                      <img
                        src={wine.colourImageURL}
                        style={{
                          height: "15px",
                          margin:
                            dir === "ltr"
                              ? "-4px 5px auto auto"
                              : "-4px auto auto 5px"
                        }}
                        alt={wine.colour}
                        data-uk-img
                      />
                    )}
                    {wine.originDisplayName}
                  </p>
                  {wine.classification && wine.classification.length > 0 && (
                    <p className="uk-text-small uk-margin-remove-top">
                      {wine.classification[0].title}
                      {wine.classification[0].name && (
                        <span className="uk-text-meta">
                          {" " + wine.classification[0].name}
                        </span>
                      )}
                    </p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default LabelOtherWine;
