import React, { useState, useEffect, useContext, createContext } from "react";
import { auth, db, firebase } from "../util/firebase";
// axios
import axios from "axios";
// i18n
import { useTranslation } from "react-i18next";
// redux
import { useDispatch } from "react-redux";
import {
  ERRORS_CLEAR,
  ERRORS_SET,
  UI_LOADING,
  USER_EMAIL_SENT,
  USER_EMAIL_VERIFIED,
} from "../redux/types";

import { getId } from "../util/helpers";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
const useProvideAuth = () => {
  // User state
  const [user, setUser] = useState(null);
  // Redux dispatch
  const dispatch = useDispatch();
  // i18n
  const [t] = useTranslation();
  // Auth token state
  const [sToken, setToken] = useState(localStorage.iToken);
  // Set axios' default token value
  axios.defaults.headers.common["Authorization"] = sToken;
  // Auth link
  const authLink =
    process.env.NODE_ENV === "production"
      ? "https://inwine.com"
      : "http://localhost:3000";

  const sendPasswordResetEmail = async (email) => {
    dispatch({ type: UI_LOADING });
    // proceed to the password reset
    try {
      await auth.sendPasswordResetEmail(email);
      window.localStorage.setItem("pending_email", email);
      dispatch({ type: ERRORS_CLEAR });
      return true;
    } catch (err) {
      // console.error("Error:", err);
      if (err.code === "auth/user-not-found") {
        dispatch({
          type: ERRORS_SET,
          payload: { message: err.message },
        });
      } else
        dispatch({
          type: ERRORS_SET,
          payload: { message: err.message },
        });
    }
  };

  const verifyPasswordResetCode = async (actionCode) => {
    dispatch({ type: UI_LOADING });
    // Proceed to the link check
    try {
      // send password reset link via email
      let email = await auth.verifyPasswordResetCode(actionCode);
      dispatch({ type: ERRORS_CLEAR });
      return JSON.stringify(email);
    } catch (err) {
      dispatch({
        type: ERRORS_SET,
        payload: { message: err.message },
      });
    }
  };
  const confirmPasswordReset = (code, password) => {
    return auth.confirmPasswordReset(code, password).then(() => {
      return true;
    });
  };
  // Delete an existing profile photo
  const deleteProfilePhoto = (photo) => {
    // set async loading
    dispatch({ type: UI_LOADING });
    // Call for the user's profile photo removal method [POST]
    axios
      .delete(`/profile/photo/${photo.photoId}`)
      .then((response) => {
        // update user object with the new photo
        getUserData();
        // Update Redux reducers
        dispatch({ type: ERRORS_CLEAR });
      })
      .catch((err) => {
        console.error(err.response);
        // Update Redux reducers
        dispatch({
          type: ERRORS_SET,
          payload: err.response.data,
        });
      });
  };
  // Get the user's profile data
  const getCompanies = (data) => {
    // set async loading
    dispatch({ type: UI_LOADING });
    // Call the API profile method [POST]
    axios
      .post("/companies", { search: data })
      .then((response) => {
        // console.log("Profile > Response:", response);
        // Successful call, store the user's profile data
        console.log(response.data);
        // Update Redux reducers
        dispatch({ type: ERRORS_CLEAR });
      })
      .catch((err) => {
        // console.error("Error:", err.response);
        // Failed call, update Redux reducer with error message
        dispatch({
          type: ERRORS_SET,
          payload: { message: err.response },
        });
      });
  };
  // getIDToken or refresh
  const getIdToken = () =>
    new Promise((resolve, reject) => {
      try {
        auth.currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
          let iToken = `Bearer ${idToken}`;
          // Set axios' default token value
          axios.defaults.headers.common["Authorization"] = iToken;
          setToken(iToken);
          resolve(idToken);
        });
      } catch (err) {
        console.err({ code: err.code, message: err.message });
        setUser(false);
        reject({ code: err.code, message: err.message });
      }
    });
  // Get the user's profile data
  const getUserData = (user) => {
    // set async loading
    dispatch({ type: UI_LOADING });
    let data = {};
    // Get the user data
    db.doc(`/users/${user.uid}`)
      .get()
      .then((doc) => {
        // We have the user's document
        if (doc.exists) {
          // store the user's data in the user's object
          data = {
            uid: doc.id,
            displayName: doc.data().displayName || "",
            photoUrl: doc.data().photoUrl || "",
            phoneNumber: doc.data().phoneNumber || "",
            ...doc.data(),
          };
          // Update Redux reducers
          dispatch({ type: ERRORS_CLEAR });
          setUser(data);
        }
        // console.log("getUserData > user:", user);
        return db.collection(`/users/${user.uid}/profileImage`).get();
      })
      .then((images) => {
        let photos = [];
        images.forEach((doc) => {
          photos.push({ photoId: doc.id, ...doc.data() });
        });
        data = {
          ...user,
          photos,
        };
        setUser(user);
      })
      .catch((err) => {
        // Failed call, update Redux reducer with error message
        dispatch({
          type: ERRORS_SET,
          payload: { message: err.message },
        });
        // An error happened.
        console.error("profile > Error :", err);
      });
  };
  // checks validity of email signin
  const isValidEmail = async (email) => {
    let providers;
    try {
      providers = await auth.fetchSignInMethodsForEmail(email);
      console.log("Providers:", providers);
      if (providers.length === 0) {
        // This email has not signed up yet
        return true;
      } else {
        // This email was already used for signup
        return false;
      }
    } catch (err) {
      console.error("Error:", err);
      return;
    }
  };
  // Checks if the link is valid and signins
  const isValidSignInLink = (link) => {
    // init variables
    let email, iToken, data, userId;
    // set async loading
    dispatch({ type: UI_LOADING });
    // Call the API isValidSignin method
    if (auth.isSignInWithEmailLink(link)) {
      email = localStorage.getItem("emailForSignin");
      // The client SDK parses the link
      auth
        .signInWithEmailLink(email, link)
        .then((result) => {
          console.log("isValidSignInLink > result:", result);
          userId = result.user.uid;
          data = {
            createdAt: new Date(
              result.user.metadata.creationTime
            ).toISOString(),
            email: result.user.email,
            isNewUser: result.additionalUserInfo.isNewUser,
            lastLoginAt: new Date(
              result.user.metadata.lastSignInTime
            ).toISOString(),
            uid: result.user.uid,
          };
          //   // Successful call, store the email locally for check
          //   return result.user.getIdToken(true);
          // })
          // .then((token) => {
          //   iToken = `Bearer ${token}`;
          //   // Store/update the token locally
          //   localStorage.setItem("iToken", iToken);
          // remove email from storage
          localStorage.removeItem("emailForSignin");
          dispatch({ type: USER_EMAIL_VERIFIED });
          // Store the user data
          if (data.isNewUser) {
            return db.doc(`/users/${data.uid}`).set(
              {
                email: [{ type: "primary", email: data.email }],
                isNewUser: true,
                createdAt: data.createdAt,
                lastSignIn: data.lastLoginAt,
              },
              { merge: true }
            );
          } else {
            return db
              .doc(`/users/${data.uid}`)
              .set({ lastSignIn: data.lastLoginAt }, { merge: true });
          }
        })
        .then(() => {
          // get the user data
          getUserData(data);
        })
        .catch((err) => {
          console.error("isValidSignin > signInWithEmailLink:", err);
          // remove email from storage
          localStorage.removeItem("emailForSignin");
          dispatch({
            type: ERRORS_SET,
            payload: { code: err.code, message: err.message },
          });
        });
    }
  };
  // Checks if a username already exists
  const isValidUsername = (username) => {
    // set async loading
    dispatch({ type: UI_LOADING });
    // Call the API profile method [POST]
    axios
      .post("/signup/isValidUsername", { username })
      .then((response) => {
        // console.log("isValidUsername > Response:", response);
        // Update Redux reducers
        dispatch({ type: ERRORS_CLEAR });
        // Successful call return the boolean
        return response.data.isValid;
      })
      .catch((err) => {
        // console.error("Error:", err);
        // Failed call, update Redux reducer with error message
        dispatch({
          type: ERRORS_SET,
          payload: { message: err.message },
        });
      });
  };
  // Set profile photo as top photo
  const setProfilePhoto = (photo) => {
    // set async loading
    dispatch({ type: UI_LOADING });
    // Call for the user's profile photo set method [POST]
    axios
      .post(`/profile/photo/${photo.photoId}`)
      .then(() => {
        // update user object with the new photo
        getUserData(user);
        // Update Redux reducers
        dispatch({ type: ERRORS_CLEAR });
      })
      .catch((err) => {
        console.error(err.response);
        // Update Redux reducers
        dispatch({
          type: ERRORS_SET,
          payload: err.response.data,
        });
      });
  };
  // Sends signin link to user's email
  const signin = (data) => {
    // set async loading
    dispatch({ type: UI_LOADING });
    // amend data with the authLink for actionCodeSettings
    data = { ...data, authLink: authLink + "/action" };
    // console.log("Signin > data:", data);
    // Call the API signin method [POST]
    axios
      .post("/signin", data)
      .then((response) => {
        // console.log("Signin > Response:", response);
        // Successful call, store the email locally for check
        localStorage.setItem("emailForSignin", data.email);
        // Update Redux reducers
        dispatch({ type: USER_EMAIL_SENT });
        dispatch({ type: ERRORS_CLEAR });
      })
      .catch((e) => {
        console.error("Error:", e);
        // Failed call, update Redux reducer with error message
        dispatch({
          type: ERRORS_SET,
          payload: { message: e },
        });
      });
  };
  // Store user's personal details
  const signup = (data) => {
    // set async loading
    dispatch({ type: UI_LOADING });
    // console.log("User:", data);
    let userToStore = {
      address: data.address,
      dateOfBirth: new Date(data.dateOfBirth).toISOString(),
      displayName:
        data.userName.trim() !== ""
          ? data.userName.trim()
          : data.firstName.trim().concat(" ", data.lastname.trim()),
      firstName: data.firstName.trim(),
      isNewUser: data.isOrganisation ? Boolean(data.isOrganisation) : false,
      lastName: data.lastName.trim(),
      locale: data.locale,
      language: data.locale,
      isOrganisation:
        (data.isOrganisation && Boolean(data.isOrganisation)) || false,
      photoURL: "https://inwine.com/assets/user.png",
      userName:
        data.userName.trim() !== ""
          ? data.userName.trim()
          : data.firstName.trim().concat(" ", data.lastname.trim()),
    };
    // console.log("Signup > User:", userToStore);
    // Update user profile
    db.doc(`/users/${user.uid}`)
      .set(userToStore, { merge: true })
      .then(() => {
        getUserData(user);
        // Update Redux reducers
        dispatch({ type: ERRORS_CLEAR });
      })
      .catch((err) => {
        console.error("Error:", err.message);
        // Failed call, update Redux reducer with error message
        dispatch({
          type: ERRORS_SET,
          payload: { message: err.message },
        });
      });
    // set async loading
    dispatch({ type: UI_LOADING });
  };
  // Signout the user from the application
  const signout = () => {
    // set async loading
    dispatch({ type: UI_LOADING });
    // Call for the user's signout method [GET]
    auth
      .signOut()
      .then(() => {
        // Remove the token locally
        localStorage.removeItem("iToken");
        setUser(false);
        // Update Redux reducers
        dispatch({ type: ERRORS_CLEAR });
      })
      .catch((err) => {
        // Oops, an error occured
        console.error(err);
        // Still remove the token locally
        localStorage.removeItem("iToken");
        setUser(false);
        // Updtae Redux reducers
        dispatch({
          type: ERRORS_SET,
          payload: { message: err.message },
        });
      });
  };
  // Uploads a new profile photo
  const uploadProfilePhoto = (file, filename) => {
    // set async loading
    dispatch({ type: UI_LOADING });
    // store profile image in firestore and storage
    // create a form object
    var formData = new FormData();
    formData.append("image", file);
    formData.append("filename", filename);
    // Call for the user's profile photo upload method [POST]
    getIdToken()
      .then((token) => {
        console.log({ token });
        axios
          .post("/profile/photo", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            // update user object with the new photo
            getUserData(user);
            // Update Redux reducers
            dispatch({ type: ERRORS_CLEAR });
          })
          .catch((err) => {
            console.error(err.response);
            // Update Redux reducers
            dispatch({
              type: ERRORS_SET,
              payload: err.response,
            });
          });
      })
      .catch((err) => {
        console.error("uploadProfilePhoto > Error:", err);
      });
  };
  // Update user's profile data
  const updateUserProfile = async (user) => {
    dispatch({ type: UI_LOADING });
    // update the user's profile
    try {
      let activeUser = auth.currentUser;
      // console.log("activeUser:", activeUser);
      let userToStore = user && getNewUser(user);
      await db.doc(`/users/${activeUser.uid}`).update({
        ...userToStore,
        isNewUser: firebase.firestore.FieldValue.delete(),
        isOrganisation: user.isOrganisation,
        locale: user.locale,
        updatedAt: new Date().toISOString(),
      });
      getUserData(auth.currentUser);
      dispatch({ type: ERRORS_CLEAR });
    } catch (err) {
      dispatch({
        type: ERRORS_SET,
        payload: err.message,
      });
    }
  };

  const getNewUser = (user) => {
    console.log("getUserData > User:", user);
    const creaDate = new Date().toISOString();
    let retUser = {
      createdAt: creaDate,
      displayname: user.firstname.trim().concat(" ", user.lastname.trim()),
      firstname: user.firstname.trim(),
      fullname: user.firstname.trim().concat(" ", user.lastname.trim()),
      lastname: user.lastname.trim(),
      lastSignInTime: creaDate,
      username: getId(16),
    };
    return retUser;
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getUserData(user);
      } else {
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //   // create the listener
  //   const eventListener = () => {
  //     setToken(localStorage.getItem("iToken"));
  //     checkToken();
  //   };

  //   // add the event listener
  //   window.addEventListener("storage", eventListener);

  //   // remove the event listener on cleanup
  //   return () => {
  //     window.removeEventListener("storage", eventListener);
  //   };
  // }, ["storage"]);

  // Return the user object and auth methods
  return {
    confirmPasswordReset,
    deleteProfilePhoto,
    getCompanies,
    getIdToken,
    getUserData,
    isValidEmail,
    isValidSignInLink,
    isValidUsername,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    setProfilePhoto,
    user,
    uploadProfilePhoto,
    updateUserProfile,
    verifyPasswordResetCode,
  };
};
