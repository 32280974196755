import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
// Redux hooks
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../redux/actions/news_actions";
import { useRouter } from "../../hooks/use-router";

// Other functions
import slug from "slug";
import format from "date-fns/format";
// mdi Icon
import Icon from "@mdi/react";
import { mdiFormatQuoteOpen, mdiFormatQuoteClose, mdiPlus } from "@mdi/js";

import Head from "../../components/layout/Head";
import LandingLayout from "../../components/layout/LandingLayout";
import LandingMenu from "./LandingMenu";
import CookieConsent from "../../components/layout/CookieConsent";

const LandingPage = () => {
  // i18n
  const [t] = useTranslation();
  var locale = t("app.lang.code");
  // to convert date to locale format
  let localeResource;
  if (locale === "ar") localeResource = require("date-fns/locale/ar-SA");
  else if (locale === "en") localeResource = require("date-fns/locale/en-GB");
  else if (locale === "zh") localeResource = require("date-fns/locale/zh-CN");
  else localeResource = require(`date-fns/locale/${locale}`);
  // page dir
  const dir = t("app.lang.dir");
  // router
  const router = useRouter();
  // cookies
  const [cookies, setCookie] = useCookies(["accepts_policy"]);
  // news
  const news = useSelector((state) => state.news.items);
  const dispatch = useDispatch();
  const readingTime = (ev) => {
    const wordsPerMinute = 200; // Average case.
    let result = t("lbl.min_to_read", { count: 0 });
    let textLength = ev && ev.split(" ").length; // Split by words
    if (textLength > 0) {
      let value = Math.ceil(textLength / wordsPerMinute);
      result = t("lbl.min_to_read", { count: value });
    }
    return result;
  };
  useEffect(() => {
    if (typeof cookies !== "undefined")
      window.addEventListener("scroll", handleAccept);
    return () => {
      if (typeof cookies !== "undefined")
        window.removeEventListener("scroll", handleAccept);
    };
  });
  useEffect(() => {
    dispatch(getNews());
  }, [dispatch]);

  const handleAccept = (e) => {
    e.preventDefault();
    let d = new Date();
    d.setTime(d.getTime() + 20 * 365 * 24 * 60 * 60);
    setCookie("accepts_policy", true, {
      path: "/",
      expires: d,
      domain: "inwine.com",
      secure: true,
    });
  };

  const handleDecline = (e) => {
    e.preventDefault();
    let d = new Date();
    d.setTime(d.getTime() + 20 * 365 * 24 * 60 * 60);
    setCookie("accepts_policy", false, {
      path: "/",
      expires: d,
      domain: "inwine.com",
      secure: true,
    });
  };

  return (
    <>
      <Head
        title={t("app.name") + " | " + t("app.home")}
        type={t("app.type")}
        image="/assets/inwine_home.jpg"
        description={t("app.description")}
      />
      <LandingLayout landingMenu={<LandingMenu />}>
        <div className="uk-inline uk-overflow-hidden">
          <img
            className="uk-animation-reverse uk-transform-origin-top-right"
            src="/assets/images/home-410x700.jpg"
            srcSet="/assets/images/home-410x700.jpg 410w, /assets/images/home-640x700.jpg 640w, /assets/images/home-960x700.jpg 960w, /assets/images/home-1200x900.jpg 1200w, /assets/images/home-1600x900.jpg 1600w, /assets/images/home-1920x1080.jpg 1920w"
            sizes="(max-width: 640px), (min-width: 640px), (max-width: 960px), (max-width: 1200px), (max-width: 1600px), (max-width: 1920px), 100vw"
            alt="homepage"
            uk-scrollspy="cls: uk-animation-kenburns; repeat: true"
            style={{
              height: "100vh",
              width: "100%",
              objectFit: "cover",
              backgroundPosition: "center",
            }}
          />
          <div
            className="uk-overlay-default uk-position-cover"
            style={{
              background:
                "url(/assets/images/background-image-overlay-full.png)",
              backgroundRepeat: "repeat",
              opacity: "0.3",
              minHeight: "calc(100vh)",
            }}
            data-uk-height-viewport
          />
          <div
            className={
              dir === "ltr"
                ? "uk-position-large uk-position-bottom-left uk-position-z-index uk-light"
                : "uk-position-large uk-position-bottom-right uk-position-z-index uk-light"
            }
            style={
              dir === "ltr"
                ? { margin: "auto auto 10% 50px" }
                : { margin: "auto 50px 10% auto" }
            }
          >
            <div className="uk-heading-xlarge uk-text-300">{t("app.name")}</div>
            <div className="uk-main-subheading">{t("app.home")}</div>
          </div>
        </div>
        <div
          id="vision"
          className="uk-container uk-section"
          style={{ paddingTop: 110 }}
        >
          <div className="uk-section-header">{t("home.vision.title")}</div>
          <div className="uk-section-subheader">
            {t("home.vision.subtitle")}
          </div>
          <div
            data-uk-scrollspy={
              dir === "ltr"
                ? "target: > div; cls: uk-animation-slide-left-medium"
                : "target: > div; cls: uk-animation-slide-right-medium"
            }
          >
            <div
              className="uk-card uk-card-small uk-margin uk-text-center"
              style={{ marginTop: 50 }}
            >
              <div className="uk-card-body uk-text-middle">
                <div className="uk-text-statement">
                  <Icon
                    className={
                      dir === "ltr"
                        ? "uk-margin-small-right"
                        : "uk-margin-small-left"
                    }
                    path={mdiFormatQuoteOpen}
                    color="#666"
                    size={0.8}
                    style={{ verticalAlign: "super" }}
                  />
                  {t("home.vision.about")}
                  <Icon
                    className={
                      dir === "ltr"
                        ? "uk-margin-small-left"
                        : "uk-margin-small-right"
                    }
                    path={mdiFormatQuoteClose}
                    color="#666"
                    size={0.8}
                    style={{ verticalAlign: "super" }}
                  />
                </div>
                <div className="uk-text-affirm">
                  {t("home.vision.platform")}
                </div>
                <div
                  className="uk-grid uk-grid-large uk-child-width-1-3@s"
                  data-uk-grid
                >
                  <div>
                    <div className="uk-card uk-card-iw">
                      <div className="uk-card-madia-top">
                        <img
                          src="assets/images/_identify-500x500.jpg"
                          alt="Identify"
                        />
                      </div>
                      <div className="uk-card-body">
                        <div className="uk-card-header">
                          <Link className="uk-link-reset" to="/iwin-masterfile">
                            {t("app.nav.identify")}
                          </Link>
                          <br />
                        </div>
                        <div className="uk-text-300">
                          {t("home.identify.subtitle")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="uk-card uk-card-iw">
                      <div className="uk-card-madia-top">
                        <img
                          src="assets/images/_logistics-500x500.jpg"
                          alt="Trace"
                        />
                      </div>
                      <div className="uk-card-body">
                        <div className="uk-card-header">
                          <Link className="uk-link-reset" to="/wine-trace">
                            {t("app.nav.trace")}
                          </Link>
                          <br />
                        </div>
                        <div className="uk-text-300">
                          {t("home.trace.subtitle")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="uk-card uk-card-iw">
                      <div className="uk-card-madia-top">
                        <img
                          src="assets/images/_trace-500x500.jpg"
                          alt="Authenticate"
                        />
                      </div>
                      <div className="uk-card-body">
                        <div className="uk-card-header">
                          {t("app.nav.authenticate")}
                          <br />
                        </div>
                        <div className="uk-text-300">
                          {t("home.authenticate.subtitle")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="uk-section-default uk-inline"
          id="industry"
          style={{ width: "100%" }}
        >
          <div
            className="uk-section uk-background-cover uk-light"
            style={{
              backgroundImage:
                "url('/assets/images/_peertopeer-1920x1080.jpg')",
            }}
          >
            <div
              className="uk-overlay-primary uk-position-cover"
              style={{ background: "rgba(20,40,64,0.8)" }}
            />
            <div className="uk-container" style={{ paddingTop: 110 }}>
              <div className="uk-section-header">
                {t("home.industry.title")}
              </div>
              <div className="uk-section-subheader uk-margin-large-bottom">
                {t("home.industry.subtitle")}
              </div>
              <div className="uk-card">
                <div className="uk-card-body">
                  <div className="uk-child-width-1-3@s uk-margin" data-uk-grid>
                    <div className="">
                      <p className="uk-text-lead uk-text-center">
                        {t("home.industry.p1")}
                      </p>
                    </div>
                    <div className="">
                      <p className="uk-text-lead uk-text-center">
                        {t("home.industry.p2")}
                      </p>
                    </div>
                    <div className="">
                      <p className="uk-text-lead uk-text-center">
                        {t("home.industry.p3")}
                      </p>
                    </div>
                  </div>
                  <div className="uk-text-center uk-margin-large-top">
                    <div className="uk-text-statement uk-light">
                      <Icon
                        className={
                          dir === "ltr"
                            ? "uk-margin-small-right"
                            : "uk-margin-small-left"
                        }
                        path={mdiFormatQuoteOpen}
                        color="#999"
                        size={0.8}
                        style={{ verticalAlign: "super" }}
                      />
                      {t("home.industry.p4")}
                      <Icon
                        className={
                          dir === "ltr"
                            ? "uk-margin-small-left"
                            : "uk-margin-small-right"
                        }
                        path={mdiFormatQuoteClose}
                        color="#999"
                        size={0.8}
                        style={{ verticalAlign: "super" }}
                      />
                    </div>
                    <div className="uk-text-affirm uk-light">
                      {t("home.industry.solution")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="news"
          className="uk-container uk-section"
          style={{ paddingTop: 110 }}
        >
          <div className="uk-section-header">{t("home.news.title")}</div>
          <div className="uk-section-subheader uk-margin-medium-bottom">
            {t("home.news.subtitle")}
          </div>
          <div style={{ width: "90%", marginLeft: "5%" }}>
            <div className="uk-grid uk-grid-small" data-uk-grid="masonry: true">
              {news &&
                news.length > 0 &&
                news.map((item) => (
                  <div className="uk-width-1-2@m" key={item.newsId}>
                    <div
                      className="uk-card uk-card-body uk-card-news uk-visible-toggle"
                      tabIndex="-1"
                    >
                      <div className="uk-news-header">
                        <div className="uk-news-category">
                          {t(`lbl.${item.category}`)}
                        </div>
                        <div className="uk-news-date">
                          {format(new Date(item.date), "PPP", {
                            locale: localeResource.default,
                          })}
                        </div>
                        <div className="uk-float-right">
                          <div className="uk-icon-button uk-icon-link uk-hidden-notouch pulse">
                            <Icon
                              path={mdiPlus}
                              style={{ marginLeft: 2, marginTop: -4 }}
                              size={0.8}
                              color="#FFF"
                              onClick={() =>
                                // route to NewsItem handler and UI
                                router.history.push(
                                  `/news/${item.newsId}/${slug(
                                    item.title.toLowerCase()
                                  )}`,
                                  {
                                    item,
                                  }
                                )
                              }
                            />
                          </div>
                          <div className="uk-icon-button uk-icon-link uk-hidden-touch uk-invisible-hover pulse">
                            <Icon
                              path={mdiPlus}
                              style={{ marginLeft: 2, marginTop: -4 }}
                              size={0.8}
                              color="#FFF"
                              onClick={() =>
                                // route to NewsItem handler and UI
                                router.history.push(
                                  `/news/${item.newsId}/${slug(
                                    item.title.toLowerCase()
                                  )}`,
                                  {
                                    item,
                                  }
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="uk-news-title">{item.title}</div>
                        <div className="uk-news-author">
                          {t("lbl.by_author", { author: item.author })}
                          <div className="uk-news-time-read">
                            {readingTime(item.content)}
                          </div>
                        </div>
                      </div>
                      <div className="uk-news-image">
                        <div className="uk-news-image-cropped">
                          <img src={item.imageUrl} alt="" />
                        </div>
                        <div className="uk-news-image-legend">
                          {item.imageLegend}
                        </div>
                      </div>
                      <div className="uk-news-cropped">
                        {item.content
                          .replaceAll("/n", "\n")
                          .split("\n")
                          .map((str, i) => (
                            <p key={i}>{str}</p>
                          ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div
          id="team"
          className="uk-container uk-section"
          style={{ paddingTop: 110 }}
        >
          <div className="uk-section-header">{t("home.team.title")}</div>
          <div className="uk-section-subheader uk-margin-medium-bottom">
            {t("home.team.subtitle")}
          </div>
          <div className="uk-text-center uk-margin-large">
            <Link
              to={"#"}
              className="uk-button uk-button-default uk-button-large"
            >
              {t("lbl.get_in_touch")}
            </Link>
          </div>
        </div>
      </LandingLayout>
      {!cookies.accepts_policy && (
        <CookieConsent onAccept={handleAccept} onDecline={handleDecline} />
      )}
    </>
  );
};

export default LandingPage;
