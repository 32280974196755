import { MODAL_OPEN, MODAL_CLOSE } from "../types";

const INITIAL_STATE = null;

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Open the modal
    case MODAL_OPEN:
      const { modalType, modalProps } = action.payload;
      return { modalType, modalProps };

    // Close the modal
    case MODAL_CLOSE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
