export const firebaseProd = {
  apiKey: "AIzaSyBGXIyjIjfnHMGhQh-f5CRSPZL3qLbUXzU",
  authDomain: "inwine-3f733.firebaseapp.com",
  databaseURL: "https://inwine-3f733.firebaseio.com",
  projectId: "inwine-3f733",
  storageBucket: "inwine-3f733.appspot.com",
  messagingSenderId: "515872849927",
  appId: "1:515872849927:web:7a4f8986caaf3364",
  measurementId: "G-DR6FQP22CZ",
};
export const firebaseDev = {
  apiKey: "AIzaSyBGXIyjIjfnHMGhQh-f5CRSPZL3qLbUXzU",
  authDomain: "inwine-3f733.firebaseapp.com",
  databaseURL: "https://inwine-3f733.firebaseio.com",
  projectId: "inwine-3f733",
  storageBucket: "inwine-3f733.appspot.com",
  messagingSenderId: "515872849927",
  appId: "1:515872849927:web:7a4f8986caaf3364",
  measurementId: "G-DR6FQP22CZ",
};
export const firebase =
  process.env.NODE_ENV === "production" ? firebaseProd : firebaseDev;

const prodReCaptcha = {
  siteKey: "6Lduo2IUAAAAAP5ETqjQDpCJbNmqbuYUkczYT0eN",
  secretKey: "6Lduo2IUAAAAAFD_daNpPvZkJ5NH6WFmgdTQ8gNk",
};
const devReCaptcha = {
  siteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  secretKey: "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe",
};
export const reCaptchaConfig =
  process.env.NODE_ENV === "production" ? prodReCaptcha : devReCaptcha;

export const facebookConfig = {
  appId: "1826054454327842",
  appSecret: "e974be52b6629f8dbdac2bcfe406eddd",
};

export const twitterConfig = {
  ownerId: "731796724629442560",
  consumerKey: "1oQCrCQl3eCKUk4aU209WShi3",
  consumerSecret: "WqbpIF4raHBfayQ1ZYTrl6RlsWcWsmbwrU52KJQv6upeKcJ4Ka",
};

export const sendGridConfig = {
  apiKey:
    "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",
};

export const algolia = {
  applicationId: "5XOWO54LH6",
  apiKey: "7e0267f760c0235db9903eab90758e1f",
  adminApiKey: "7379fbd873f109900494332dbde6c9ce",
  monitoringApiKey: "954d0718f0aa43fc121564e5029ba195",
};

export const mapBox = {
  accessToken:
    "pk.eyJ1IjoibmNoZXJvbmV0IiwiYSI6ImNrYmY3Y3FzejBzcWozMHBteThpbGRiNDcifQ.Ca9hVziapOuFjtr1V-AOUQ",
};
