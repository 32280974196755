import React, { useEffect, useState } from "react";
// i18n
import { Trans, useTranslation } from "react-i18next";
import { withI18nParam } from "../../../util/helpers";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { USER_EMAIL_RESET } from "../../../redux/types";
// mdi Icon
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
// hooks
import { useAuth } from "../../../hooks/use-auth";
import { useRouter } from "../../../hooks/use-router";
// Internal components
import Head from "../../../components/layout/Head";
import EmailForm from "../../../components/forms/auth/Email";

const Signin = () => {
  // Get auth state and re-render anytime it changes
  const auth = useAuth();
  // Get the router object
  const router = useRouter();
  // i18n
  const { t } = useTranslation();
  const dir = t("app.lang.dir");
  const langCode = t("app.lang.code");
  // Get redux ui object
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  const [email, setEmail] = useState(null);

  // component did mount
  useEffect(() => {
    console.log(
      "Signin > useEffect > emailForSignin:",
      localStorage.getItem("emailForSignin")
    );
    setEmail(localStorage.getItem("emailForSignin"));
  }, []);

  // re-route upon auth update
  useEffect(() => {
    console.log("Signin > useEffect > auth:", auth);
    if (auth && auth.user) {
      console.log("Signin > useEffect > auth.user:", auth.user);
      const { isNewUser } = auth.user;
      if (isNewUser && isNewUser === false) router.push("/dashboard");
      else router.replace("/signup");
    }
  }, [auth, auth.user, router]);

  // Corporate signIn initiated
  const handleSignin = (user) => {
    console.log("Signin > handleSignin > user:", user);
    auth.signin(user);
    setEmail(user.email);
  };
  const resetEmail = () => {
    setEmail(null);
    dispatch({ type: USER_EMAIL_RESET });
    localStorage.removeItem("emailForSignin");
  };

  const termsUrl = (
    <span key="0">
      <br className="uk-hidden@m" />
      <span
        className="uk-text-normal uk-text-uppercase"
        onClick={() => router.push("/terms")}
        style={{ cursor: "pointer" }}
      >
        {t("app.legal.terms")}
      </span>
    </span>
  );
  const privacyUrl = (
    <span
      key="1"
      className="uk-text-normal uk-text-uppercase"
      onClick={() => router.push("/privacy-policy")}
      style={{ cursor: "pointer" }}
    >
      {t("app.legal.privacy_policy")}
    </span>
  );

  return (
    <>
      <Head
        title={t("signin.header", { app: t("app.name") })}
        type={t("app.type")}
        image="/assets/images/brand-74x29.png"
        description={t("app.description")}
      />
      {auth.user !== null && (
        <div className="uk-container">
          <div className="uk-section uk-section-branded">
            {!auth.user && !email && (
              <>
                <div
                  className="uk-position-absolute"
                  style={
                    dir === "ltr"
                      ? {
                          left: 50,
                          top: 50,
                          transform: "translate(0, -100%)",
                        }
                      : {
                          right: 50,
                          top: 50,
                          transform: "translate(0, -100%)",
                        }
                  }
                >
                  <a href={"#"} className="uk-icon-button">
                    <Icon
                      path={dir === "ltr" ? mdiChevronLeft : mdiChevronRight}
                      size={2}
                      color="#999999"
                      onClick={() => router.history.goBack()}
                    />
                  </a>
                </div>
                <div className="uk-flex uk-flex-center uk-flex-1">
                  <div className="uk-card uk-card-transparent">
                    <div className="uk-card-header uk-text-center">
                      <img
                        src={`/assets/logo_inwine_${langCode}.png`}
                        alt="inWine"
                        style={{ width: "50%", maxWidth: 200 }}
                      />
                    </div>
                    <div className="uk-card-body uk-text-center">
                      <div className="uk-h3 uk-text-300 uk-text-uppercase">
                        <Trans
                          i18nKey="signin.title"
                          values={{ app: t("app.name") }}
                        />
                      </div>
                      <EmailForm
                        onSubmit={handleSignin}
                        isLoading={ui.isLoading}
                      />
                      <div className="uk-text-small uk-text-danger uk-width-5-6">
                        {ui.error && ui.error.message}
                      </div>
                      <div className="uk-align-center uk-width-5-6">
                        <div className="uk-text-small uk-align-center">
                          {withI18nParam(
                            "signin.disclaimer",
                            [termsUrl, privacyUrl],
                            t
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!auth.user && email && (
              <div className="uk-flex uk-flex-center uk-flex-1">
                <div className="uk-card uk-card-transparent">
                  <div className="uk-card-header uk-text-center">
                    <img
                      src={`/assets/logo_inwine_${langCode}.png`}
                      alt="inWine"
                      style={{ width: "50%", maxWidth: 200 }}
                    />
                  </div>
                  <div className="uk-card-body">
                    <div className="uk-h3 uk-text-uppercase uk-text-center uk-margin">
                      {t("signin.check_email.title")}
                    </div>
                    <div className="uk-text-normal">
                      <Trans
                        i18nKey="signin.check_email.p1"
                        values={{ email }}
                      />
                    </div>
                    <div className="uk-text-normal">
                      {t("signin.check_email.p2")}
                    </div>
                  </div>
                  <div className="uk-card-footer">
                    <div
                      className="uk-text-primary uk-margin-small-top"
                      style={{ cursor: "pointer" }}
                      onClick={() => resetEmail()}
                    >
                      {t("signin.check_email.did_not_receive")}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Signin;
