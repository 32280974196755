import { useEffect, useState } from "react";
import { db, firebase } from "../util/firebase";

import { useDispatch } from "react-redux";
import { ERRORS_CLEAR, ERRORS_SET, UI_LOADING } from "../redux/types";

export function useIwin(iwin) {
  const dispatch = useDispatch();

  const [label, setLabel] = useState(null);
  const [root, setRoot] = useState(iwin.substr(0, 7) + "00");

  useEffect(() => {
    const unsubscribe = db
      .collection("iwin")
      .doc(root)
      .onSnapshot(doc => {
        setLabel(doc.data());
      });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (label && !label.title) {
      console.log("useIwin > useEffect[label] > No title:", label);
      updateTitle(label);
    } else if (label && !label.property) {
      console.log("useIwin > useEffect[label] > No property:", label);
      updateProperty(label);
    } else if (label && !label.labelImageURL) {
      console.log("useIwin > useEffect[label] > No label image:", label);
      updateLabelImage(label);
    } else if (label && label.createdAt) {
      console.log("useIwin > useEffect[label] > createdAt:");
    }
  }, [label]);

  const updateProperty = label => {
    dispatch({ type: UI_LOADING });
    db.collection("domaines")
      .where("domaineId", "==", label.domaineId)
      .get()
      .then(properties => {
        if (!properties.empty) {
          let propertie = properties.docs[0].data();
          let property = {
            id: properties.docs[0].id,
            region: propertie.region || "",
            appelation: propertie.appelation || "",
            appelationId: propertie.appelationId || "",
            domaineId: propertie.domaineId || "",
            domaineIso: propertie.domaineIso || "",
            domaineIwin: propertie.domaineIwin || "",
            address: {
              name: propertie.chateau || label.displayName || "",
              line1: propertie.address1 || "",
              line2: propertie.address2 || "",
              zip: propertie.postalCode || "",
              city: propertie.city || "",
              country: propertie.country || ""
            },
            map: {
              lat: "",
              lng: "",
              plusCode: "",
              url: propertie.map || ""
            },
            contact: {
              mail: propertie.email || "",
              facebook: propertie.facebook || "",
              tel: propertie.phone || "",
              fax: propertie.fax || "",
              website: propertie.domaineURL || ""
            }
          };
          return db
            .collection("iwin")
            .doc(root)
            .update({
              property,
              domaineId: firebase.firestore.FieldValue.delete()
            });
        }
      })
      .then(() => {
        console.log("useIwin > updateProperty > Property was updated");
        dispatch({ type: ERRORS_CLEAR });
      })
      .catch(err => {
        console.error("useIwin > updateProperty > Error:", err);
        dispatch({ type: ERRORS_SET, payload: err });
      });
  };

  const updateLabelImage = label => {
    dispatch({ type: UI_LOADING });
    // Get the image
    db.collection("iwin")
      .doc(root)
      .collection("label_images")
      .doc(root)
      .get()
      .then(doc => {
        let labelImageURL = doc.data().labelImageURL;
        return db
          .collection("iwin")
          .doc(root)
          .update({ labelImageURL });
      })
      .then(() => {
        console.log("useIwin > updateLabelImage > Image URL was updated");
        dispatch({ type: ERRORS_CLEAR });
      })
      .catch(err => {
        console.error("useIwin > updateLabelImage > Error:", err);
        dispatch({ type: ERRORS_SET, payload: err });
      });
  };

  const updateTitle = label => {
    dispatch({ type: UI_LOADING });
    db.collection("iwin")
      .doc(root)
      .update({ title: label.displayName + " - " + label.originDisplayName })
      .then(() => {
        console.log("useIwin > updateTitle > Title was updated");
        dispatch({ type: ERRORS_CLEAR });
      })
      .catch(err => {
        console.error("useIwin > updateTitle > Error:", err);
        dispatch({ type: ERRORS_SET, payload: err });
      });
  };

  const getLabel = async iwin => {
    // Get the root IWIN infos
    dispatch({ type: UI_LOADING });
    let label, retLabel, labelImages, otherWines;
    try {
      label = await db
        .collection("iwin")
        .doc(`${root}`)
        .get();
      // Build label object
      retLabel = label.data();
      // Add label title
      retLabel.title =
        retLabel.displayName + " - " + retLabel.originDisplayName;

      // Data for other children wines
      labelImages = await db
        .collection("iwin")
        .doc(root)
        .collection("label_images")
        .get();
      if (!labelImages.empty) {
        let retLabelImages = [];
        labelImages.forEach(doc =>
          retLabelImages.push({
            id: doc.id,
            labelImageURL: doc.data().labelImageURL
          })
        );
        retLabel.labelImages = retLabelImages;
      }

      // Data for other children wines
      otherWines = await db
        .collection("iwin")
        .where("linkIwin", "==", root)
        .get();
      if (!otherWines.empty) {
        let retOtherWines = [];
        otherWines.forEach(wine =>
          retOtherWines.push({ iwin: wine.id, ...wine.data() })
        );
        retLabel.wines = retOtherWines;
      }

      if (root !== iwin) {
        // IWIN has a vintage, get data
        let retLabelVintage;
        let labelVintage = await db
          .collection("iwin_vintage")
          .doc(`${iwin}`)
          .get();
        retLabelVintage = labelVintage.data();
        console.log("useLabel > labelVintage:", retLabelVintage);
        // Add label title
        retLabel.title =
          retLabelVintage.displayName +
          " " +
          retLabelVintage.vintage +
          " - " +
          retLabelVintage.originDisplayName;
        // Add label scores
        retLabel.scores = retLabelVintage.scores;
        // Add label varietals
        retLabel.varietals = retLabelVintage.varietals;
        // Add label vintage
        retLabel.vintage = retLabelVintage.vintage;
      }

      // Data for property and map
      if (retLabel.domaineId) {
        let property = await db
          .collection("domaines")
          .where("domaineId", "==", retLabel.domaineId)
          .get();
        if (!property.empty) retLabel.property = property.docs[0].data();
      }

      // Data for parent wine
      if (retLabel.linkIwin) {
        let wines = await db
          .collection("iwin")
          .doc(`${retLabel.linkIwin}`)
          .get();
        retLabel.parent = wines.data();
      }

      // Update label object
      setLabel(retLabel);
      // Clear all errors
      dispatch({ type: ERRORS_CLEAR });
    } catch (err) {
      dispatch({
        type: ERRORS_SET,
        payload: { message: err.message }
      });
    }
  };

  return {
    iwin,
    root,
    label
  };
}
