import React, { useEffect } from "react";
// i18n
import { useTranslation } from "react-i18next";
// redux
import { useSelector } from "react-redux";
// hooks
import { useAuth } from "../../../hooks/use-auth";
import { useRouter } from "../../../hooks/use-router";
// internal components
import Loading from "../../../components/layout/Loading";
import Head from "../../../components/layout/Head";

const Authenticate = () => {
  const [t] = useTranslation();
  const auth = useAuth();
  const emailVerified = useSelector((state) => state.user.email_verified);
  const ui = useSelector((state) => state.ui);
  const router = useRouter();

  // component has mounted
  useEffect(() => {
    // Get the link
    const link = window.location.href;
    // check link and authenticate
    auth.isValidSignInLink(link);
  }, []);

  const handleClose = () => {
    window.close();
  };

  return (
    <>
      <Head
        title={t("signin.header", { app: t("app.name") })}
        type={t("app.type")}
        image="/assets/bordeaux_inwine.jpg"
        description={t("app.description")}
      />
      <div className="uk-section uk-section-default uk-text-center">
        <div className="uk-container uk-width-2-3@s uk-width-1-2@m uk-position-relative">
          {emailVerified || auth.user ? (
            <>
              <div className="uk-h2 uk-text-300 uk-text-uppercase">
                {t("signin.verify.success_title")}
              </div>
              <p>{t("signin.verify.success_subtitle")}</p>
              <button
                className="uk-button uk-button-default"
                onClick={() => handleClose()}
              >
                {t("lbl.close")}
              </button>
            </>
          ) : (
            <>
              <div className="uk-h2 uk-text-300 uk-text-uppercase">
                {t("signin.verify.header")}
              </div>
              {ui.isLoading && <Loading />}
            </>
          )}
          {ui && ui.error && !auth.user && (
            <div className="uk-form-warning">
              {ui.error && <p>{ui.error.message}</p>}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Authenticate;
