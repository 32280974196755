import React, { useEffect, useState } from "react";

// i18n
import { Trans, useTranslation } from "react-i18next";

// Redux
import { useSelector } from "react-redux";

// Hooks
import { useAuth } from "../../../hooks/use-auth";
import { useRouter } from "../../../hooks/use-router";

// Internal components
import Head from "../../../components/layout/Head";
import PasswordForm from "../../../components/forms/auth/Password";

const ResetPassword = ({ actionCode, apiKey }) => {
  // Get auth state and re-render anytime it changes
  const auth = useAuth();
  // Get the router object
  const router = useRouter();
  // Get the i18next object
  const { t } = useTranslation();
  // Get redux ui object
  const ui = useSelector(state => state.ui);
  // State
  const [email, setEmail] = useState(null);
  const [error, setError] = useState("");
  const [valid, setValid] = useState(null);
  const [check, setCheck] = useState(false);

  // Corporate signIn initiated
  const handlePassword = password => {
    console.log(password);
    auth.confirmPasswordReset(actionCode, password.password);
  };

  useEffect(() => {
    auth
      .verifyPasswordResetCode(actionCode)
      .then(email => {
        setEmail(email);
        setValid(true);
        setCheck(true);
      })
      .catch(err => {
        setError(err.message);
        setValid(false);
        setCheck(true);
      });
  }, [auth]);

  return (
    <>
      <Head
        title={t("forgot_password.header", { app: t("app.name") })}
        type={t("app.type")}
        image="/assets/images/brand-74x29.png"
        description={t("app.description")}
      />
      <div className="uk-container uk-container-small">
        <div className="uk-section uk-section-small">
          <div className="uk-h3 uk-text-uppercase uk-text-left">
            <Trans
              i18nKey="reset_password.title"
              values={{ app: t("app.name") }}
            />
          </div>
          <div className="uk-text-normal uk-margin-small-top uk-margin-remove-bottom">
            {t("reset_password.help")}
          </div>
          <div className="uk-margin">
            <PasswordForm onSubmit={handlePassword} isLoading={ui.isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
