import React from "react";

import { Link } from "react-router-dom";

import SpanFloat from "../../../../components/molecules/SpanFloat";

const AccordionListItem = ({ children, description, header, info, dir }) => {
  return (
    <ul className="uk-accordion uk-position-relative" data-uk-accordion>
      <li>
        <Link className="uk-accordion-title" to={"#"}>
          <div
            className="uk-h6 uk-width-2-3 uk-width-5-6@s uk-margin"
            style={{ padding: "0 5px" }}
          >
            {header}
            <p className="uk-text-meta uk-margin-small">{description}</p>
          </div>
          <div
            className={`uk-width-1-3 uk-width-1-6@s uk-text-meta ${
              dir === "ltr" ? "uk-position-top-right" : "uk-position-top-left"
            }`}
            style={{ margin: "25px auto" }}
          >
            <SpanFloat float="right">{info}</SpanFloat>
          </div>
        </Link>
        <div className="uk-accordion-content uk-margin-remove-top" hidden>
          {children}
        </div>
      </li>
      <hr className="uk-margin-small" />
    </ul>
  );
};

export default AccordionListItem;
