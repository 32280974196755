// Redux
import { combineReducers } from "redux";

// reducers
// import activities from "./activities_reducer";
// import auth from "./auth_reducer";
// import data from "./data_reducer";
import { firebaseReducer as firebase } from "react-redux-firebase";
import { firestoreReducer as firestore } from "redux-firestore";
import { reducer as form } from "redux-form";
import modal from "./modal_reducer";
import news from "./news_reducer";
import { reducer as toastr } from "react-redux-toastr";
import ui from "./ui_reducer";
import user from "./user_reducer";

export default combineReducers({
  firebase,
  firestore,
  form,
  modal,
  news,
  toastr,
  ui,
  user,
});
