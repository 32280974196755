import React from "react";

// i18n
import { useTranslation } from "react-i18next";

import LabelDisplayInput from "./LabelDisplayInput";

const SelectInput = ({
  className,
  disabled,
  help,
  icon,
  input,
  label,
  mandatory,
  meta: { touched, error },
  multiple,
  optional,
  options,
  placeholder,
  style,
  type,
  validation,
  width
}) => {
  // i18n
  const [t] = useTranslation();

  return (
    <div className={width ? `uk-width-1-1 ${width}@s` : "uk-width-1-1"}>
      {label && (
        <LabelDisplayInput
          dir={t("app.lang.dir")}
          label={label}
          mandatory={mandatory}
          optional={optional}
        />
      )}
      <div className="uk-form-controls">
        <select
          {...input}
          className={
            touched && !!error ? `${className} uk-form-danger` : `${className}`
          }
          disabled={disabled}
          multiple={multiple}
          style={style}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options &&
            options.map((option, i) => (
              <option key={i} value={option.key}>
                {option.value}
              </option>
            ))}
        </select>
        <div className="uk-text-small uk-margin-small-bottom">
          {help && (
            <>
              <span className="uk-text-meta uk-text-left">{help}</span>
              <br />
            </>
          )}
          {validation === false
            ? ""
            : touched &&
              error && (
                <span className="uk-text-danger">
                  {error.substring(0, 4) === "err." ? t(error) : error}
                </span>
              )}
        </div>
      </div>
    </div>
  );
};

export default SelectInput;
