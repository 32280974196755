import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

// External components
import { useDropzone } from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.min.css";
// i18n
import { useTranslation } from "react-i18next";
// Redux
import { useSelector } from "react-redux";
// hooks
import { useAuth } from "../../../hooks/use-auth";
// Internal components
import IconPosition from "../../molecules/IconPosition";
import SpanFloat from "../../molecules/SpanFloat";
import Loading from "../../layout/Loading";
// mdi Icon
import Icon from "@mdi/react";
import { mdiCloudUploadOutline } from "@mdi/js";

const ProfilePhoto = ({ closeModal, header, isMobile, user }) => {
  const [files, setFiles] = useState([]);
  const [filename, setFilename] = useState("");
  //const [cropResult, setCropResult] = useState(null);
  const [image, setImage] = useState({});
  const [photoUploaded, setPhotoUploaded] = useState(false);
  const [filteredPhotos, setFilteredPhotos] = useState([]);

  const [t] = useTranslation();
  const isLoading = useSelector((state) => state.ui.isLoading);
  const auth = useAuth();
  const cropper = useRef(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setFilename(acceptedFiles[0].name);
      setPhotoUploaded(false);
    },
  });

  const uploadImage = () => {
    auth.uploadProfilePhoto(image, filename);
    cancelCrop();
  };

  const deleteImage = (photo) => {
    auth.deleteProfilePhoto(photo);
  };

  const setPhoto = (photo) => {
    auth.setProfilePhoto(photo);
  };

  const cancelCrop = () => {
    setFiles([]);
    setImage({});
    setPhotoUploaded(true);
  };

  const cropImage = () => {
    if (typeof cropper.current.getCroppedCanvas() === "undefined") {
      return;
    }

    cropper.current.getCroppedCanvas().toBlob(
      (blob) => {
        //let imageUrl = URL.createObjectURL(blob);
        // console.log("imageUrl:", imageUrl);
        //setCropResult(imageUrl);
        //console.log(cropResult)
        // console.log("Blob:", blob);
        setImage(blob);
      },
      "image/jpeg",
      0.95
    );
  };

  useEffect(() => {
    setFilteredPhotos(
      user.photos.filter((photo) => {
        return photo.photoUrl !== user.photoUrl;
      })
    );
  }, [user.photos, user.photoUrl]);

  useEffect(() => {
    files && files.length > 0 && setFilename(files[0].name);
    setPhotoUploaded(false);

    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <>
      {isLoading && <Loading />}
      <ul className="uk-tab" uk-tab="connect: #uk-switcher-photo">
        {!files[0] && (
          <li>
            <Link to={"#"}>{t("lbl.select")}</Link>
          </li>
        )}
        {files[0] && (
          <li>
            <Link to={"#"}>{t("lbl.crop")}</Link>
          </li>
        )}
        <li className={photoUploaded ? "uk-active" : ""}>
          <Link to={"#"}>{t("profile.photo.tab.all")}</Link>
        </li>
      </ul>
      <ul id="uk-switcher-photo" className="uk-switcher uk-margin">
        {!files[0] && (
          <li>
            <div className="uk-section uk-section-default uk-section-small uk-padding-remove-vertical">
              <div
                {...getRootProps()}
                className="uk-placeholder uk-text-center uk-padding-large uk-padding-remove-horizontal"
                acceptstyle={{
                  color: "#53845d",
                  backgroundColor: "#f1f1f1",
                  borderColor: "#d2d2d2",
                }}
                rejectstyle={{
                  color: "#b52626",
                  backgroundColor: "#f1f1f1",
                  borderColor: "#d2d2d2",
                }}
                multiple={false}
              >
                <input {...getInputProps()} />
                <div>
                  <div className="uk-margin-small">
                    <span className="uk-icon">
                      <Icon path={mdiCloudUploadOutline} size={3} />
                    </span>
                  </div>
                  <div
                    className="uk-h3 uk-text-300 uk-margin-remove-top"
                    style={{ color: "#7c5f62" }}
                  >
                    {t("profile.photo.drag")}
                    <div
                      className="uk-margin-small uk-text-meta"
                      style={{ color: "#7c5f62" }}
                    >
                      {t("lbl.or")}
                    </div>
                    <button
                      className="uk-button uk-button-default uk-button-small"
                      style={{
                        textTransform: "none",
                        color: "#7c5f62",
                      }}
                    >
                      {t("profile.photo.select_computer")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
        {files[0] && (
          <li>
            <div className="uk-text-small">
              {t("profile.photo.selected", { photo: filename })}
            </div>
            <div className="uk-text-meta uk-margin-medium-bottom">
              {t("profile.photo.selected_helper")}
            </div>
            <Cropper
              style={{ maxHeight: 330, width: "100%" }}
              ref={cropper}
              src={files[0].preview}
              aspectRatio={1}
              viewMode={0}
              dragMode="move"
              guides={false}
              scalable={true}
              cropBoxMovable={true}
              cropBoxResizable={true}
              crop={() => cropImage()}
            />
            <hr className="uk-margin" />
            <div className="uk-width-1-1">
              <SpanFloat float="left">
                <button
                  className="uk-button uk-button-primary uk-button-small"
                  onClick={() => uploadImage()}
                >
                  {t("lbl.save")}
                </button>
              </SpanFloat>
              <SpanFloat float="right">
                <button
                  className="uk-button uk-button-default uk-button-small"
                  onClick={() => cancelCrop()}
                >
                  {t("lbl.cancel")}
                </button>
              </SpanFloat>
            </div>
          </li>
        )}
        <li className={photoUploaded ? "uk-active" : ""}>
          {user && user.photos && user.photos.length > 0 ? (
            <>
              <div className="uk-text-meta uk-margin-medium-bottom">
                {t("profile.photo.helper")}
              </div>
              <div
                className="uk-grid uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s"
                data-uk-grid
              >
                {user && user.photoUrl && (
                  <div className="uk-position-relative">
                    <img src={user.photoUrl} alt={t("profile.photo.active")} />
                    <span className="uk-text-primary">
                      <IconPosition
                        icon={"check"}
                        vertical={"top"}
                        tooltip={t("profile.photo.active")}
                      />
                    </span>
                  </div>
                )}
                {user &&
                  user.photos &&
                  filteredPhotos.map((photo, i) => (
                    <div
                      key={i}
                      data-photo-id={photo.photoId}
                      className="uk-position-relative uk-visible-toggle"
                      uk-toggle={`target: #photo_${photo.photoId}; mode: hover; cls: uk-box-shadow-large`}
                    >
                      <div
                        id={`photo_${photo.photoId}`}
                        className="uk-card uk-card-body uk-padding-remove"
                      >
                        <img src={photo.photoUrl} alt={photo.photoId} />
                        <div
                          className="uk-text-success uk-hidden-hover"
                          onClick={() => setPhoto(photo)}
                          style={{ cursor: "pointer" }}
                        >
                          <IconPosition
                            icon={"check"}
                            vertical={"top"}
                            tooltip={t("profile.photo.set")}
                          />
                        </div>
                        <div
                          className="uk-text-danger uk-hidden-hover"
                          onClick={() => deleteImage(photo)}
                          style={{ cursor: "pointer" }}
                        >
                          <IconPosition
                            icon={"trash"}
                            vertical={"bottom"}
                            tooltip={t("profile.photo.remove")}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <div className="uk-section uk-section-default uk-section-small uk-text-center">
              <div
                className="uk-margin-small"
                uk-icon="icon: image; ratio: 3"
              />
              <div className="uk-h3 uk-text-300">{t("profile.photo.none")}</div>
            </div>
          )}
        </li>
      </ul>
    </>
  );
};

export default ProfilePhoto;
