import { differenceInMonths } from "date-fns";
import capitalize from "lodash/capitalize";
import toUpper from "lodash/toUpper";
import removeDiacritics from "./diacritics";
import { libellesCodesNaf } from "../fixtures/codes_naf";
import { libellesCategoriesJuridiques } from "../fixtures/categories_juridiques";

const ageConvert = (dateOfBirth) => {
  let months = differenceInMonths(new Date(), new Date(dateOfBirth));
  let years = Math.floor(months / 12);
  months = months % 12;
  return { years, months };
};

const toTitleCase = (str) => {
  if (typeof str === "undefined") return "";
  var i, j, lowers, uppers;
  str = str.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "S/O",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (
      txt
    ) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = [
    "Ceo",
    "Coo",
    "Cto",
    "Cio",
    "Cfo",
    "Cmo",
    "Md",
    "Id",
    "Tv",
    "M.a",
    "B.o",
  ];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(
      new RegExp("\\b" + uppers[i] + "\\b", "g"),
      uppers[i].toUpperCase()
    );

  return str;
};

// Converts number to Indian number
const inNumber = (number) => {
  let x;
  if (typeof number === "number") {
    x = number.toString();
    x = Math.floor(x);
    x = x.toString();
  } else if (typeof number === "string") {
    x = number;
  } else return "";
  let lastThree = x.substring(x.length - 3);
  let otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
};

// Converts locale strings with multiple placeholders
const withI18nParam = (i18nKey, components, t) => {
  let string = t(i18nKey);
  let results = [];
  components.forEach((component, idx) => {
    let splitedStrings = string.split("{" + idx + "}");
    results.push(splitedStrings[0]);
    results.push(component);
    string = splitedStrings[1];
  });
  results.push(string);
  return results;
};

const concatNames = (firstName, lastName) => {
  const last = toUpper(lastName);
  const first = capitalize(firstName);

  if (first !== "" && last !== "") return `${first} ${last}`;
  else return last;
};

function removeExtraChars(str) {
  if (!str) return;

  return str
    .replace(/\*/g, " ")
    .replace(/\//g, " ")
    .replace(/\s{2,}/g, " ")
    .replace(/\s$/, "");
}

const placeHolderIfEmpty = (str) => {
  return str && str !== "null" ? str : "Non renseigné";
};

const libelleFromCodeNaf = (codeNaf) => {
  const formattedNaf = codeNaf.replace(/[.-]/g, "");
  return libellesCodesNaf[formattedNaf];
};
const libelleFromCategoriesJuridiques = (categorie) => {
  return libellesCategoriesJuridiques[categorie];
};

const prettySiret = (str) => {
  if (!str) return null;
  // 11122233344444 => 111 222 333 444 44 => 111 222 333 44444.
  return str.replace(/(\d{3})/g, "$1 ").replace(/(\s)(?=(\d{2})$)/g, "");
};

const prettySiretHtml = (str) => {
  if (!str) return null;
  // 11122233344444 => 111 222 333 44444 with &#8239 the NO-BREAK SPACE
  return str.replace(
    /(\d{3})(\d{3})(\d{3})(\d{5})/g,
    "$1&#8239;$2&#8239;$3&#8239;$4"
  );
};

const prettySiren = (str) => {
  if (!str) return null;
  // 111222333 => 111 222 333.
  return str.replace(/(\d)(?=(\d{3})+$)/g, "$1 ");
};

const prettySirenHtml = (str) => {
  if (!str) return null;
  // 111222333 => 111 222 333 with &#8239 the NO-BREAK SPACE
  return str.replace(/(\d{3})(\d{3})(\d{3})/g, "$1&#8239;$2&#8239;$3");
};

const prettyVatNumber = (str) => {
  if (!str) return null;
  return str.replace(/(\d)(?=(\d{3})+$)/g, "$1 ");
};

// convert a date into the DD/MM/YYYY format
const frenchDateFormat = (date) => {
  const dateFormatHyphens = RegExp(/^\d{4}-\d{2}-\d{2}$/);
  const timestamp = Date.parse(date);

  if (dateFormatHyphens.test(date)) {
    return date == null
      ? null
      : new Intl.DateTimeFormat("en-GB").format(new Date(date));
  } else if (!isNaN(timestamp)) {
    return new Intl.DateTimeFormat("en-GB").format(new Date(timestamp));
  } else return date;
};

const formatAddressZone = (
  codePostal,
  ville,
  pays,
  { shortened = false } = {}
) => {
  let zone = `${toUpper(codePostal)} ${capitalize(ville)}, ${toUpper(pays)}`;
  if (shortened) zone = zone.replace(/FRANCE/, "");
  const trim = new RegExp(/^, ?| ,| ?,$/g);
  return zone.trim().replace(trim, "");
};

const getId = (len = 20) => {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
};

const dec2hex = (dec) => {
  return ("0" + dec.toString(16)).substr(-2);
};

const truncate = (str) => {
  if (str && str.length > 80) {
    return `${str.substring(0, 75)}...`;
  }
  return str;
};

export {
  ageConvert,
  capitalize,
  concatNames,
  frenchDateFormat,
  formatAddressZone,
  getId,
  inNumber,
  placeHolderIfEmpty,
  prettySiret,
  prettySiren,
  prettySiretHtml,
  prettySirenHtml,
  prettyVatNumber,
  removeDiacritics,
  removeExtraChars,
  libelleFromCodeNaf,
  libelleFromCategoriesJuridiques,
  toTitleCase,
  truncate,
  withI18nParam,
};
