export default {
  twitter: (link, message) =>
    `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
      message || ""
    )}&url=${encodeURIComponent(link || "")}`,
  facebook: link =>
    `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      link || ""
    )}`,
  google: link =>
    `https://plus.google.com/share?url=${encodeURIComponent(link || "")}`,
  tumblr: link =>
    `http://tumblr.com/widgets/share/tool?canonicalUrl=${encodeURIComponent(
      link || ""
    )}`,
  reddit: link =>
    `https://reddit.com/submit/?url=${encodeURIComponent(link || "")}`,
  whatsapp: (link, message) =>
    `whatsapp://send?text=${encodeURIComponent(message)}%20${encodeURIComponent(
      link || ""
    )}`,
  telegram: (link, message) =>
    `https://telegram.me/share/url?text=${encodeURIComponent(
      message || ""
    )}&amp;url=${encodeURIComponent(link || "")}`,
  vk: (link, message) =>
    `http://vk.com/share.php?title=${encodeURIComponent(
      message || ""
    )}&amp;url=${encodeURIComponent(link || "")}`,
  hacker: (link, message) =>
    `https://news.ycombinator.com/submitlink?u=${encodeURIComponent(
      link || ""
    )}&amp;t=${encodeURIComponent(message)}`,
  xing: (link, message) =>
    `https://www.xing.com/app/user?op=share;url=${encodeURIComponent(
      link || ""
    )};title=${encodeURIComponent(message)}.`,
  mail: (link, message) =>
    `mailto:?Subject=${encodeURIComponent(
      message || ""
    )}&body=${encodeURIComponent(link || "")}`,
  pinterest: (link, message) =>
    `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
      link || ""
    )}&amp;media=${encodeURIComponent(
      link || ""
    )}&amp;description=${encodeURIComponent(message || "")}`,
  linkedin: (link, message) =>
    `https://www.linkedin.com/shareArticle?mini=true&amp;url=${encodeURIComponent(
      link || ""
    )}&amp;title=${encodeURIComponent(
      message || ""
    )}.&amp;summary=${encodeURIComponent(
      message || ""
    )}.&amp;source=${encodeURIComponent(link || "")}`
};
