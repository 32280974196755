import React from "react";

// i18n
import { useTranslation } from "react-i18next";

// Internal components
import LabelDisplayInput from "./LabelDisplayInput";

const TextInput = ({
  asyncError,
  autofocus,
  className,
  disabled,
  help,
  icon,
  input,
  type,
  label,
  mandatory,
  meta: { touched, error },
  optional,
  placeholder,
  validation,
  width,
}) => {
  // i18n
  const [t] = useTranslation();

  return (
    <div className={width ? `uk-width-1-1 ${width}@s` : "uk-width-1-1"}>
      <LabelDisplayInput
        dir={t("app.lang.dir")}
        label={label}
        mandatory={mandatory}
        optional={optional}
      />
      <div className="uk-form-controls">
        <div className="uk-inline uk-width-1-1">
          {icon && <span className="uk-form-icon" uk-icon={"icon: " + icon} />}
          <input
            {...input}
            autoFocus={autofocus}
            type={type}
            width={width}
            placeholder={
              optional ? `${placeholder} ${t("lbl.optional")}` : placeholder
            }
            disabled={disabled}
            autoComplete="none"
            className={
              touched && !!error
                ? `${className} uk-form-danger`
                : `${className}`
            }
          />
        </div>
        <div className="uk-text-meta uk-margin-small-bottom uk-text-left">
          {help && (
            <>
              <span>{help}</span>
              <br />
            </>
          )}
          {validation === false
            ? ""
            : touched &&
              (error || asyncError) && (
                <span className="uk-text-danger uk-align-left">
                  {error.substring(0, 4) === "err." ? t(error) : error}
                </span>
              )}
        </div>
      </div>
    </div>
  );
};

export default TextInput;
