import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import DisplayMap from "../../molecules/DisplayMap";

const LabelProperty = ({ property }) => {
  const [t] = useTranslation();

  const [showMap, setShowMap] = useState(false);

  const showMapToggle = () => {
    setShowMap(!showMap);
  };

  return (
    <>
      <div className="uk-grid-small uk-grid-match" data-uk-grid>
        <div className="uk-width-auto">
          <a
            onClick={() => showMapToggle()}
            className="uk-icon-link"
            uk-tooltip={showMap ? t("lbl.hide_map") : t("lbl.show_map")}
          >
            <span uk-icon="location" />
          </a>
        </div>
        <div className="uk-width-expand">
          <address>
            {property.address.name && (
              <div className="uk-text-small">{property.address.name}</div>
            )}
            {property.address.line1 && (
              <div className="uk-text-small">{property.address.line1}</div>
            )}
            {property.address.line2 && (
              <div className="uk-text-small">{property.address.line2}</div>
            )}
            {property.address.zip && property.address.city && (
              <>
                <span className="uk-text-small">
                  {property.address.zip}&nbsp;
                </span>
                <span className="uk-text-small">
                  {" " + property.address.city}
                </span>
              </>
            )}
            {property.address.country && (
              <span className="uk-text-small">
                {", " + property.address.country}
              </span>
            )}
          </address>
        </div>
      </div>
      {property.map.lat && property.map.lng && showMap && (
        <DisplayMap lat={property.map.lat} lng={property.map.lng} />
      )}
      {property.contact.tel && (
        <div className="uk-grid-small uk-grid-match" data-uk-grid>
          <div className="uk-width-auto">
            <span uk-icon="receiver" />
          </div>
          <div className="uk-width-expand">
            <div className="uk-text-small">{property.contact.tel}</div>
          </div>
        </div>
      )}
      {property.contact.mail && (
        <div className="uk-grid-small uk-grid-match" data-uk-grid>
          <div className="uk-width-auto">
            <span uk-icon="mail" />
          </div>
          <div className="uk-width-expand">
            <div>
              <a
                href={"mailto:" + property.contact.mail}
                className="uk-text-small"
              >
                {property.contact.mail}
              </a>
            </div>
          </div>
        </div>
      )}
      {property.contact.website && (
        <div className="uk-grid-small uk-grid-match" data-uk-grid>
          <div className="uk-width-auto">
            <span uk-icon="world" />
          </div>
          <div className="uk-width-expand">
            <div>
              <a
                href={property.contact.website}
                target="_blank"
                rel="noopener noreferrer"
                className="uk-text-small"
              >
                {property.contact.website}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LabelProperty;
