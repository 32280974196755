import React, { useEffect } from "react";
// i18n
import { useTranslation } from "react-i18next";
// redux
import { Field, reduxForm, formValueSelector } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../redux/actions/modal_actions";
import { combineValidators, isRequired } from "revalidate";
// Internal helpers
import { toTitleCase } from "../../../../util/helpers";
// internal components
import TextInput from "../../elements/TextInput";
import DateInput from "../../elements/DateInput";
import ButtonInput from "../../elements/ButtonInput";
import SpanFloat from "../../../molecules/SpanFloat";

const validate = combineValidators({
  displayName: isRequired({ message: "err.display_name.required" }),
  firstName: isRequired({ message: "err.first_name.required" }),
  lastName: isRequired({ message: "err.last_name.required" }),
});

const lowercase = (value) => value && value.toLowerCase();
const titlecase = (value) => value && toTitleCase(value);

const NameLocation = ({
  handleSubmit,
  initialize,
  invalid,
  pristine,
  submitting,
  user,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  let dir = t("app.lang.dir");
  // dateOfBirth
  const selector = formValueSelector("name_location_form");
  const state = useSelector((state) => state);
  const dateOfBirth = selector(state, "dateOfBirth");
  const country = selector(
    state,
    "address.addressComponents.country.long_name"
  );
  const city = selector(state, "address.addressComponents.city");

  useEffect(() => {
    if (user !== {}) initialize(user);
  }, [initialize, user]);

  return (
    <form onSubmit={handleSubmit} className="uk-form-stacked">
      <div className="uk-grid-small uk-margin-top" uk-grid="">
        <Field
          name="firstName"
          mandatory
          label={t("lbl.first_name")}
          component={TextInput}
          className="uk-input"
          width="uk-width-1-2"
          type="text"
          placeholder={t("plh.first_name")}
          normalize={titlecase}
        />
        <Field
          name="lastName"
          mandatory
          label={t("lbl.last_name")}
          component={TextInput}
          className="uk-input"
          width="uk-width-1-2"
          placeholder={t("plh.last_name")}
          normalize={titlecase}
        />
        <Field
          name="userName"
          mandatory
          label={t("lbl.username")}
          component={TextInput}
          className="uk-input"
          width="uk-width-2-3"
          type="text"
          placeholder={t("plh.username")}
          help={t("hlp.display_name")}
          normalize={lowercase}
        />
        <Field
          name="dateOfBirth"
          dateInit={dateOfBirth}
          mandatory
          component={DateInput}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          placement={dir === "ltr" ? "bottom-end" : "bottom-start"}
          className="uk-input"
          width="uk-width-1-3"
          type="text"
          label={t("lbl.date_of_birth")}
          placeholder={t("plh.date_of_birth")}
        />
        <Field
          name="position"
          optional={t("lbl.optional")}
          component={TextInput}
          className="uk-input"
          width="uk-width-1-1"
          type="text"
          label={t("lbl.position")}
          placeholder={t("plh.position")}
          normalize={titlecase}
        />
        <Field
          name="address.addressComponents.country.long_name"
          component={TextInput}
          className="uk-input"
          width="uk-width-1-2"
          type="text"
          label={t("lbl.country")}
          placeholder={t("plh.country")}
          normalize={titlecase}
        />
        <Field
          name="address.addressComponents.city"
          component={TextInput}
          className="uk-input"
          width="uk-width-1-2"
          type="text"
          label={t("lbl.city")}
          placeholder={t("plh.city")}
          normalize={titlecase}
        />
        <hr
          className="uk-width-1-1 uk-margin-small"
          style={{ marginLeft: "15px" }}
        />
        <div className="uk-width-1-1">
          <SpanFloat float="left">
            <ButtonInput
              primary
              disabled={invalid || pristine || submitting}
              action="submit"
              loading={submitting}
              label={t("lbl.save")}
            />
          </SpanFloat>
          <SpanFloat float="right">
            <ButtonInput
              onClick={() => dispatch(closeModal())}
              label={t("lbl.cancel")}
            />
          </SpanFloat>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "name_location_form",
  validate,
})(NameLocation);
