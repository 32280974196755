import React from "react";

import { Link } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// redux
import { useDispatch } from "react-redux";
import { openModal } from "../../redux/actions/modal_actions";
import { locales } from "../../util/helpers/locale";

const renderShares = ({ i18n, t }) => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <ul className="uk-iconnav">
        <li>
          <Link to={"#"}>
            <span uk-icon="icon: world" />
          </Link>
          <div data-uk-drop="pos: top">
            <div className="uk-card uk-card-body uk-card-secondary">
              <div className="uk-column-1-2">
                <ul className="uk-list">
                  {locales.map((locale, i) =>
                    locale !== t("app.lang.code") ? (
                      <li key={i}>
                        <Link
                          to={"#"}
                          onClick={(e) => changeLanguage(e.target.id)}
                          id={t("app.lang." + locale + ".code")}
                          uk-tooltip={t("app.lang." + locale + ".long")}
                          title={t("app.lang." + locale + ".long")}
                        >
                          {t("app.lang." + locale + ".localised")}
                        </Link>
                      </li>
                    ) : null
                  )}
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li>
          <a
            className="uk-icon-facebook"
            href="https://facebook.com/inwine"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span uk-icon="icon: facebook" />
          </a>
        </li>
        {/* <li>
          <a
            className="uk-icon-google-plus"
            href="https://plus.google.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span uk-icon="icon: google-plus" />
          </a>
        </li> */}
        <li>
          <a
            className="uk-icon-twitter"
            href="https://twitter.com/areyouinwine"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span uk-icon="icon: twitter" />
          </a>
        </li>
      </ul>
      <div className="uk-text-meta uk-margin-small">
        {t("app.denomination")}
        <br />
        {t("app.legal.copyright")} &copy; {new Date().getFullYear()},{" "}
        {t("app.legal.rights")}
      </div>
    </>
  );
};

const Footer = ({ children }) => {
  // i18n
  const [t, i18n] = useTranslation();
  // Redux dispatch
  const dispatch = useDispatch();

  return (
    <footer>
      <div className="uk-section uk-section-secondary uk-section-medium">
        <div className="uk-container uk-container-expand">
          <div className="uk-grid-large uk-width-1-1" data-uk-grid>
            <div className=" uk-width-1-1 uk-width-1-3@s">
              <ul className="uk-nav-default" data-uk-nav>
                <li>
                  <Link to={"/"}>{t("app.name")}</Link>
                </li>
                <li>
                  <Link to={"/terms"}>{t("app.legal.terms")}</Link>
                </li>
                <li>
                  <Link to={"/privacy-policy"}>
                    {t("app.legal.privacy_policy")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"#"}
                    onClick={() =>
                      dispatch(
                        openModal("ContactModal", {
                          className: "uk-modal uk-flex-top uk-open",
                          style: { display: "block" },
                          header: t("form.contact.header"),
                          description: t("form.contact.description"),
                        })
                      )
                    }
                  >
                    {t("app.nav.contact")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className=" uk-width-1-1 uk-width-1-3@s">
              <ul className="uk-nav-default" data-uk-nav>
                {children}
              </ul>
            </div>
            <div className="uk-width-1-1 uk-width-1-3@s">
              <div className="uk-visible@s">
                <span className="uk-float-right">
                  {renderShares({ i18n, t })}
                </span>
              </div>
              <div className="uk-hidden@s">{renderShares({ i18n, t })}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-section uk-section-default uk-section-xsmall">
        <div className="uk-container uk-container-expand">
          <div
            className="uk-grid-small uk-child-width-1-6@s uk-child-width-1-3 uk-flex-center uk-flex-middle uk-text-center"
            data-uk-grid
          >
            <div className="uk-card uk-card-small uk-card-body">
              <a
                className="uk-link-reset"
                href="http://www.bpifrance.fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/assets/bpi_france.png"
                  width=""
                  height=""
                  alt="BPI France"
                />
              </a>
            </div>
            <div className="uk-card uk-card-small uk-card-body">
              <a
                className="uk-link-reset"
                href="http://www.nouvelle-aquitaine.fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/assets/nouvelle_aquitaine.png"
                  width=""
                  height=""
                  alt="Nouvelle Aquitaine"
                />
              </a>
            </div>
            <div className="uk-card uk-card-small uk-card-body">
              <a
                className="uk-link-reset"
                href="http://www.frenchtechbordeaux.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/assets/french_tech_bordeaux.png"
                  width=""
                  height=""
                  alt="French Tech Bordeaux"
                />
              </a>
            </div>
            <div className="uk-card uk-card-small uk-card-body">
              <a
                className="uk-link-reset"
                href="http://www.lafrenchtech.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/assets/french_tech.png"
                  width=""
                  height=""
                  alt="French Tech"
                />
              </a>
            </div>
            <div className="uk-card uk-card-small uk-card-body">
              <a
                className="uk-link-reset"
                href="http://www.innovin.fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/assets/inno_vin.png"
                  width=""
                  height=""
                  alt="Inno'Vin"
                />
              </a>
            </div>
            <div className="uk-card uk-card-small uk-card-body">
              <a
                className="uk-link-reset"
                href="http://www.unitec.fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/assets/unitec.png" width="" height="" alt="Unitec" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
