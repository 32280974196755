import React from "react";

// i18n
import { useTranslation } from "react-i18next";

// external imports
import { Helmet } from "react-helmet";

import { locales } from "../../util/helpers/locale";

const Head = ({ description, image, title, type }) => {
  const [t] = useTranslation();

  require("react-helmet/lib/HelmetConstants.js").HELMET_ATTRIBUTE =
    "data-inwine";

  return (
    <Helmet>
      <html lang={t("app.lang.code")} dir={t("app.lang.dir")} />
      <title>{title ? title : t("app.name")}</title>
      <meta name="title" content={title ? title : t("app.name")} />
      <meta name="description" content={description} />
      <link rel="canonical" href={window.location.href} />
      {locales.map((locale, i) =>
        locale !== t("app.lang.code") ? (
          <link
            key={i}
            rel="alternate"
            href={
              window.location.href + "?lng=" + t("app.lang." + locale + ".code")
            }
            hrefLang={t("app.lang." + locale + ".code")}
          />
        ) : null
      )}
      <meta property="og:title" content={title} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={window.location.origin + image} />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content={t("app.name")} />
      <meta property="og:locale" content={t("app.lang.code")} />
      <meta name="twitter:domain" content={t("app.domain")} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={window.location.href} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={window.location.origin + image} />
      <meta name="twitter:site" content="@areyouinwine" />
      <meta itemProp="name" content={title} />
      <meta itemProp="url" content={window.location.href} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={window.location.origin + image} />
      <meta name="application-name" content={t("app.name")} />
      <meta name="msapplication-TileColor" content="#EDECE6" />
      <meta
        name="msapplication-TileImage"
        content={window.location.origin + "/assets/mstile-144x144.png"}
      />
      {t("app.lang.dir") === "rtl" ? (
        <link
          type="text/css"
          rel="stylesheet"
          href="uikit/dist/css/uikit.rtl.min.css"
        />
      ) : null}
    </Helmet>
  );
};

export default Head;
