import React, { useEffect } from "react";

// i18n
import { useTranslation } from "react-i18next";

// Cookies
import { useCookies } from "react-cookie";

// Router
import { useRouter } from "../../hooks/use-router";

import Head from "../../components/layout/Head";
import LandingLayout from "../../components/layout/LandingLayout";
import WineTraceMenu from "./WineTraceMenu";
import CookieConsent from "../../components/layout/CookieConsent";

const WineTrace = () => {
  // i18n
  const [t] = useTranslation();
  // page dir
  const dir = t("app.lang.dir");
  // cookies
  const [cookies, setCookie] = useCookies(["accepts_policy"]);
  // Router
  const router = useRouter();

  useEffect(() => {
    if (typeof cookies !== "undefined")
      window.addEventListener("scroll", handleAccept);
    return () => {
      if (typeof cookies !== "undefined")
        window.removeEventListener("scroll", handleAccept);
    };
  });

  const handleAccept = (e) => {
    e.preventDefault();
    let d = new Date();
    d.setTime(d.getTime() + 20 * 365 * 24 * 60 * 60);
    setCookie("accepts_policy", true, {
      path: "/",
      expires: d,
      domain: "inwine.com",
      secure: true,
    });
  };

  const handleDecline = (e) => {
    e.preventDefault();
    let d = new Date();
    d.setTime(d.getTime() + 20 * 365 * 24 * 60 * 60);
    setCookie("accepts_policy", false, {
      path: "/",
      expires: d,
      domain: "inwine.com",
      secure: true,
    });
  };

  return (
    <>
      <Head
        title={t("app.name") + " | " + t("trace.header")}
        type={t("app.type")}
        image="/assets/inwine_home.jpg"
        description={t("app.description")}
      />
      <LandingLayout landingMenu={<WineTraceMenu />}>
        <div className="uk-inline uk-overflow-hidden">
          <img
            className="uk-animation uk-transform-origin-top-right"
            src="/assets/images/_logistics-410x700.jpg"
            srcSet="/assets/images/_logistics-410x700.jpg 410w, /assets/images/_logistics-640x700.jpg 640w, /assets/images/_logistics-960x700.jpg 960w, /assets/images/_logistics-1200x900.jpg 1200w, /assets/images/_logistics-1600x900.jpg 1600w, /assets/images/_logistics-1920x1080.jpg 1920w"
            sizes="(max-width: 640px), (min-width: 640px), (max-width: 960px), (max-width: 1200px), (max-width: 1600px), (max-width: 1920px), 100vw"
            alt="Wine Trace"
            uk-scrollspy="cls: uk-animation-kenburns; repeat: true"
            style={{
              height: "100vh",
              width: "100%",
              objectFit: "cover",
              backgroundPosition: "center",
            }}
          />
          <div
            className="uk-overlay-default uk-position-cover"
            style={{
              background:
                "url(/assets/images/background-image-overlay-full.jpg)",
              backgroundRepeat: "repeat",
              opacity: "0.5",
              minHeight: "calc(100vh)",
            }}
            data-uk-height-viewport
          />
          <div
            className={
              dir === "ltr"
                ? "uk-position-large uk-position-bottom-left uk-position-z-index uk-light"
                : "uk-position-large uk-position-bottom-right uk-position-z-index uk-light"
            }
            style={
              dir === "ltr"
                ? { margin: "auto auto 10% 50px" }
                : { margin: "auto 50px 10% auto" }
            }
          >
            <div className="uk-heading-medium uk-text-300">
              {t("trace.header")}
            </div>
          </div>
        </div>
        <div id="blockchain" className="uk-section" style={{ paddingTop: 110 }}>
          <div
            style={
              dir === "ltr"
                ? { margin: "auto auto auto 50px" }
                : { margin: "auto 50px auto auto" }
            }
          >
            <div className="uk-text-lead uk-text-uppercase uk-text-300">
              {t("trace.blockchain.title")}
            </div>
            <h1 className="uk-heading-medium uk-text-300 uk-width-5-6">
              {t("trace.blockchain.subtitle")}
            </h1>
          </div>
        </div>
      </LandingLayout>
      {!cookies.accepts_policy && (
        <CookieConsent onAccept={handleAccept} onDecline={handleDecline} />
      )}
    </>
  );
};

export default WineTrace;
