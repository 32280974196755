import React from "react";

const LabelImageThumb = ({ wine }) => {
  return (
    <img
      src={"/assets/label/" + wine.iwin + ".jpg"}
      className="uk-box-shadow-medium"
      alt={wine.displayName}
      style={{ maxHeight: "80px" }}
      data-uk-img
    />
  );
};

export default LabelImageThumb;
