import React, { useEffect, useState } from "react";

// i18n
import { useTranslation } from "react-i18next";

// Other functions
import slug from "slug";

// Hooks
import { useRouter } from "../../hooks/use-router";
import { useRequireAuth } from "../../hooks/use-require-auth";
import { useIwin } from "../../hooks/use-iwin";

// Internal components
import Head from "../../components/layout/Head";
import IwinLabelMenu from "./IwinLabelMenu";
import LabelImage from "../../components/label/image/LabelImage";
import LabelOtherWine from "../../components/label/property/LabelOtherWine";
import LabelOwnership from "../../components/label/ownership/LabelOwnership";
import LabelProperty from "../../components/label/property/LabelProperty";
import LabelScores from "../../components/label/score/LabelScores";
import LabelVarietals from "../../components/label/varietal/LabelVarietals";
import LabelVintage from "../../components/label/identification/LabelVintage";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/layout/Loading";
import SocialLinks from "../../components/molecules/SocialLinks";

const IwinLabel = () => {
  // i18n
  const [t] = useTranslation();
  const dir = t("app.lang.dir");
  // router
  const router = useRouter();
  // auth
  const auth = useRequireAuth();

  const IWIN = useIwin(router.query.iwin);
  const { iwin, label, root } = IWIN;
  console.log("IwinLabel:", IWIN);

  // state
  const [user, setUser] = useState({});
  const [vintage, setVintage] = useState({});

  useEffect(() => {
    if (auth.user) setUser(auth.user);
  }, [auth]);

  useEffect(() => {
    if (vintage && (vintage.id || vintage.vintage)) {
      console.log("Vintage:", vintage);
      const newIwin = label && label.iwin.substr(0, 7) + vintage.id;
      console.log("New IWIN:", newIwin);
      const urlSlug = slug(label && label.displayName + " " + vintage.vintage);
      console.log("URL slug:", urlSlug);
      router.push(`/iwin-masterfile/${newIwin}/${urlSlug}`);
    }
  }, [vintage]);

  const objectToArray = (object) => {
    if (object) {
      return Object.entries(object).map((e) =>
        Object.assign(e[1], { id: e[0] })
      );
    }
  };

  const handleVintageChange = (vintage) => {
    console.log("Vintage:", vintage);
    setVintage(vintage);
  };

  return (
    <>
      <Head
        title={
          t("app.name") +
          " | " +
          ((label && label.title) || t("iwin.masterfile"))
        }
        type={t("app.type")}
        image="/assets/inwine_home.jpg"
        description={t("app.description")}
      />
      <Layout search menu={<IwinLabelMenu />}>
        {label ? (
          <div className="uk-container">
            <div className="uk-section uk-section-xsmall">
              <div className="uk-grid uk-grid-small">
                <div className="uk-width-auto@s">
                  <h1 className="uk-text-iwin uk-text-center uk-text-left@s">
                    {iwin}
                  </h1>
                </div>
                <div className="uk-visible@s uk-width-expand@s uk-flex uk-flex-middle uk-flex-right">
                  <SocialLinks
                    link={window.location.href}
                    title={(label && label.title) + " | " + t("app.name")}
                  />
                </div>
                <div className="uk-width-1-1 uk-text-center uk-text-left@s">
                  <div className="boundary-align">
                    <h2 className="uk-margin-remove-vertical uk-display-block">
                      <span className="uk-display-inline-block uk-text-middle">
                        {label && label.displayName}
                      </span>
                      <span className="uk-display-inline-block">
                        {label && (
                          <LabelVintage
                            label={label}
                            onVintageChange={handleVintageChange}
                          />
                        )}
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-expand@s">
                  <div className="uk-panel">
                    <div
                      className={
                        dir === "ltr"
                          ? "uk-text-center uk-text-left@s"
                          : "uk-text-center uk-text-right@s"
                      }
                    >
                      {label && label.labelImageURL && (
                        <img
                          src={label.labelImageURL}
                          alt={label.iwin}
                          style={{ maxWidth: "300px", width: "100%" }}
                          className={
                            dir === "ltr"
                              ? "uk-align-center uk-align-left@s uk-margin-remove-adjacent"
                              : "uk-align-center uk-align-right@s uk-margin-remove-adjacent"
                          }
                        />
                      )}
                      {label && label.colourImageURL && (
                        <img
                          src={label.colourImageURL}
                          style={{
                            height: "15px",
                            margin:
                              dir === "ltr"
                                ? "-4px 5px auto auto"
                                : "-4px auto auto 5px",
                          }}
                          alt={label.colour}
                        />
                      )}
                      {label && label.originDisplayName}
                      {label &&
                        label.classifications &&
                        objectToArray(label.classifications).length > 0 &&
                        objectToArray(label.classifications)
                          .sort((a, b) => b.vintageStart - a.vintageStart)
                          .map((classification, i) => (
                            <p
                              key={i}
                              className="uk-margin-remove-bottom uk-margin-small-top"
                            >
                              <strong>{classification.rankName}</strong>
                              {classification.name && (
                                <>
                                  <br />
                                  <span className="uk-text-meta">
                                    {classification.name}
                                  </span>
                                </>
                              )}
                            </p>
                          ))}
                      {label &&
                        (label.vintageStart ||
                          label.vintageEnd ||
                          label.vintageNo) && (
                          <div
                            className="uk-grid-small uk-width-1-1 uk-margin-small-top"
                            data-uk-grid
                          >
                            {label.vintageStart && (
                              <div className="uk-width-1-2 uk-width-1-1@s">
                                {t("label.vintageStart", {
                                  vintage: label.vintageStart,
                                })}
                              </div>
                            )}
                            {label.vintageEnd && (
                              <div className="uk-width-1-2 uk-width-1-1@s">
                                {t("label.vintageEnd", {
                                  vintage: label.vintageEnd,
                                })}
                              </div>
                            )}
                            {label.vintageNo && (
                              <div className="uk-width-1-1">
                                {t("label.vintageNo", {
                                  vintages: label.vintageNo.map((v) => " " + v),
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      {label &&
                        label.varietals &&
                        objectToArray(label.varietals).length > 0 && (
                          <div className="uk-margin-medium-top">
                            <LabelVarietals
                              varietals={objectToArray(label.varietals)}
                            />
                          </div>
                        )}
                      {label &&
                        label.scores &&
                        objectToArray(label.scores).length > 0 && (
                          <div className="uk-margin-medium-top">
                            <LabelScores scores={objectToArray(label.scores)} />
                          </div>
                        )}
                      {label &&
                        label.wines &&
                        objectToArray(label.wines).length > 0 && (
                          <div className="uk-margin-medium-top">
                            <LabelOtherWine
                              dir={dir}
                              wines={objectToArray(label.wines)}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="uk-width-auto@s">
                  {label && label.property && (
                    <LabelProperty property={label.property} />
                  )}
                  {label && label.ownership && (
                    <LabelOwnership ownership={label.ownership} />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </Layout>
    </>
  );
};

export default IwinLabel;
