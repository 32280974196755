import React from "react";

const LabelDisplayInput = ({ dir, label, mandatory, optional }) => {
  return label ? (
    <label
      className={
        dir === "ltr"
          ? "uk-form-label uk-text-left"
          : "uk-form-label uk-text-right"
      }
      style={dir === "ltr" ? { marginLeft: "10px" } : { marginRight: "10px" }}
    >
      {mandatory ? (
        <strong>
          {label} {optional}
          <span
            style={
              dir === "ltr"
                ? { color: "red", marginTop: "-2px", marginLeft: "3px" }
                : { color: "red", marginTop: "-2px", marginRight: "3px" }
            }
          >
            *
          </span>
        </strong>
      ) : (
        label
      )}
    </label>
  ) : (
    <></>
  );
};

export default LabelDisplayInput;
