import React from "react";
import { Route, Switch } from "react-router-dom";

// Internal components
import ModalManager from "../components/modals";

// Internal page components
import Action from "../pages/auth/Action";
import Authenticate from "../pages/auth/Authenticate";
import Dashboard from "../pages/Dashboard";
import ForgotPassword from "../pages/auth/ForgotPassword";
import IwinLabel from "../pages/IwinLabel";
import IwinMasterfile from "../pages/IwinMasterfile";
import Landing from "../pages/Landing";
import News from "../pages/News";
import NewsItem from "../pages/News/Item";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ResetPassword from "../pages/auth/ResetPassword";
import Signin from "../pages/auth/Signin";
import Signup from "../pages/auth/Signup";
import Settings from "../pages/auth/Settings";
import WineTrace from "../pages/WineTrace";

const App = () => {
  return (
    <>
      <ModalManager />
      <Switch>
        <Route exact path="/" component={Landing} />
      </Switch>
      <Route
        path="/(.+)"
        render={() => (
          <Switch>
            <Route exact path="/action" component={Action} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/signin" component={Signin} />
            <Route exact path="/authenticate" component={Authenticate} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path={["/news", "/news/"]} component={News} />
            <Route path="/news/:item" component={NewsItem} />
            <Route
              exact
              path={["/iwin-masterfile", "/iwin-masterfile/"]}
              component={IwinMasterfile}
            />
            <Route path="/iwin-masterfile/:iwin" component={IwinLabel} />
            <Route
              exact
              path={["/wine-trace", "/wine-trace/"]}
              component={WineTrace}
            />
            <Route exact path="/:username" component={Settings} />
            <Route exact path="/dashboard" component={Dashboard} />
          </Switch>
        )}
      />
    </>
  );
};

export default App;
