import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import subYears from "date-fns/subYears";
import format from "date-fns/format";

import { useTranslation } from "react-i18next";

const LabelVintage = ({ label, onVintageChange }) => {
  const [t] = useTranslation();
  const [vintage, setVintage] = useState(null);
  const [vintages, setVintages] = useState([]);

  useEffect(() => {
    if (label && label.vintage) setVintage(label.vintage);
  }, [label]);

  const handleVintageChange = e => {
    e.preventDefault();
    console.log(e.target.value);
  };
  return (
    <>
      <div className="uk-inline">
        <span
          className={
            t("app.lang.dir") === "ltr"
              ? "uk-form-icon uk-form-icon-flip"
              : "uk-form-icon"
          }
          uk-icon="icon: triangle-down"
        />
        <input
          className="uk-input"
          style={{
            width: 100,
            border: "none",
            height: "inherit",
            paddingBottom: 1,
            background: "transparent"
          }}
          uk-tooltip="title: Click to select a vintage..."
          value={vintage || ""}
          onChange={handleVintageChange}
        />
      </div>
    </>
  );
};

export default LabelVintage;
