import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import reduxThunk from "redux-thunk";

import rootReducer from "../redux/reducers";

export const configureStore = preloadedState => {
  const middlewares = [reduxThunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const storeEnhancers = [middlewareEnhancer];

  const composedEnhancer =
    process.env.NODE_ENV !== "production"
      ? composeWithDevTools(...storeEnhancers)
      : compose(...storeEnhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancer);

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("../redux/reducers", () => {
        const newRootReducer = require("../redux/reducers").default;
        store.replaceReducer(newRootReducer);
      });
    }
  }

  return store;
};
