import React from "react";
import { useTranslation } from "react-i18next";

import AccordionListItem from "../components/AccordionListItem";

const SettingsLanguage = ({ user }) => {
  const [t] = useTranslation();

  return (
    <AccordionListItem
      header={t("settings.personal_details.language.header")}
      description={t("settings.personal_details.language.description")}
      info={user && user.language && t(`app.lang.${user.language}.localised`)}
      dir={t("app.lang.dir")}
    >
      <div className="uk-width-1-1 uk-width-1-2@s uk-margin"></div>
    </AccordionListItem>
  );
};

export default SettingsLanguage;
