import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const SettingsMenu = () => {
  // i18n
  const [t] = useTranslation();

  return (
    <>
      <li>
        <Link to="/inventories">
          <span>{t("app.nav.inventories")}</span>
        </Link>
      </li>
    </>
  );
};

export default SettingsMenu;
