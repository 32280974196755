import React, { Fragment } from "react";

import { Trans, useTranslation } from "react-i18next";

import SpanFloat from "../molecules/SpanFloat";

const CookieConsent = ({ onAccept, onDecline }) => {
  const [t] = useTranslation();

  return (
    <div className="uk-position-bottom">
      <div
        className="uk-section uk-section-secondary uk-section-xsmall"
        style={{ opacity: "0.65" }}
      >
        <div className="uk-container uk-text-small">
          <p>
            {t("app.legal.cookie_consent.message")}{" "}
            <Trans
              i18nKey="app.legal.cookie_consent.policy"
              values={{ policy: t("app.legal.cookie_policy") }}
            />
          </p>
          <p>{t("app.legal.cookie_consent.decline")}</p>
          <SpanFloat float="right">
            <Fragment>
              <button
                className="uk-button uk-button-secondary uk-button-small"
                style={{ margin: "auto 10px" }}
                onClick={onAccept}
              >
                {t("lbl.accept")}
              </button>
              <button
                className="uk-button uk-button-default uk-button-small"
                onClick={onDecline}
              >
                {t("lbl.decline")}
              </button>
            </Fragment>
          </SpanFloat>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
