import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const IwinMasterfileMenu = () => {
  // i18n
  const [t] = useTranslation();

  return (
    <>
      <li>
        <Link to="/iwin-masterfile" data-uk-scroll="duration: 500">
          {t("app.nav.identify")}
        </Link>
      </li>
      <li>
        <Link to="#data" data-uk-scroll="duration: 500">
          {t("app.nav.iwin.data")}
        </Link>
      </li>
      <li>
        <Link to="#masterfile" data-uk-scroll="duration: 500">
          {t("app.nav.iwin.masterfile")}
        </Link>
      </li>
      <li>
        <Link to="#benefits" data-uk-scroll="duration: 500">
          {t("app.nav.iwin.benefits")}
        </Link>
      </li>
    </>
  );
};

export default IwinMasterfileMenu;
